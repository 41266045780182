import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b39d8c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "new-hi-content" }
const _hoisted_2 = { class: "new-btn-with-text" }
const _hoisted_3 = { class: "new-history-item" }
const _hoisted_4 = { class: "form-item form-item-description" }
const _hoisted_5 = { class: "form-item" }
const _hoisted_6 = { class: "right-aligned-items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!
  const _component_AsButton = _resolveComponent("AsButton")!
  const _component_AsBlock = _resolveComponent("AsBlock")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_AsFilesSelector = _resolveComponent("AsFilesSelector")!

  return (!_ctx.showNewItemForm)
    ? (_openBlock(), _createBlock(_component_AsBlock, {
        key: 0,
        class: "new-hi-wrapper",
        header: _ctx.$t('case.history.title')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_AsButton, {
                type: _ctx.bTypes.Primary,
                label: _ctx.$t('AddNew'),
                onClick: _ctx.initNewHistoryItem
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_fa, { icon: "icon fa-plus" })
                ]),
                _: 1
              }, 8, ["type", "label", "onClick"])
            ]),
            _createElementVNode("div", {
              class: "new-btn-icon",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.initNewHistoryItem && _ctx.initNewHistoryItem(...args)))
            }, [
              _createVNode(_component_fa, { icon: "icon fa-plus" })
            ])
          ])
        ]),
        _: 1
      }, 8, ["header"]))
    : (_openBlock(), _createBlock(_component_AsBlock, { key: 1 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", null, _toDisplayString(_ctx.$t('case.history.addEntry')), 1),
              _createVNode(_component_Textarea, {
                class: "full-width",
                rows: "3",
                modelValue: _ctx.command.text,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.command.text) = $event)),
                autoResize: true
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_AsFilesSelector, {
                files: _ctx.command.files,
                onUpdate: _cache[2] || (_cache[2] = (files) => (_ctx.command.files = files))
              }, null, 8, ["files"]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_AsButton, {
                  type: _ctx.bTypes.Primary,
                  label: 'Create',
                  disabled: !_ctx.isFormValid,
                  onClick: _ctx.createNewHistoryItem
                }, null, 8, ["type", "disabled", "onClick"]),
                _createVNode(_component_AsButton, {
                  type: _ctx.bTypes.Outlined,
                  label: 'Cancel',
                  onClick: _ctx.resetNewHistoryItem
                }, null, 8, ["type", "onClick"])
              ])
            ])
          ])
        ]),
        _: 1
      }))
}