
  import { Options, Vue } from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import AsBlock from '@/components/shared/as-block-component.vue';
  import AsDropdown from '@/components/shared/as-dropdown.vue';
  import AsTextWithLabel from '@/components/shared/as-textWithLabel.vue';
  import AsReadWriteBlock from '@/components/shared/as-read-write.vue';
  import { UserManagementModule, UserManagementStore } from '@/store/userManagement';
  import AsLabeledBlock from '@/components/shared/as-labeled-block.vue';
  import { CaseResponsibilityDto, CaseRoleType, LookUpValueType } from '@/apiclient/apiclient_generated';
  import AsMultiSelect from '@/components/shared/as-multi-select.vue';
  import { formatDateWithoutTime } from '@/services/dateFunctions';
  import { PublicConstants } from '@/types/PublicConstants';
  import AsDatepicker from '@/components/shared/as-datepicker.vue';
  import { LookUpValuesModule, LookUpValuesStore } from '@/store/lookUpValues';
  import { CaseModule, CaseStore } from '@/store/case';

  @Options({
    components: {
      AsBlock,
      AsDropdown,
      AsTextWithLabel,
      AsReadWriteBlock,
      AsLabeledBlock,
      AsMultiSelect,
      AsDatepicker,
    },
  })
  export default class CaseResponsibility extends Vue {
    @Prop()
    isEditMode: boolean;
    @Prop()
    isEditing: boolean;

    @Prop()
    responsibility: CaseResponsibilityDto;

    lvTypes = LookUpValueType;

    private usersStore: UserManagementModule = UserManagementStore;
    private caseStore: CaseModule = CaseStore;
    private lookUpStore: LookUpValuesModule = LookUpValuesStore;

    getFormattedDate = formatDateWithoutTime;

    get caseId(): any {
      return this.$route.params.id;
    }

    get isReadOnly() {
      if (!this.isEditMode) {
        return false;
      }

      if (this.isEditMode && !this.isEditing) {
        return true;
      }

      return false;
    }

    formatDateToString(date: string) {
      return date ? new Date(date).toDateString() : '';
    }

    get firstContactManager() {
      const manager = this.responsibility.managerId ? this.usersStore.users[this.responsibility.managerId] : null;

      return manager ? manager.fullName : PublicConstants.emptyValue;
    }

    get managers() {
      return [...this.usersStore.caseManagers, ...this.usersStore.admins];
    }
    get peers() {
      return this.usersStore.peers;
    }
    get technicalSuperVisors() {
      return this.caseStore.getUsersInRole(CaseRoleType.TechnicalSuperVisor);
    }

    getValueOrDefault(source) {
      return source ? source : PublicConstants.emptyValue;
    }

    getUserNames(ids: number[]) {
      let result = '';

      (ids || []).forEach((id) => {
        const user = this.usersStore.users[id];

        if (user) {
          result += `${user.fullName}; `;
        }
      });

      return result;
    }

    getLookUpOptions(groupType: LookUpValueType) {
      return this.lookUpStore.lookUpOptions(groupType);
    }

    getLookUpValue(group: LookUpValueType, id) {
      return this.lookUpStore.getLookUpValueName({ group, id });
    }
  }
