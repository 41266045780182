
  import Layout from '@/components/Layout.vue';
  import { Options, Vue } from 'vue-class-component';

  @Options({
    components: {
      Layout,
    },
  })
  export default class GroupsView extends Vue {}
