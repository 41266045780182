
  import { Options, Vue } from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import AsBlock from '@/components/shared/as-block-component.vue';
  import AsDropdown from '@/components/shared/as-dropdown.vue';
  import AsTextWithLabel from '@/components/shared/as-textWithLabel.vue';
  import AsReadWriteBlock from '@/components/shared/as-read-write.vue';
  import { UserManagementModule, UserManagementStore } from '@/store/userManagement';
  import AsLabeledBlock from '@/components/shared/as-labeled-block.vue';
  import { CaseClinicalInformationDto, CaseRoleType, LookUpValueType } from '@/apiclient/apiclient_generated';
  import AsMultiSelect from '@/components/shared/as-multi-select.vue';
  import { formatDateWithoutTime } from '@/services/dateFunctions';
  import AsInput from '@/components/shared/as-input.vue';
  import AsTags from '@/components/shared/as-tags.vue';
  import ClinicHistoryInputs from './ClinicHistoryInputs.vue';
  import { isFormReadOnly } from '@/services/formFunctions';
  import AsDatepicker from '@/components/shared/as-datepicker.vue';
  import HospitalizationHistory from './ClinicHospitalizationHistory.vue';
  import { GroupsModule, GroupsStore } from '@/store/groups';
  import { PublicConstants } from '@/types/PublicConstants';
  import { LookUpValuesModule, LookUpValuesStore } from '@/store/lookUpValues';
  import { CaseModule, CaseStore } from '@/store/case';

  @Options({
    components: {
      AsBlock,
      AsDropdown,
      AsTextWithLabel,
      AsReadWriteBlock,
      AsLabeledBlock,
      AsMultiSelect,
      AsInput,
      AsTags,
      ClinicHistoryInputs,
      AsDatepicker,
      HospitalizationHistory,
    },
  })
  export default class CaseClinicalInformation extends Vue {
    @Prop()
    isEditMode: boolean;
    @Prop()
    isEditing: boolean;

    @Prop()
    model: CaseClinicalInformationDto;

    lvTypes = LookUpValueType;

    private caseStore: CaseModule = CaseStore;
    private usersStore: UserManagementModule = UserManagementStore;
    private groupsStore: GroupsModule = GroupsStore;
    private lookUpStore: LookUpValuesModule = LookUpValuesStore;

    get caseId(): any {
      return this.$route.params.id;
    }

    get isReadOnly() {
      return isFormReadOnly(this.isEditMode, this.isEditing);
    }

    formatDateToString(date: string) {
      return date ? new Date(date).toDateString() : '';
    }

    get managers() {
      return this.usersStore.caseManagers;
    }
    get superVisors() {
      return this.caseStore.getUsersInRole(CaseRoleType.SuperVisor);
    }
    get therapists() {
      return this.usersStore.therapists;
    }
    get peers() {
      return this.usersStore.peers;
    }

    get groups() {
      return this.groupsStore.groupsLightInfo;
    }

    getValueOrDefault(source) {
      return source ? source : PublicConstants.emptyValue;
    }

    getBooleanValueOrDefault(source) {
      return source ? this.$t('yes') : this.$t('no');
    }

    getGroupNameOrDefault(groupId) {
      const group = this.groupsStore.groupsLightInfo.find((t) => t.id === groupId);

      return group?.name || PublicConstants.emptyValue;
    }

    getListValuesOrDefault(source: string[]) {
      return source ? source.join('; ') : PublicConstants.emptyValue;
    }

    getFormattedDate(source: Date) {
      return formatDateWithoutTime(source);
    }

    getUserNames(ids: number[]) {
      let result = '';

      (ids || []).forEach((id) => {
        const user = this.usersStore.users[id];

        if (user) {
          result += `${user.fullName}; `;
        }
      });

      return result;
    }

    getUserFullNameOrDefault(userId) {
      const user = userId ? this.usersStore.users[userId] : null;

      return user ? user.fullName : PublicConstants.emptyValue;
    }

    processGroupChange(useGroups) {
      if (!useGroups) {
        this.model.groupId = null;
      }
    }

    processProgramSuccessChange(isProgramSucceed) {
      if (isProgramSucceed) {
        this.model.programFailReasonId = null;
      }
    }

    getLookUpOptions(groupType: LookUpValueType) {
      return this.lookUpStore.lookUpOptions(groupType);
    }

    getLookUpValue(group: LookUpValueType, id) {
      return this.lookUpStore.getLookUpValueName({ group, id });
    }
  }
