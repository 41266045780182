
  import AsBlock from '@/components/shared/as-block-component.vue';
  import AsButton from '@/components/shared/as-button.vue';
  import AsDropdown from '@/components/shared/as-dropdown.vue';
  import AsInput from '@/components/shared/as-input.vue';
  import { DocumentsModule, DocumentsStore } from '@/store/documents';
  import { ButtonTypes } from '@/types/ButtonTypes';
  import { Options, Vue } from 'vue-class-component';
  import { ref } from 'vue';
  import { LookUpValuesModule, LookUpValuesStore } from '@/store/lookUpValues';
  import { LookUpValueDto, LookUpValueType } from '@/apiclient/apiclient_generated';
  import NewLibraryDocument from './NewLibraryDocument.vue';
  import LibraryDocument from './LibraryDocument.vue';
  import { FilesModule, FilesStore } from '@/store/files';
  import AsModal from '@/components/shared/as-modal.vue';
  import VuePdfApp from 'vue3-pdf-app';
  import Routes from '@/types/Routes';

  @Options({
    components: {
      AsBlock,
      AsButton,
      AsDropdown,
      AsInput,
      VuePdfApp,
      AsModal,
      NewLibraryDocument,
      LibraryDocument,
    },
  })
  export default class LibraryDocuments extends Vue {
    private docsStore: DocumentsModule = DocumentsStore;
    private lookUpStore: LookUpValuesModule = LookUpValuesStore;
    private filesStore: FilesModule = FilesStore;

    buttonTypes = ButtonTypes;
    filters: any = ref({
      docType: null,
      docName: '',
    });

    previewDocument = false;
    isAscSort = false;
    bTypes = ButtonTypes;
    sharedFileId = null;
    previewFile = { name: '', arrayBuffer: null };

    async mounted() {
      this.resetFiltersModel();

      await this.docsStore.getLibraryDocuments();

      this.sharedFileId = this.$route.query?.id;
    }

    navigateToLibrary() {
      this.sharedFileId = null;
      this.$router.push({ name: Routes.library.name, query: null });
    }

    get docTypesOptions(): LookUpValueDto[] {
      return this.lookUpStore.lookUpOptions(LookUpValueType.LibraryFile);
    }

    get documents() {
      return this.docsStore.libraryDocuments;
    }

    get filteredDocs() {
      return this.sharedFileId ? [this.documents.find((t) => t.id == this.sharedFileId)] : this.filteredDocs_;
    }

    get filteredDocs_() {
      let sortedDocs = this.documents.sort((a: any, b: any) => {
        return this.isAscSort ? a.createdDate - b.createdDate : b.createdDate - a.createdDate;
      });

      if (this.filters.docName) {
        sortedDocs = sortedDocs.filter((t) => this.includes(t.name, this.filters.docName));
      }

      if (this.filters.docType) {
        sortedDocs = sortedDocs.filter((t) => t.categoryId === this.filters.docType);
      }

      return sortedDocs;
    }

    private includes(source: string, pattern) {
      return source?.toLowerCase().includes(pattern?.toLowerCase());
    }

    resetFiltersModel() {
      this.filters = {
        docType: null,
        docName: '',
      };
    }

    get fileCategories() {
      return this.lookUpStore.lookUpOptions(LookUpValueType.FileCategory);
    }
  }
