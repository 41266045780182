
  import AsBlock from '@/components/shared/as-block-component.vue';
  import AsButton from '@/components/shared/as-button.vue';
  import AsFilesSelector from '@/components/shared/as-files-selector.vue';
  import FileService from '@/services/file.service';
  import { CaseHistoryModule, CaseHistoryStore } from '@/store/caseHistory';
  import { ButtonTypes } from '@/types/ButtonTypes';
  import { CreateHistoryItemCommand } from '@/types/commands/CreateHistoryItemCommand';
  import { Options, Vue } from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';

  @Options({
    components: {
      AsBlock,
      AsButton,
      AsFilesSelector,
    },
  })
  export default class NewHistoryItem extends Vue {
    private historyStore: CaseHistoryModule = CaseHistoryStore;
    @Prop() caseId;

    bTypes = ButtonTypes;
    showNewItemForm = false;

    command = { files: [], useSystemMessage: false } as CreateHistoryItemCommand;

    isLoading = false;

    mounted() {
      this.resetNewHistoryItem();
    }

    initNewHistoryItem() {
      this.showNewItemForm = true;
    }

    resetNewHistoryItem() {
      this.showNewItemForm = false;

      this.resetModel();
    }

    private resetModel() {
      this.command = { caseId: this.caseId, files: [], useSystemMessage: false } as CreateHistoryItemCommand;
    }

    async createNewHistoryItem() {
      this.isLoading = true;

      const isSuccess = await this.historyStore.createHistoryItem(this.command);

      this.isLoading = false;

      if (isSuccess) {
        this.resetNewHistoryItem();
      }
    }

    get isFormValid() {
      return !this.isLoading && this.command.text && FileService.isFilesFormValid(this.command.files);
    }
  }
