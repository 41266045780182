export enum CaseReadWriteScope {
  NewCase = 'NewCase',
  CaseData = 'CaseData',
  CasePersonalData = 'CasePersonalData',
  CaseHistory = 'CaseHistory',
  Documents = 'Documents',
  Laboratory = 'Laboratory',
  UserRights = 'UserRights',
}

export enum EditPermissions {
  All = 'All',
  Personal = 'Personal',
}

export class WriteRolePermissions {
  role: string;
  permissions: EditPermissions;
}
