import { UserManagementModule } from './userManagement';
import {
  CaseClinicalInformationDto,
  CaseDto,
  CaseFilters,
  CaseResponsibilityDto,
  GetCasesQuery,
  ServiceResultOfCaseClinicalInformationDto,
  UpdateCaseClinicalInformationCommand,
  UpdateCasePersonalDataCommand,
  UpdateCaseResponsibilityCommand,
  CaseClient,
  CasePersonalDataDto,
  CaseDataDto,
  CaseUsersDto,
  CaseUserDto,
  SetCaseUsersCommand,
  CaseRoleType,
  ServiceResultOfCaseDto,
  DataHistoryChange,
  GetCaseQuery,
} from '@/apiclient/apiclient_generated';
import { NotificationService } from './../services/notification.service';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/index';
import { API_URL, WS_URL as ApiUrl } from '@/config';
import axios from 'axios';
import { CreateCaseCommand } from '@/types/commands/CreateCaseCommand';
import { mapFiles, mapObjectToForm } from '@/services/formDataMapper';
import { AuditableEntity } from '@/types/AuditableEntity';
import { initDateInEntity } from '@/services/dateFunctions';
import { PublicConstants } from '@/types/PublicConstants';

@Module({ dynamic: true, namespaced: true, store, name: 'case' })
export class CaseModule extends VuexModule {
  private client = new CaseClient(ApiUrl, axios);

  currentCase: CaseDto = {
    caseData: {} as CaseDataDto,
    personalData: {} as CasePersonalDataDto,
    caseClinicalInformation: {} as CaseClinicalInformationDto,
    createdBy: '',
  } as CaseDto;

  caseUsers = {} as CaseUsersDto;

  caseChanges: DataHistoryChange[] = [];

  private cases: CaseDto[] = [];

  get getUsersInRole() {
    const usersStore = getModule(UserManagementModule);

    return function (role: CaseRoleType) {
      const users: CaseUserDto[] = this.caseUsers.caseUsers.filter((t: CaseUserDto) => t.caseRole === role);

      const usersDetails = users.map((t) => {
        const user = usersStore.users[t.userId];

        if (user) {
          user.isDeleted = t.isDeleted || user.isDeleted;
        }

        return user;
      });

      return usersDetails;
    };

    // const result = this.usersArray.filter((t) => t.roles && t.roles.includes(ApplicationRoleType.SuperVisor));
    // return result;
  }

  get isCaseDeleted() {
    return this.currentCase.caseData.isDeleted;
  }

  get activeCaseUsers() {
    return this.caseUsers.caseUsers?.filter((t) => !t.isDeleted);
  }

  @Action
  async updateCasePersonalData(command: UpdateCasePersonalDataCommand) {
    const result = await this.client.updateCasePersonalData(command);

    this.processUpdateCasePersonalData(result);

    return result.isSuccess;
  }

  @Action
  async getCaseUsers(caseId) {
    const result = await this.client.getCaseUsers(caseId);

    this.setCaseUsers(result);
  }

  @Action
  async setCaseUsersAsync(command: SetCaseUsersCommand) {
    const result = await this.client.setCaseUsers(command);

    this.setCaseUsersWithRights(result);
  }

  @Action
  async getCaseFilters(): Promise<CaseFilters> {
    const result = await this.client.getCaseFilters();

    return result;
  }

  @Action
  async updateCaseResponsibilityAsync(command: UpdateCaseResponsibilityCommand) {
    const result = await this.client.updateCaseResponsibility(command);

    this.updateCaseResponsibility(result);

    return result.isSuccess;
  }

  @Action
  async updateCaseClinicalInformationAsync(command: UpdateCaseClinicalInformationCommand) {
    const result = await this.client.updateCaseClinicalInformation(command);
    this.updateCaseClinicalInformation(result);

    return result.isSuccess;
  }

  get sortedCases() {
    if (!this.cases.length) {
      return this.cases;
    }

    return this.cases.sort((a: CaseDto, b: CaseDto) => {
      const aa = a.caseData as AuditableEntity;
      const bb = b.caseData as AuditableEntity;

      return aa.createdDate - bb.createdDate;
    });
  }

  @Action
  async createCase(command: CreateCaseCommand): Promise<string> {
    const formData = new FormData();

    formData.append('CaseManagerId', `${command.CaseManagerId}`);

    mapObjectToForm(command.PersonalData, 'PersonalData', formData);

    if (command.HistoryData) {
      formData.append(`HistoryData.HistoryItemText`, command.HistoryData.HistoryItemText || '');

      mapFiles(command.HistoryData.Files, 'HistoryData.Files', formData);
    }

    const newCase: any = await axios.post(`${API_URL}/case/new`, formData, {
      headers: PublicConstants.multiPartHeaders,
    });

    return newCase.data.entity;
  }

  @Action
  async getCases(query: GetCasesQuery) {
    const result = await this.client.getCases(query);

    this.setCases(result);
  }

  @Action
  async getCaseDataHistoryChangesAsync(caseId) {
    const changes = await this.client.getCaseDataHistoryChanges(caseId);

    this.setCaseDataHistoryChangesAsync(changes);
  }

  @Action
  async getCase(query: GetCaseQuery) {
    const caseDto = await this.client.getCase(query);

    this.setCase(caseDto);
  }

  @Mutation
  setCaseDataHistoryChangesAsync(changes: DataHistoryChange[]) {
    this.caseChanges = changes;
  }

  @Mutation
  setCases(cases: CaseDto[]) {
    const store = getModule(CaseModule);
    cases.forEach((c: CaseDto) => {
      store.initDatesInCaseData(c);
    });
    this.cases = cases;
  }

  @Mutation
  setCase(caseDto: CaseDto) {
    const store = getModule(CaseModule);

    caseDto.personalData ??= {} as CasePersonalDataDto;
    caseDto.caseResponsibility ??= {} as CaseResponsibilityDto;
    caseDto.caseClinicalInformation ??= {} as CaseClinicalInformationDto;

    store.initDatesInCaseData(caseDto);
    store.initCasePersonalDataDates(caseDto.personalData);
    store.initCaseResponsibilityDates(caseDto.caseResponsibility);
    store.initCaseClinicalInformationDates(caseDto.caseClinicalInformation);

    this.currentCase = caseDto;
  }

  @Mutation
  addCase(newCase: CaseDto) {
    if (this.cases.find((t) => t.caseData.id === newCase.caseData.id)) {
      return;
    }

    const store = getModule(CaseModule);
    store.initDatesInCaseData(newCase);
    store.initCasePersonalDataDates(newCase.personalData);
    store.initCaseResponsibilityDates(newCase.caseResponsibility);

    this.cases.push(newCase);
  }

  @Mutation
  addAllItemToCaseFilters(allItem) {
    // if (this.casesWithFilters.caseFilters.years?.length) {
    //   this.casesWithFilters.caseFilters.years.unshift(allItem);
    // }
    // if (this.casesWithFilters.caseFilters.programs?.length) {
    //   this.casesWithFilters.caseFilters.programs.unshift(allItem);
    // }
    // if (this.casesWithFilters.caseFilters.groups?.length) {
    //   this.casesWithFilters.caseFilters.groups.unshift(allItem);
    // }
  }

  @Mutation
  private initDatesInCaseData(entity: CaseDto) {
    if (!entity.caseData) {
      return;
    }

    initDateInEntity(entity.caseData, 'created', 'createdDate');
    initDateInEntity(entity.caseData, 'modified', 'modifiedDate');
  }

  @Mutation
  setCasePersonalData(personalData: CasePersonalDataDto) {
    this.currentCase.personalData = personalData;
  }

  @Mutation
  setCaseResponsibility(caseResponsibility: CaseResponsibilityDto) {
    this.currentCase.caseResponsibility = caseResponsibility;
  }

  @Mutation
  setCaseClinicalInformation(caseClinicalInformation: CaseClinicalInformationDto) {
    this.currentCase.caseClinicalInformation = caseClinicalInformation;
  }

  @Mutation
  processUpdateCasePersonalData(result: ServiceResultOfCaseDto) {
    if (result.isSuccess) {
      this.currentCase.caseData.caseNumber = result.entity.caseData.caseNumber;
      this.currentCase.personalData = result.entity.personalData;

      const store = getModule(CaseModule);
      store.initCasePersonalDataDates(this.currentCase.personalData);

      const listCase = this.cases.find((t) => t.caseData.id == result.entity.caseData.id);
      if (listCase) {
        listCase.personalData = this.currentCase.personalData;
      }
      NotificationService.NotifySuccess('Case data was successfully updated');
    } else {
      result.errors.forEach((err) => {
        NotificationService.NotifyError(err);
      });
    }
  }

  @Mutation
  updateCaseResponsibility(result: ServiceResultOfCaseDto) {
    if (result.isSuccess) {
      this.currentCase.caseData.caseNumber = result.entity.caseData.caseNumber;
      this.currentCase.caseResponsibility = result.entity.caseResponsibility;

      const store = getModule(CaseModule);
      store.initCaseResponsibilityDates(this.currentCase.caseResponsibility);

      NotificationService.NotifySuccess('Case responsibility was successfully updated');
    } else {
      result.errors.forEach((err) => {
        NotificationService.NotifyError(err);
      });
    }
  }

  @Mutation
  updateCaseClinicalInformation(result: ServiceResultOfCaseClinicalInformationDto) {
    if (result.isSuccess) {
      this.currentCase.caseClinicalInformation = result.entity;

      const store = getModule(CaseModule);
      store.initCaseClinicalInformationDates(this.currentCase.caseClinicalInformation);

      NotificationService.NotifySuccess('Case Clinical Information was successfully updated');
    } else {
      result.errors.forEach((err) => {
        NotificationService.NotifyError(err);
      });
    }
  }
  @Mutation
  setCaseUsers(users: CaseUsersDto) {
    this.caseUsers = users;
  }
  @Mutation
  setCaseUsersWithRights(users: CaseUserDto[]) {
    this.caseUsers.caseUsers = users;
  }

  @Mutation
  private initCasePersonalDataDates(entity: CasePersonalDataDto) {
    entity ??= {} as CasePersonalDataDto;

    initDateInEntity(entity, 'dateOfBirth', 'dateOfBirthDateTime');
  }
  @Mutation
  private initCaseResponsibilityDates(entity: CaseResponsibilityDto) {
    entity ??= {} as CaseResponsibilityDto;

    initDateInEntity(entity, 'firstContactDate', 'firstContactDateTime');
    initDateInEntity(entity, 'firstConsultationDate', 'firstConsultationDateTime');
    initDateInEntity(entity, 'secondConsultationDate', 'secondConsultationDateTime');
    initDateInEntity(entity, 'assessmentDate', 'assessmentDateTime');
    initDateInEntity(entity, 'medicalAgreementSignedDate', 'medicalAgreementSignedDateTime');
  }
  @Mutation
  private initCaseClinicalInformationDates(entity: CaseClinicalInformationDto) {
    entity ??= {} as CaseClinicalInformationDto;

    initDateInEntity(entity, 'programStartDate', 'programStartDateTime');
    initDateInEntity(entity, 'plannedProgramEndDate', 'plannedProgramEndDateTime');
    initDateInEntity(entity, 'medicalExpirationDate', 'medicalExpirationDateTime');

    if (entity.historyInputs) {
      entity.historyInputs.forEach((history) => {
        initDateInEntity(history, 'date', 'dateTime');
      });
    }

    if (entity.hospitalizationHistory) {
      entity.hospitalizationHistory.forEach((historyItem) => {
        initDateInEntity(historyItem, 'fromDate', 'fromDateTime');
        initDateInEntity(historyItem, 'toDate', 'toDateTime');
      });
    }

    initDateInEntity(entity, 'programEndDate', 'programEndDateTime');
    initDateInEntity(entity, 'finalLetterSentDate', 'finalLetterSentDateTime');
  }
}

export const CaseStore = getModule(CaseModule);
