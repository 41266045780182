import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e112aa1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tab-menu outline-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabMenu = _resolveComponent("TabMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TabMenu, {
      model: _ctx.menu.items,
      onTabChange: _ctx.processTabChange,
      activeIndex: _ctx.activeIndex,
      "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeIndex) = $event))
    }, null, 8, ["model", "onTabChange", "activeIndex"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu.items, (view) => {
      return (_openBlock(), _createElementBlock("div", {
        key: view.label
      }, [
        (view.isActive)
          ? _renderSlot(_ctx.$slots, `${view.label}`, { key: 0 }, undefined, true)
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}