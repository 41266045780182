import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08244de5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "doc-filters" }
const _hoisted_2 = { class: "doc-filters-date" }
const _hoisted_3 = { class: "doc-filters-type" }
const _hoisted_4 = { class: "doc-filters-search" }
const _hoisted_5 = { class: "doc-filters-clear" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { class: "empty-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewDocument = _resolveComponent("NewDocument")!
  const _component_fa = _resolveComponent("fa")!
  const _component_AsButton = _resolveComponent("AsButton")!
  const _component_AsDropdown = _resolveComponent("AsDropdown")!
  const _component_AsInput = _resolveComponent("AsInput")!
  const _component_AsBlock = _resolveComponent("AsBlock")!
  const _component_ListDocument = _resolveComponent("ListDocument")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.isCaseDeleted && _ctx.canCreate)
      ? (_openBlock(), _createBlock(_component_NewDocument, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_AsBlock, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_AsButton, {
              type: _ctx.buttonTypes.Outlined,
              class: _normalizeClass(_ctx.buttonTypes.Secondary),
              label: _ctx.$t('date'),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isAscSort = !_ctx.isAscSort))
            }, {
              icon: _withCtx(() => [
                (_ctx.isAscSort)
                  ? (_openBlock(), _createBlock(_component_fa, {
                      key: 0,
                      icon: "icon fa-arrow-up"
                    }))
                  : (_openBlock(), _createBlock(_component_fa, {
                      key: 1,
                      icon: "icon fa-arrow-down"
                    }))
              ]),
              _: 1
            }, 8, ["type", "class", "label"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_AsDropdown, {
              placeholder: _ctx.$t('lookUpValues.typePlaceholder'),
              clearIcon: true,
              value: _ctx.filters.docType,
              options: _ctx.docTypesOptions,
              optionLabel: 'value',
              optionValue: 'id',
              onUpdate: _cache[1] || (_cache[1] = (value) => (_ctx.filters.docType = value))
            }, null, 8, ["placeholder", "value", "options"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_AsInput, {
              placeholder: _ctx.$t('library.searchPlaceholder'),
              value: _ctx.filters.docName,
              onUpdate: _cache[2] || (_cache[2] = (value) => (_ctx.filters.docName = value))
            }, null, 8, ["placeholder", "value"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_AsButton, {
              type: _ctx.buttonTypes.Outlined,
              class: _normalizeClass(_ctx.buttonTypes.Secondary),
              label: _ctx.$t('clear'),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.resetFiltersModel()))
            }, null, 8, ["type", "class", "label"])
          ])
        ])
      ]),
      _: 1
    }),
    (_ctx.canRead(_ctx.caseScope.Documents) && _ctx.filteredDocs && _ctx.filteredDocs.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredDocs, (item) => {
            return (_openBlock(), _createBlock(_component_AsBlock, { key: item }, {
              default: _withCtx(() => [
                _createVNode(_component_ListDocument, { document: item }, null, 8, ["document"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_AsBlock, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('noData')), 1)
            ]),
            _: 1
          })
        ]))
  ]))
}