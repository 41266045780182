import moment from 'moment';

export function initDateInEntity(entity, sourceProperty, destinationProperty) {
  const value = entity[sourceProperty];

  if (value) {
    entity[destinationProperty] = new Date(value);
  }
}

export function formatDate(date) {
  if (!date) {
    return date;
  }

  return moment(date).format('DD.MM.yyyy HH:mm');
}

export function formatDateToISOString(date) {
  if (!date) {
    return date;
  }

  return moment(date).toISOString();
}

export function formatDateWithoutTime(date) {
  if (!date) {
    return date;
  }

  return moment(date).format('DD.MM.yyyy');
}

export function formatStringDate(date) {
  if (!date) {
    return date;
  }

  return moment(new Date(date)).format('DD.MM.yyyy');
}
