
  import AsBlock from '@/components/shared/as-block-component.vue';
  import AsButton from '@/components/shared/as-button.vue';
  import AsDropdown from '@/components/shared/as-dropdown.vue';
  import AsInput from '@/components/shared/as-input.vue';
  import { Options, Vue } from 'vue-class-component';
  import { CaseModule, CaseStore } from '@/store/case';
  import AsTextWithLabel from '@/components/shared/as-textWithLabel.vue';
  import { CaseRoleType, CaseUserDto, SetCaseUsersCommand, UserDto } from '@/apiclient/apiclient_generated';
  import { ButtonTypes } from '@/types/ButtonTypes';
  import { UserManagementModule, UserManagementStore } from '@/store/userManagement';
  import { NotificationService } from '@/services/notification.service';
  import { CaseReadWriteScope } from '@/types/CaseReadWriteScope';
  import AccessRightsService from '@/services/accessRightsService';

  @Options({
    components: {
      AsBlock,
      AsButton,
      AsDropdown,
      AsInput,
      AsTextWithLabel,
    },
  })
  export default class CaseUsers extends Vue {
    private caseStore: CaseModule = CaseStore;
    private usersStore: UserManagementModule = UserManagementStore;

    buttonTypes = ButtonTypes;
    caseScope = CaseReadWriteScope;
    manuallyAddedCaseUsers = [] as CaseUserDto[];

    private caseRolesOptionsSource = [
      CaseRoleType.FullAccess,
      CaseRoleType.Readonly,
      CaseRoleType.Peer,
      CaseRoleType.Therapist,
      CaseRoleType.SuperVisor,
      CaseRoleType.ClinicStaff,
      CaseRoleType.Laboratory,
      CaseRoleType.TechnicalSuperVisor,
    ];

    async mounted() {
      await this.caseStore.getCaseUsers(this.caseId);
    }

    get caseRolesOptions() {
      return this.caseRolesOptionsSource.map((item) => {
        return { key: item, label: this.$t(item) };
      });
    }

    get users() {
      return this.caseStore.caseUsers;
    }

    get filteredCaseUsers() {
      return this.users.caseUsers.filter((t: CaseUserDto) => !t.isDeleted);
    }

    removeCaseUser(user: CaseUserDto) {
      const userIndex = this.users.caseUsers.findIndex((t) => t.userId == user.userId);

      let role = '';

      if (user.caseRole === CaseRoleType.Peer && this.caseStore.currentCase?.caseClinicalInformation?.advisorId === user.userId) {
        role = this.$t('Peer');
      } else if (
        user.caseRole === CaseRoleType.Therapist &&
        this.caseStore.currentCase?.caseClinicalInformation?.therapistId === user.userId
      ) {
        role = this.$t('Therapist');
      }

      if (role) {
        const message = this.$t('errors.cantRemoveCaseUser');

        NotificationService.NotifyWarn(message.replace('$role', role));
      } else {
        this.users.caseUsers.splice(userIndex, 1);
      }
    }

    removeDraftCaseUser(index) {
      this.manuallyAddedCaseUsers.splice(index, 1);
    }

    async saveRights() {
      const command = {
        caseId: this.caseId,
        caseUsers: this.resultCaseUsers,
      } as SetCaseUsersCommand;

      await this.caseStore.setCaseUsersAsync(command);

      NotificationService.NotifySuccess(this.$t('case.rights.updateMessage'));

      this.manuallyAddedCaseUsers = [];
    }

    get resultCaseUsers() {
      const validNewCaseUsers = this.manuallyAddedCaseUsers.filter((t) => t.userId && t.caseRole);

      const caseUsers = [...this.users.caseUsers, ...validNewCaseUsers];

      return caseUsers;
    }

    get allAppUsers() {
      return this.usersStore.users;
    }

    processManualUserSelect(destinationUser: CaseUserDto, userId) {
      if (this.selectedCaseUsers.some((id) => id === userId)) {
        NotificationService.NotifyWarn(this.$t('errors.addingExistingCaseUser'));
      } else {
        destinationUser.userId = userId;
      }
    }

    get caseId(): any {
      return this.$route.params.id;
    }

    get usersArray() {
      return this.usersStore.usersArray;
    }

    private get currentCaseCreator(): number {
      return +this.caseStore.currentCase.createdBy;
    }

    isCurrentCaseCreator(user: CaseUserDto) {
      return user.userId === this.currentCaseCreator;
    }

    addNewCaseUser() {
      this.manuallyAddedCaseUsers.push({
        caseId: this.caseId,
      } as CaseUserDto);
    }

    getUserFullName(user: CaseUserDto) {
      return this.allAppUsers[user.userId]?.fullName;
    }

    private manuallyCreated = 'user.manuallyCreated';
    private automaticallyCreated = 'user.automaticallyCreated';

    getRoleDetails(user: CaseUserDto) {
      const label = user.isAutoCreation ? this.automaticallyCreated : this.manuallyCreated;

      return this.$t(label);
    }

    get selectedCaseUsers() {
      return [...this.filteredCaseUsers.map((t) => t.userId), ...this.manuallyAddedCaseUsers.filter((t) => t.userId).map((t) => t.userId)];
    }

    canRead(caseScope: CaseReadWriteScope) {
      return AccessRightsService.canUserRead(caseScope);
    }

    canWrite(caseScope: CaseReadWriteScope) {
      return AccessRightsService.canUserWrite(caseScope);
    }
  }
