import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsTextWithLabel = _resolveComponent("AsTextWithLabel")!

  return (_ctx.isReadOnly)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_AsTextWithLabel, {
          label: _ctx.label,
          value: _ctx.value || '---',
          valueClass: _ctx.labelClass
        }, null, 8, ["label", "value", "valueClass"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", null, _toDisplayString(_ctx.label), 1),
        _renderSlot(_ctx.$slots, "default")
      ]))
}