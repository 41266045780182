
  import { Options, Vue } from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import AsDropdown from '@/components/shared/as-dropdown.vue';
  import AsTextWithLabel from '@/components/shared/as-textWithLabel.vue';
  import AsReadWriteBlock from '@/components/shared/as-read-write.vue';
  import AsLabeledBlock from '@/components/shared/as-labeled-block.vue';
  import { HospitalizationHistoryDto, LookUpValueType } from '@/apiclient/apiclient_generated';
  import AsMultiSelect from '@/components/shared/as-multi-select.vue';
  import { formatDateWithoutTime } from '@/services/dateFunctions';
  import AsInput from '@/components/shared/as-input.vue';
  import { getFormValueOrDefault } from '@/services/formFunctions';
  import { ButtonTypes } from '@/types/ButtonTypes';
  import AsButton from '@/components/shared/as-button.vue';
  import AsDatepicker from '@/components/shared/as-datepicker.vue';
  import moment from 'moment';
  import { LookUpValuesModule, LookUpValuesStore } from '@/store/lookUpValues';

  @Options({
    components: {
      AsDropdown,
      AsTextWithLabel,
      AsReadWriteBlock,
      AsLabeledBlock,
      AsMultiSelect,
      AsInput,
      AsDatepicker,
      AsButton,
    },
  })
  export default class HospitalizationHistory extends Vue {
    @Prop()
    isReadOnly: boolean;
    @Prop()
    isEditing: boolean;

    bTypes = ButtonTypes;

    newItem: HospitalizationHistoryDto;

    @Prop()
    model: HospitalizationHistoryDto[];

    private lookUpStore: LookUpValuesModule = LookUpValuesStore;
    lvTypes = LookUpValueType;

    getLookUpOptions(groupType: LookUpValueType) {
      return this.lookUpStore.lookUpOptions(groupType);
    }

    getLookUpValue(group: LookUpValueType, id) {
      return this.lookUpStore.getLookUpValueName({ group, id });
    }

    mounted(): void {
      this.newItem = {} as HospitalizationHistoryDto;
    }

    get caseId(): any {
      return this.$route.params.id;
    }

    addNewHistoryItem() {
      this.newItem = {} as HospitalizationHistoryDto;

      this.model.push(this.newItem);
    }

    removeItem(item) {
      const index = this.model.indexOf(item);

      this.model.splice(index, 1);
    }

    getValueOrDefault(value) {
      return getFormValueOrDefault(value);
    }

    getReadOnlyLabel(item: HospitalizationHistoryDto) {
      const type = this.getLookUpValue(this.lvTypes.Hospitalization, item.hospitalizationTypeId);
      const from = item['fromDateTime'] ? formatDateWithoutTime(item['fromDateTime']) : 'N/A';
      const to = item['toDateTime'] ? formatDateWithoutTime(item['toDateTime']) : 'N/A';

      return `${type}: ${from} - ${to}`;
    }

    processDateUpdate(history: HospitalizationHistoryDto) {
      if (history.toDate && moment(history.fromDate).isAfter(history.toDate)) {
        history.toDate = '';
      }
    }
  }
