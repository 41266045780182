import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c680b68"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "row-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsDropdown = _resolveComponent("AsDropdown")!
  const _component_AsDatepicker = _resolveComponent("AsDatepicker")!
  const _component_AsButton = _resolveComponent("AsButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["hospitalization-history", { padding: _ctx.isReadOnly }])
  }, [
    (_ctx.isReadOnly)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.model && _ctx.model.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.id
                  }, _toDisplayString(_ctx.getReadOnlyLabel(item)), 1))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.getValueOrDefault('')), 1))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.id,
              class: "grid-group-of-three"
            }, [
              _createVNode(_component_AsDropdown, {
                clearIcon: true,
                class: "grid-group-of-three-first",
                value: item.hospitalizationTypeId,
                options: _ctx.getLookUpOptions(_ctx.lvTypes.Hospitalization),
                placeholder: _ctx.$t('SelectTypePlaceholder'),
                optionValue: 'id',
                optionLabel: 'value',
                label: _ctx.$t('type'),
                onUpdate: (value) => (item.hospitalizationTypeId = value)
              }, null, 8, ["value", "options", "placeholder", "label", "onUpdate"]),
              _createVNode(_component_AsDatepicker, {
                class: "grid-group-of-three-second",
                placeholder: "Select date",
                value: item.fromDate,
                enableTimePicker: false,
                label: _ctx.$t('from'),
                onUpdate: (date) => ((item.fromDate = date), _ctx.processDateUpdate(item))
              }, null, 8, ["value", "label", "onUpdate"]),
              _createVNode(_component_AsDatepicker, {
                class: "grid-group-of-three-third",
                placeholder: "Select date",
                value: item.toDate,
                enableTimePicker: false,
                min: item.fromDate ? item.fromDate : null,
                label: _ctx.$t('to'),
                onUpdate: (date) => (item.toDate = date)
              }, {
                default: _withCtx(() => [
                  _createElementVNode("i", {
                    class: "pi pi-trash",
                    onClick: ($event: any) => (_ctx.removeItem(item))
                  }, null, 8, _hoisted_5)
                ]),
                _: 2
              }, 1032, ["value", "min", "label", "onUpdate"])
            ]))
          }), 128)),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", null, [
              _createVNode(_component_AsButton, {
                class: "right-aligned-items",
                type: _ctx.bTypes.Primary,
                label: _ctx.$t('AddNew'),
                onClick: _ctx.addNewHistoryItem
              }, null, 8, ["type", "label", "onClick"])
            ])
          ])
        ]))
  ], 2))
}