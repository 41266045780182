
  import { LayoutModule, LayoutStore } from '@/store/layout';
  import { Options, Vue } from 'vue-class-component';

  @Options({})
  export default class AsSpinner extends Vue {
    private layoutStore: LayoutModule = LayoutStore;

    get showSpinner() {
      return this.layoutStore.showSpinner;
    }
  }
