
  import { Options, Vue } from 'vue-class-component';
  import Button from 'primevue/button';
  import { Prop } from 'vue-property-decorator';
  import { ButtonTypes } from '@/types/ButtonTypes';

  @Options({
    components: {
      Button,
    },
  })
  export default class AsButton extends Vue {
    @Prop() class;
    @Prop() type;
    @Prop() label;

    @Prop() disabled;
    @Prop() hideIcon;

    types: { [key in ButtonTypes]: string } = {
      [ButtonTypes.Primary]: 'p-button-primary',
      [ButtonTypes.Secondary]: 'p-button-secondary',
      [ButtonTypes.Success]: 'p-button-success',
      [ButtonTypes.Danger]: 'p-button-danger',
      [ButtonTypes.Outlined]: 'p-button-outlined',
    };

    get buttonType() {
      return this.types[this.type];
    }

    get buttonClass() {
      return this.types[this.class];
    }

    get justifyContent() {
      return this.hideIcon ? 'justify-center' : 'justify-space-between';
    }
  }
