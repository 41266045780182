import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ec99c57c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "case-responsibility" }
const _hoisted_2 = { class: "grid-container" }
const _hoisted_3 = { class: "form-item" }
const _hoisted_4 = { class: "grid-group-of-three" }
const _hoisted_5 = { class: "grid-group-of-two" }
const _hoisted_6 = { class: "grid-group-of-two" }
const _hoisted_7 = { class: "grid-group-of-two" }
const _hoisted_8 = { class: "grid-group-of-two" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsDropdown = _resolveComponent("AsDropdown")!
  const _component_AsReadWriteBlock = _resolveComponent("AsReadWriteBlock")!
  const _component_AsDatepicker = _resolveComponent("AsDatepicker")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_AsLabeledBlock = _resolveComponent("AsLabeledBlock")!
  const _component_AsMultiSelect = _resolveComponent("AsMultiSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_AsReadWriteBlock, {
          label: _ctx.$t('case.responsibility.Manager'),
          value: _ctx.firstContactManager,
          isReadOnly: _ctx.isReadOnly
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AsDropdown, {
              placeholder: 'Select Manager',
              clearIcon: true,
              value: _ctx.responsibility.managerId,
              options: _ctx.managers,
              optionValue: 'id',
              optionLabel: 'fullName',
              onUpdate: _cache[0] || (_cache[0] = (value) => (_ctx.responsibility.managerId = value))
            }, null, 8, ["value", "options"])
          ]),
          _: 1
        }, 8, ["label", "value", "isReadOnly"])
      ])
    ]),
    _createVNode(_component_AsLabeledBlock, {
      header: _ctx.$t('case.responsibility.FirstContact.Title')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_AsReadWriteBlock, {
            class: "form-item grid-group-of-three-first",
            label: _ctx.$t('case.responsibility.FirstContact.Date'),
            value: _ctx.getFormattedDate(_ctx.responsibility.firstContactDate),
            isReadOnly: _ctx.isReadOnly
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AsDatepicker, {
                placeholder: _ctx.$t('datePlaceholder'),
                value: _ctx.responsibility.firstContactDate,
                enableTimePicker: false,
                onUpdate: _cache[1] || (_cache[1] = (date) => (_ctx.responsibility.firstContactDate = date))
              }, null, 8, ["placeholder", "value"])
            ]),
            _: 1
          }, 8, ["label", "value", "isReadOnly"]),
          _createVNode(_component_AsReadWriteBlock, {
            class: "form-item grid-group-of-three-second",
            label: _ctx.$t('case.responsibility.FirstContact.AsPerson'),
            value: _ctx.getValueOrDefault(_ctx.responsibility.firstContactAsPerson),
            isReadOnly: _ctx.isReadOnly
          }, {
            default: _withCtx(() => [
              _createVNode(_component_InputText, {
                class: "form-control",
                type: "text",
                modelValue: _ctx.responsibility.firstContactAsPerson,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.responsibility.firstContactAsPerson) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label", "value", "isReadOnly"]),
          _createVNode(_component_AsReadWriteBlock, {
            class: "form-item grid-group-of-three-third",
            label: _ctx.$t('case.responsibility.FirstContact.ContactType'),
            value: _ctx.getLookUpValue(_ctx.lvTypes.Contact, _ctx.responsibility.contactTypeId),
            isReadOnly: _ctx.isReadOnly
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AsDropdown, {
                placeholder: _ctx.$t('case.responsibility.FirstContact.ContactType'),
                clearIcon: true,
                value: _ctx.responsibility.contactTypeId,
                options: _ctx.getLookUpOptions(_ctx.lvTypes.Contact),
                optionValue: 'id',
                optionLabel: 'value',
                onUpdate: _cache[3] || (_cache[3] = (value) => (_ctx.responsibility.contactTypeId = value))
              }, null, 8, ["placeholder", "value", "options"])
            ]),
            _: 1
          }, 8, ["label", "value", "isReadOnly"])
        ])
      ]),
      _: 1
    }, 8, ["header"]),
    _createVNode(_component_AsLabeledBlock, {
      header: _ctx.$t('case.responsibility.Consultation.First.Title')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_AsReadWriteBlock, {
            class: "form-item grid-group-of-two-first",
            label: _ctx.$t('case.responsibility.Consultation.First.Date'),
            value: _ctx.getFormattedDate(_ctx.responsibility['firstConsultationDateTime']),
            isReadOnly: _ctx.isReadOnly
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AsDatepicker, {
                placeholder: _ctx.$t('datePlaceholder'),
                value: _ctx.responsibility.firstConsultationDate,
                enableTimePicker: false,
                onUpdate: _cache[4] || (_cache[4] = (date) => (_ctx.responsibility.firstConsultationDate = date))
              }, null, 8, ["placeholder", "value"])
            ]),
            _: 1
          }, 8, ["label", "value", "isReadOnly"]),
          _createVNode(_component_AsReadWriteBlock, {
            class: "form-item grid-group-of-two-second",
            label: _ctx.$t('case.responsibility.Consultation.First.By'),
            value: _ctx.getUserNames(_ctx.responsibility.firstConsultationUsers),
            isReadOnly: _ctx.isReadOnly
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AsMultiSelect, {
                placeholder: _ctx.$t('selectContactsPlaceholder'),
                clearIcon: true,
                value: _ctx.responsibility.firstConsultationUsers,
                options: _ctx.peers,
                optionValue: 'id',
                optionLabel: 'fullName',
                onUpdate: _cache[5] || (_cache[5] = (updates) => (_ctx.responsibility.firstConsultationUsers = updates))
              }, null, 8, ["placeholder", "value", "options"])
            ]),
            _: 1
          }, 8, ["label", "value", "isReadOnly"])
        ])
      ]),
      _: 1
    }, 8, ["header"]),
    _createVNode(_component_AsLabeledBlock, {
      header: _ctx.$t('case.responsibility.Assessment.Title')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_AsReadWriteBlock, {
            class: "form-item grid-group-of-two-first",
            label: _ctx.$t('case.responsibility.Consultation.Second.Date'),
            value: _ctx.getFormattedDate(_ctx.responsibility['assessmentDateTime']),
            isReadOnly: _ctx.isReadOnly
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AsDatepicker, {
                placeholder: _ctx.$t('datePlaceholder'),
                value: _ctx.responsibility.assessmentDate,
                enableTimePicker: false,
                onUpdate: _cache[6] || (_cache[6] = (date) => (_ctx.responsibility.assessmentDate = date))
              }, null, 8, ["placeholder", "value"])
            ]),
            _: 1
          }, 8, ["label", "value", "isReadOnly"]),
          _createVNode(_component_AsReadWriteBlock, {
            class: "form-item grid-group-of-two-second",
            label: _ctx.$t('case.responsibility.Assessment.By'),
            value: _ctx.getUserNames(_ctx.responsibility.assessmentUsers),
            isReadOnly: _ctx.isReadOnly
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AsMultiSelect, {
                placeholder: _ctx.$t('selectContactsPlaceholder'),
                clearIcon: true,
                value: _ctx.responsibility.assessmentUsers,
                options: _ctx.technicalSuperVisors,
                optionValue: 'id',
                optionLabel: 'fullName',
                onUpdate: _cache[7] || (_cache[7] = (updates) => (_ctx.responsibility.assessmentUsers = updates))
              }, null, 8, ["placeholder", "value", "options"])
            ]),
            _: 1
          }, 8, ["label", "value", "isReadOnly"])
        ])
      ]),
      _: 1
    }, 8, ["header"]),
    _createVNode(_component_AsLabeledBlock, {
      header: _ctx.$t('case.responsibility.Consultation.Second.Title')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_AsReadWriteBlock, {
            class: "form-item grid-group-of-two-first",
            label: _ctx.$t('case.responsibility.Consultation.Second.Date'),
            value: _ctx.getFormattedDate(_ctx.responsibility['secondConsultationDateTime']),
            isReadOnly: _ctx.isReadOnly
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AsDatepicker, {
                placeholder: _ctx.$t('datePlaceholder'),
                value: _ctx.responsibility.secondConsultationDate,
                enableTimePicker: false,
                onUpdate: _cache[8] || (_cache[8] = (date) => (_ctx.responsibility.secondConsultationDate = date))
              }, null, 8, ["placeholder", "value"])
            ]),
            _: 1
          }, 8, ["label", "value", "isReadOnly"]),
          _createVNode(_component_AsReadWriteBlock, {
            class: "form-item grid-group-of-two-second",
            label: _ctx.$t('case.responsibility.Consultation.Second.By'),
            value: _ctx.getUserNames(_ctx.responsibility.secondConsultationUsers),
            isReadOnly: _ctx.isReadOnly
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AsMultiSelect, {
                placeholder: _ctx.$t('selectContactsPlaceholder'),
                clearIcon: true,
                value: _ctx.responsibility.secondConsultationUsers,
                options: _ctx.peers,
                optionValue: 'id',
                optionLabel: 'fullName',
                onUpdate: _cache[9] || (_cache[9] = (updates) => (_ctx.responsibility.secondConsultationUsers = updates))
              }, null, 8, ["placeholder", "value", "options"])
            ]),
            _: 1
          }, 8, ["label", "value", "isReadOnly"])
        ])
      ]),
      _: 1
    }, 8, ["header"]),
    _createVNode(_component_AsLabeledBlock, {
      header: _ctx.$t('case.responsibility.TreatmentAgreement.Title')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_AsReadWriteBlock, {
            class: "form-item grid-group-of-two-first",
            label: _ctx.$t('case.responsibility.TreatmentAgreement.Date'),
            value: _ctx.getFormattedDate(_ctx.responsibility['medicalAgreementSignedDateTime']),
            isReadOnly: _ctx.isReadOnly
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AsDatepicker, {
                placeholder: _ctx.$t('datePlaceholder'),
                value: _ctx.responsibility.medicalAgreementSignedDate,
                enableTimePicker: false,
                onUpdate: _cache[10] || (_cache[10] = (date) => (_ctx.responsibility.medicalAgreementSignedDate = date))
              }, null, 8, ["placeholder", "value"])
            ]),
            _: 1
          }, 8, ["label", "value", "isReadOnly"]),
          _createVNode(_component_AsReadWriteBlock, {
            class: "form-item grid-group-of-two-second",
            label: _ctx.$t('case.responsibility.TreatmentAgreement.By'),
            value: _ctx.getUserNames(_ctx.responsibility.medicalAgreementUsers),
            isReadOnly: _ctx.isReadOnly
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AsMultiSelect, {
                placeholder: _ctx.$t('selectContactsPlaceholder'),
                clearIcon: true,
                value: _ctx.responsibility.medicalAgreementUsers,
                options: _ctx.peers,
                optionValue: 'id',
                optionLabel: 'fullName',
                onUpdate: _cache[11] || (_cache[11] = (updates) => (_ctx.responsibility.medicalAgreementUsers = updates))
              }, null, 8, ["placeholder", "value", "options"])
            ]),
            _: 1
          }, 8, ["label", "value", "isReadOnly"])
        ])
      ]),
      _: 1
    }, 8, ["header"])
  ]))
}