
  import { Options, Vue } from 'vue-class-component';
  import Layout from '@/components/Layout.vue';

  @Options({
    components: {
      Layout,
    },
  })
  export default class UsersManagementView extends Vue {}
