import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31da381d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "as-block" }
const _hoisted_2 = {
  key: 1,
  class: "edit-block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!
  const _component_AsButton = _resolveComponent("AsButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.header)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["block-header", { sticky: _ctx.editParams?.isEditing }])
        }, [
          _createElementVNode("div", null, _toDisplayString(_ctx.header), 1),
          (!_ctx.isReadOnly && !_ctx.editParams?.isEditing)
            ? (_openBlock(), _createBlock(_component_fa, {
                key: 0,
                icon: "icon fa-pencil",
                class: "cursor-pointer edit-block-btn",
                onClick: _ctx.processEditEnable
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.editParams?.isEditing)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_AsButton, {
                  type: _ctx.buttonTypes.Primary,
                  disabled: _ctx.isDisabled,
                  label: _ctx.$t('update'),
                  onClick: _ctx.processUpdateSubmit
                }, null, 8, ["type", "disabled", "label", "onClick"]),
                _createVNode(_component_AsButton, {
                  type: _ctx.buttonTypes.Outlined,
                  label: _ctx.$t('cancel'),
                  onClick: _ctx.processEditCancel
                }, null, 8, ["type", "label", "onClick"])
              ]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass({ 'sticky-padding': _ctx.editParams?.isEditing })
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ]))
}