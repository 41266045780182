import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e8600760"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, _toDisplayString(_ctx.label), 1),
    _createVNode(_component_MultiSelect, {
      class: "form-control w-full",
      modelValue: _ctx.value_,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value_) = $event)),
        _ctx.update
      ],
      options: _ctx.options,
      optionLabel: _ctx.optionLabel,
      optionValue: _ctx.optionValue,
      placeholder: _ctx.placeholder,
      showClear: _ctx.clearIcon,
      maxSelectedLabels: 1,
      optionDisabled: 'isDeleted',
      selectedItemsLabel: _ctx.selectedItemsLabel || '{0} items selected'
    }, null, 8, ["modelValue", "options", "optionLabel", "optionValue", "placeholder", "showClear", "selectedItemsLabel", "onUpdate:modelValue"])
  ]))
}