import {
  AntiSkidCaseFileDto,
  AntiSkidLibraryFileDto,
  HistoryClient,
  HistoryItemParentType,
  LibraryClient,
} from '../apiclient/apiclient_generated';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/index';
import { API_URL, WS_URL as ApiUrl } from '@/config';
import axios from 'axios';
import { mapFiles } from '@/services/formDataMapper';
import { PublicConstants } from '@/types/PublicConstants';
import { NotificationService } from '@/services/notification.service';
import { initDateInEntity } from '@/services/dateFunctions';
import { CreateHistoryItemCommand } from '@/types/commands/CreateHistoryItemCommand';
import { CreateLibraryDocumentCommand } from '@/types/commands/CreateLibraryDocumentCommand';

@Module({ dynamic: true, namespaced: true, store, name: 'documents' })
export class DocumentsModule extends VuexModule {
  private client = new HistoryClient(ApiUrl, axios);
  private libraryClient = new LibraryClient(ApiUrl, axios);

  private libraryPostEndpoint = `${API_URL}/library`;

  caseDocuments: AntiSkidCaseFileDto[] = [];
  libraryDocuments: AntiSkidLibraryFileDto[] = [];

  @Action
  async getCaseDocuments(parentId) {
    const docs = await this.client.getDocuments(parentId);

    this.setCaseDocuments(docs);
  }

  @Action
  async getLibraryDocuments() {
    const docs = await this.libraryClient.getLibraryDocuments();

    this.setLibraryDocuments(docs);
  }

  @Action
  async createCaseDocumentAsync(command: CreateHistoryItemCommand) {
    const formData = new FormData();

    formData.append('CaseId', command.caseId.toString());
    formData.append('UseSystemMessage', command.useSystemMessage?.toString());
    formData.append('ParentType', HistoryItemParentType.Document);

    mapFiles(command.files, 'Files', formData);

    const result = await axios.post(`${API_URL}/history/item`, formData, {
      headers: PublicConstants.multiPartHeaders,
    });

    if (result.data.isSuccess) {
      this.addCaseDocuments(result.data.entity.files);
      NotificationService.NotifySuccess('Document was successfully updated');
    } else {
      NotificationService.NotifyErrors(result.data.errors);
    }

    return result.data.isSuccess;
  }

  @Action
  async createLibraryDocumentAsync(command: CreateLibraryDocumentCommand) {
    const formData = new FormData();

    mapFiles(command.files, 'Files', formData);

    const result = await axios.post(this.libraryPostEndpoint, formData, {
      headers: PublicConstants.multiPartHeaders,
    });

    if (result.data.isSuccess) {
      this.addLibraryDocuments(result.data.entity);

      NotificationService.NotifySuccess('Documents were successfully updated');
    } else {
      NotificationService.NotifyErrors(result.data.errors);
    }

    return result.data.isSuccess;
  }

  @Mutation
  setCaseDocuments(docs: AntiSkidCaseFileDto[]) {
    docs.forEach((doc) => {
      initDateInEntity(doc, 'created', 'createdDate');
    });

    this.caseDocuments = docs;
  }

  @Mutation
  setLibraryDocuments(docs: AntiSkidLibraryFileDto[]) {
    docs.forEach((doc) => {
      initDateInEntity(doc, 'created', 'createdDate');
    });

    this.libraryDocuments = docs;
  }

  @Mutation
  addCaseDocuments(docs: AntiSkidCaseFileDto[]) {
    if (!this.caseDocuments) {
      this.caseDocuments = [];
    }

    (docs || []).forEach((doc) => {
      initDateInEntity(doc, 'created', 'createdDate');
      this.caseDocuments.push(doc);
    });
  }

  @Mutation
  addLibraryDocuments(docs: AntiSkidLibraryFileDto[]) {
    if (!this.libraryDocuments) {
      this.libraryDocuments = [];
    }

    (docs || []).forEach((doc) => {
      initDateInEntity(doc, 'created', 'createdDate');
      this.libraryDocuments.push(doc);
    });
  }

  @Mutation
  deleteLibraryDocuments(id) {
    const index = this.libraryDocuments.findIndex((t) => t.id == id);
    this.libraryDocuments.splice(index, 1);
  }
}

export const DocumentsStore = getModule(DocumentsModule);
