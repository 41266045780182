import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b7a4fbc6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-item" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_InputNumber, {
      class: "form-control-wrapper w-full",
      disabled: _ctx.disabled,
      placeholder: _ctx.placeholder,
      mode: "decimal",
      modelValue: _ctx.value_,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value_) = $event)),
      locale: _ctx.locale,
      minFractionDigits: _ctx.minFractionDigits,
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.update($event.value)))
    }, null, 8, ["disabled", "placeholder", "modelValue", "locale", "minFractionDigits"])
  ]))
}