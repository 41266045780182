import {
  ApplicationRoleType,
  CreateUserCommand,
  GetUsersQuery,
  UpdateUserCommand,
  UserDto,
  UserManagementClient,
} from './../apiclient/apiclient_generated';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/index';
import { WS_URL as ApiUrl } from '@/config';
import axios from 'axios';

@Module({ dynamic: true, namespaced: true, store, name: 'userManagement' })
export class UserManagementModule extends VuexModule {
  private client = new UserManagementClient(ApiUrl, axios);

  users: { [key: string]: UserDto } = {};

  get usersArray() {
    return Object.values(this.users);
  }
  get caseManagers() {
    return this.usersArray.filter((t) => t.roles && t.roles.includes(ApplicationRoleType.CaseManager));
  }
  get groupAdmins() {
    return this.usersArray.filter((t) => t.roles && t.roles.includes(ApplicationRoleType.GroupAdmin));
  }
  get admins() {
    return this.usersArray.filter((t) => t.roles && t.roles.includes(ApplicationRoleType.SystemAdministrator));
  }
  get therapists() {
    return this.usersArray.filter((t) => t.roles && t.roles.includes(ApplicationRoleType.Therapist));
  }
  get peers() {
    return this.usersArray.filter((t) => t.roles && t.roles.includes(ApplicationRoleType.Peer));
  }

  @Action
  async getUsers(query: GetUsersQuery) {
    const users = await this.client.getAppUsers(query);

    this.setUsers(users);

    return users;
  }

  @Action
  async getAllUsers() {
    const users = await this.client.getAppUsers({} as GetUsersQuery);

    return Object.values(users);
  }

  @Action
  async createUser(command: CreateUserCommand) {
    return await this.client.createUser(command);
  }

  @Action
  async updateUser(command: UpdateUserCommand) {
    return await this.client.updateUser(command);
  }

  @Action
  async getUser(id) {
    const user = await this.client.getUser(id);

    return user;
  }

  @Mutation
  setUsers(users: { [key: string]: UserDto }) {
    this.users = users;
  }
}

export const UserManagementStore = getModule(UserManagementModule);
