import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33149ba8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "document-wrapper" }
const _hoisted_2 = { class: "list-doc-info grid-group-of-fours" }
const _hoisted_3 = { class: "list-doc-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsTextWithLabel = _resolveComponent("AsTextWithLabel")!
  const _component_ConfirmPopup = _resolveComponent("ConfirmPopup")!
  const _component_vue_pdf_app = _resolveComponent("vue-pdf-app")!
  const _component_AsModal = _resolveComponent("AsModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createVNode(_component_AsTextWithLabel, {
            class: "name grid-group-of-fours-first",
            label: _ctx.$t('name'),
            value: _ctx.document.name
          }, null, 8, ["label", "value"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_AsTextWithLabel, {
            class: "name grid-group-of-fours-second",
            label: _ctx.$t('file.createdDate'),
            value: _ctx.formattedDate
          }, null, 8, ["label", "value"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_AsTextWithLabel, {
            class: "name grid-group-of-fours-third",
            label: _ctx.$t('file.category'),
            value: _ctx.documentCategory
          }, null, 8, ["label", "value"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_AsTextWithLabel, {
            class: "name grid-group-of-fours-fours",
            label: _ctx.$t('file.description'),
            value: _ctx.document.description
          }, null, 8, ["label", "value"])
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("i", {
          class: "pi pi-share-alt cursor-pointer i-navigate-button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.shareFile(_ctx.document.id)))
        }),
        _createElementVNode("i", {
          class: "pi pi-download cursor-pointer i-navigate-button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.downloadFile(_ctx.document.id)))
        }),
        (_ctx.isPdfFile)
          ? (_openBlock(), _createElementBlock("i", {
              key: 0,
              class: "pi pi-eye cursor-pointer i-navigate-button",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.processPreviewDocumentClick()))
            }))
          : _createCommentVNode("", true),
        _createElementVNode("i", {
          class: "pi pi-trash cursor-pointer i-navigate-button",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.deleteFile($event, _ctx.document.id)))
        }),
        _createVNode(_component_ConfirmPopup)
      ])
    ]),
    (_ctx.previewDocument)
      ? (_openBlock(), _createBlock(_component_AsModal, {
          key: 0,
          showCloseButton: true,
          hideFooter: true,
          open: _ctx.previewDocument,
          onClose: _ctx.closePreviewDocument,
          size: 'large'
        }, {
          header: _withCtx(() => [
            _createElementVNode("h3", null, _toDisplayString(_ctx.previewFile.name), 1)
          ]),
          body: _withCtx(() => [
            _createVNode(_component_vue_pdf_app, {
              style: {"height":"72vh"},
              pdf: _ctx.previewFile.arrayBuffer,
              "file-name": _ctx.previewFile.name
            }, null, 8, ["pdf", "file-name"])
          ]),
          _: 1
        }, 8, ["open", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}