import { FileDto } from '@/types/FileDto';
import {
  CaseLaboratoryDataDto,
  CaseLaboratorySettingsDto,
  LaboratoryTestResultContainer,
  LaboratoryTestResultDto,
  SetCaseLaboratorySettingsCommand,
} from './../apiclient/apiclient_generated';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/index';
import { API_URL, WS_URL as ApiUrl } from '@/config';
import axios from 'axios';
import { LaboratoryClient } from '@/apiclient/apiclient_generated';
import { formatDateToISOString, initDateInEntity } from '@/services/dateFunctions';
import { NotificationService } from '@/services/notification.service';
import { mapFile, mapPropertyIfNotNull } from '@/services/formDataMapper';
import { SetCaseLaboratoryTestResultCommand } from '@/types/commands/SetCaseLaboratoryTestResultCommand';
import { PublicConstants } from '@/types/PublicConstants';

@Module({ dynamic: true, namespaced: true, store, name: 'laboratory' })
export class LaboratoryModule extends VuexModule {
  private client = new LaboratoryClient(ApiUrl, axios);

  caseLaboratoryData = { laboratorySettings: {} as CaseLaboratorySettingsDto, testResults: [] } as CaseLaboratoryDataDto;

  currentTestResultItem = {} as LaboratoryTestResultDto;
  currentTestResultItemFiles = [];

  @Action
  async getCaseLaboratoryDataAsync(caseId) {
    const result = await this.client.getCaseLaboratoryData(caseId);

    this.setCaseLaboratoryData(result);
  }

  @Action
  async deleteLabResultAsync({ caseId, testResultId }) {
    const result = await this.client.deleteCaseLaboratoryTestResult(caseId, testResultId);

    const updates = {
      nextLabTestInfo: result,
      testResultId: testResultId,
    };
    this.removeCaseLaboratoryTestResult(updates);
  }

  @Action
  async setCaseLaboratorySettingsAsync(command: SetCaseLaboratorySettingsCommand) {
    const result = await this.client.setCaseLaboratorySettings(command);

    this.setCaseLaboratoryData(result);
  }

  @Action
  async setCaseLaboratoryTestResultAsync(command: SetCaseLaboratoryTestResultCommand) {
    const formData = new FormData();

    mapPropertyIfNotNull('Id', command.Id, formData);
    mapPropertyIfNotNull('CaseId', command.CaseId, formData);

    mapPropertyIfNotNull('TestDate', formatDateToISOString(command.TestDate), formData);
    mapPropertyIfNotNull('DoctorId', command.DoctorId, formData);
    mapPropertyIfNotNull('TestResultTypeId', command.TestResultTypeId, formData);
    mapPropertyIfNotNull('Remarks', command.Remarks, formData);

    mapPropertyIfNotNull('GGT', command.GGT, formData);
    mapPropertyIfNotNull('GOT', command.GOT, formData);
    mapPropertyIfNotNull('GPT', command.GPT, formData);

    if (command.File) {
      mapFile(command.File, 'File', formData);
    }

    const result: any = await axios.post(`${API_URL}/laboratory/lab-test-result`, formData, {
      headers: PublicConstants.multiPartHeaders,
    });

    if (result.data.isSuccess) {
      this.setCaseLaboratoryTestResult(result.data.entity);
    } else {
      NotificationService.NotifyErrors(result.data.errors);
    }

    return result.data.isSuccess;
  }

  @Mutation
  setCaseLaboratoryData(data: CaseLaboratoryDataDto) {
    initDateInEntity(data.laboratorySettings, 'firstTestDate', 'firstTestDateTime');

    if (data.testResults) {
      data.testResults.forEach((result) => {
        initDateInEntity(result, 'testDate', 'testDateTime');
      });
    }

    initDateInEntity(data.laboratorySettings, 'firstTestDate', 'firstTestDateTime');

    this.caseLaboratoryData = data;
  }

  @Mutation
  resetCaseLaboratoryData() {
    this.caseLaboratoryData = { laboratorySettings: {} as CaseLaboratorySettingsDto, testResults: [] } as CaseLaboratoryDataDto;
  }

  @Mutation
  setCaseLaboratoryTestResult(data: LaboratoryTestResultContainer) {
    const testResult = data.result;

    initDateInEntity(testResult, 'testDate', 'testDateTime');

    const index = this.caseLaboratoryData.testResults.findIndex((t) => t.id === testResult.id);

    if (index >= 0) {
      this.caseLaboratoryData.testResults[index] = testResult;
    } else {
      this.caseLaboratoryData.testResults.push(testResult);
    }

    this.caseLaboratoryData.nextLabTestInfo = data.nextLabTestInfo;
  }

  @Mutation
  async setCaseLaboratoryTestResultItem(item: LaboratoryTestResultDto) {
    const index = this.caseLaboratoryData.testResults.findIndex((t) => t.id == item.id);
    this.caseLaboratoryData.testResults[index] = { ...item } as LaboratoryTestResultDto;
  }

  @Mutation
  async removeCaseLaboratoryTestResult({ nextLabTestInfo, testResultId }) {
    const index = this.caseLaboratoryData.testResults.findIndex((t) => t.id == testResultId);

    if (index >= 0) {
      this.caseLaboratoryData.testResults.splice(index, 1);
    }

    this.caseLaboratoryData.nextLabTestInfo = nextLabTestInfo;
  }

  @Mutation
  setLaboratorySettings(settings: CaseLaboratorySettingsDto) {
    initDateInEntity(settings, 'firstTestDate', 'firstTestDateTime');

    this.caseLaboratoryData.laboratorySettings = settings;
  }

  @Mutation
  setCurrentTestResultItem(item: LaboratoryTestResultDto) {
    this.currentTestResultItem = item;

    this.currentTestResultItemFiles = item.testResultFile
      ? [
          {
            Id: item.testResultFile.id,
            Name: item.testResultFile.name,
            CategoryId: item.testResultFile.categoryId,
            Description: item.testResultFile.description,
          } as FileDto,
        ]
      : [];
  }

  @Mutation
  setSelectedItemFiles(files) {
    this.currentTestResultItemFiles = files;
  }
}

export const LaboratoryStore = getModule(LaboratoryModule);
