import { FileDto } from '@/types/FileDto';
import { PublicConstants } from '@/types/PublicConstants';

export default class FileService {
  static downloadFile(blob: Blob, fileName) {
    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    URL.revokeObjectURL(link.href);
  }

  static isFilesFormValid(files: FileDto[]) {
    return files && files.length ? files.every((file: FileDto) => this.isFileValid(file)) : true;
  }

  static isFileValid(file: FileDto) {
    return file != null && (file.CategoryId === PublicConstants.OtherFileCategoryId ? file.Description : file.CategoryId);
  }
}
