import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c9082ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "as-container" }
const _hoisted_2 = { class: "body-wrapper" }
const _hoisted_3 = { class: "body-content as-scroll" }
const _hoisted_4 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_NavigationMenu = _resolveComponent("NavigationMenu")!
  const _component_AsSpinner = _resolveComponent("AsSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header, { class: "header" }),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isSigned)
        ? (_openBlock(), _createBlock(_component_NavigationMenu, {
            key: 0,
            class: "side-menu"
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "content", {}, undefined, true),
          _createVNode(_component_AsSpinner)
        ])
      ])
    ])
  ]))
}