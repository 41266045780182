
  import { Options, Vue } from 'vue-class-component';
  import User from './User.vue';
  import AsBlock from '@/components/shared/as-block-component.vue';
  import AsButton from '@/components/shared/as-button.vue';
  import { ButtonTypes } from '@/types/ButtonTypes';
  import Routes from '@/types/Routes';
  import { UserManagementModule, UserManagementStore } from '@/store/userManagement';
  import { UserDto } from '@/apiclient/apiclient_generated';
  import UserListItem from './UserListItem.vue';

  @Options({
    components: {
      User,
      AsBlock,
      AsButton,
      UserListItem,
    },
  })
  export default class UsersComponent extends Vue {
    private usersStore: UserManagementModule = UserManagementStore;

    buttonTypes = ButtonTypes;
    usersSearch = '';

    createNewUser() {
      this.$router.push({ name: Routes.user.name, params: { id: '' } });
    }

    allUsers = [];
    async mounted() {
      this.allUsers = await this.usersStore.getAllUsers();
    }

    get users() {
      const usersList: UserDto[] = this.usersSearch
        ? this.allUsers.filter(
            (t: UserDto) =>
              this.includes(t.firstName, this.usersSearch) ||
              this.includes(t.lastName, this.usersSearch) ||
              this.includes(t.email, this.usersSearch)
          )
        : this.allUsers;

      return usersList.sort((a, b) => a.lastName?.localeCompare(b.lastName));
    }

    private includes(source: string, pattern: string) {
      return source?.toLowerCase().includes(pattern?.toLowerCase());
    }
  }
