import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/index';
import { WS_URL as ApiUrl } from '@/config';
import axios from 'axios';
import {
  AuthenticationResult,
  IAuthenticationResult,
  IdentityClient,
  LoginUserCommand,
  LogoutUserCommand,
  RefreshUserCommand,
  UpdatePasswordCommand,
} from '@/apiclient/apiclient_generated';
import { NotificationService } from '@/services/notification.service';
import router from '@/router';
import Routes from '@/types/Routes';
import { UserManagementModule } from './userManagement';
import jwt_decode from 'jwt-decode';

@Module({ dynamic: true, namespaced: true, store, name: 'userStore' })
export class UserModule extends VuexModule {
  private client = new IdentityClient(ApiUrl, axios);
  private _token = '_token';
  private _refreshToken = '_refreshToken';

  authData: IAuthenticationResult = new AuthenticationResult();

  get isSigned() {
    return this.authData.token;
  }

  get userData() {
    if (!this.authData.token) {
      return {};
    }
    const tokenData: any = jwt_decode(this.authData.token as string);
    const roles = JSON.parse(tokenData.Roles);

    return {
      id: tokenData.Id,
      fullName: tokenData.UserFullName,
      email: tokenData.email,
      roles: roles,
    };
  }

  get currentUserId() {
    return this.userData.id;
  }

  get isCurrentUserAdmin() {
    const usersStore = getModule(UserManagementModule);
    return usersStore.admins.some((t) => t.id == this.userData.id);
  }

  @Action
  async login(loginCommand: LoginUserCommand) {
    return await this.client
      .login(loginCommand)
      .then((data) => {
        // todo: temporary code
        if (data.errorMessages?.length) {
          data.errorMessages.forEach((err) => {
            NotificationService.NotifyError(err);
          });
        }

        this.setAuthData(data);
        return data.isSuccess;
      })
      .catch(() => {
        return false;
      });
  }

  @Action
  async updatePasswordAsync(command: UpdatePasswordCommand) {
    return await this.client
      .updatePassword(command)
      .then((data) => {
        if (!data.isSuccess) {
          data.errors.forEach((err) => {
            NotificationService.NotifyError(err);
          });
        } else {
          if (data.entity) {
            this.setAuthData(data.entity);
          }
          return data.isSuccess;
        }
      })
      .catch(() => {
        return false;
      });
  }

  @Action
  async logout() {
    await this.client.logout({ refreshToken: this.authData?.refreshToken } as LogoutUserCommand);
    this.processLogout();
  }

  @Action
  async loginWithReturnUrl() {
    const currentRoute = router.currentRoute;
    const returnRouteName = currentRoute.value.fullPath;

    localStorage.setItem(Routes.ReturnUrl, returnRouteName);

    window.location.href = '/';
  }

  @Action
  async refresh() {
    const command = new RefreshUserCommand();

    command.refreshToken = this.authData.refreshToken;
    command.token = this.authData.token;

    this.setAuthData(this.authData);
    const refreshResult = await this.client.refresh(command);

    if (!refreshResult.isSuccess && refreshResult.errorMessages && refreshResult.errorMessages[0] === 'Invalid token') {
      this.logout();
      this.loginWithReturnUrl();
    } else {
      this.setAuthData(refreshResult);
    }
  }

  @Mutation
  processLogout() {
    localStorage.removeItem(this._token);
    localStorage.removeItem(this._refreshToken);
    this.authData = {};
  }

  @Mutation
  setAuthData(result: IAuthenticationResult) {
    if (!result.token || !result.refreshToken) {
      localStorage.removeItem(this._token);
      localStorage.removeItem(this._refreshToken);
    } else {
      localStorage.setItem(this._token, result.token as string);
      localStorage.setItem(this._refreshToken, result.refreshToken as string);

      this.authData = result;
    }
  }

  @Mutation
  initAuthDataFromLocalStorage() {
    const token = localStorage.getItem(this._token) as string;

    if (token) {
      this.authData.token = token;
    }

    const refreshToken = localStorage.getItem(this._refreshToken) as string;
    if (refreshToken) {
      this.authData.refreshToken = refreshToken;
    }
  }
}

export const UserStore = getModule(UserModule);
