
  import { CaseDto, UserDto, ApplicationRoleType, LookUpValueType } from '@/apiclient/apiclient_generated';
  import Routes from '@/types/Routes';
  import { Options, Vue } from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import AsUserWithRole from '../shared/as-userWithRole.vue';
  import { UserManagementModule, UserManagementStore } from '@/store/userManagement';
  import AsBlock from '../shared/as-block-component.vue';
  import AsTextWithLabel from '../shared/as-textWithLabel.vue';
  import { formatStringDate } from '@/services/dateFunctions';
  import { GroupsModule, GroupsStore } from '@/store/groups';
  import { PublicConstants } from '@/types/PublicConstants';
  import { LookUpValuesModule, LookUpValuesStore } from '@/store/lookUpValues';

  @Options({
    components: {
      AsUserWithRole,
      AsBlock,
      AsTextWithLabel,
    },
  })
  export default class Case extends Vue {
    private usersStore: UserManagementModule = UserManagementStore;
    private groupsStore: GroupsModule = GroupsStore;
    private lookUpStore: LookUpValuesModule = LookUpValuesStore;

    lvTypes = LookUpValueType;

    @Prop()
    modelValue: CaseDto;

    formatStringDate = formatStringDate;

    manager = {};
    therapist = {};

    applicationRoleType = ApplicationRoleType;

    get model() {
      return this.modelValue ?? ({} as CaseDto);
    }

    navigateToCaseDetails() {
      this.$router.push({ name: Routes.case.name, params: { id: this.model.caseData.id } });
    }

    getUserInfo(userId): UserDto {
      return this.usersStore.users[userId];
    }

    get isLabBadgeAvailable() {
      const nextTestingInfo = this.model.nextTestingInfo;

      return (
        nextTestingInfo &&
        (nextTestingInfo.isNextTestOverdue || (!nextTestingInfo.isNextTestOverdue && nextTestingInfo.nextLaboratoryTestDate))
      );
    }

    mounted(): void {
      const model_ = this.modelValue;

      this.manager = this.usersStore.users[model_.caseResponsibility.managerId];
      this.therapist = this.usersStore.users[model_.caseClinicalInformation?.therapistId];
    }

    getGroupNameOrDefault(groupId) {
      const group = this.groupsStore.groupsLightInfo.find((t) => t.id === groupId);

      return `GT ${group?.name || PublicConstants.emptyValue}`;
    }

    getLookUpValue(group: LookUpValueType, id) {
      return this.lookUpStore.getLookUpValueName({ group, id });
    }
  }
