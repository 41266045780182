import { PublicConstants } from '@/types/PublicConstants';

export function isFormReadOnly(isEditMode: boolean, isEditing: boolean) {
  if (!isEditMode) {
    return false;
  }

  if (isEditMode && !isEditing) {
    return true;
  }

  return false;
}

export function getFormValueOrDefault(source, defaultResponse = PublicConstants.emptyValue) {
  return source ? source : defaultResponse;
}

export function getListValuesOrDefault(source: string[]) {
  return source ? source.join('; ') : PublicConstants.emptyValue;
}

export function isEmailValid(email) {
  return email
    ? !!email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    : true;
}
