
  import { ApplicationRoleType, GetUsersQuery } from '@/apiclient/apiclient_generated';
  import { SideMenuModule, SideMenuStore } from '@/store/sideMenu';
  import { UserModule, UserStore } from '@/store/user';
  import { UserManagementModule, UserManagementStore } from '@/store/userManagement';
  import { SideMenuSize } from '@/types/SideMenuSize';
  import { Options, Vue } from 'vue-class-component';
  import Header from './Header.vue';
  import NavigationMenu from './SideMenu.vue';
  import { LookUpValuesModule, LookUpValuesStore } from '@/store/lookUpValues';
  import AsSpinner from './shared/as-spinner.vue';
  import { LayoutModule, LayoutStore } from '@/store/layout';

  @Options({
    components: {
      Header,
      NavigationMenu,
      AsSpinner,
    },
  })
  export default class Layout extends Vue {
    private sideMenuStore: SideMenuModule = SideMenuStore;
    private userStore: UserModule = UserStore;
    private usersStore: UserManagementModule = UserManagementStore;
    private lookUpStore: LookUpValuesModule = LookUpValuesStore;
    private layoutStore: LayoutModule = LayoutStore;

    navMenuConfigs: { [key: string]: string } = {};

    async mounted() {
      this.navMenuConfigs[SideMenuSize.Small] = this.smallMenuClass;
      this.navMenuConfigs[SideMenuSize.Regular] = this.regularMenuClass;

      this.userStore.initAuthDataFromLocalStorage();

      if (this.isSigned) {
        // if (!this.usersStore.admins || !this.usersStore.admins.length) {
        //   await this.usersStore.getUsers({
        //     roles: [ApplicationRoleType.SystemAdministrator],
        //   } as GetUsersQuery);
        // }

        await this.usersStore.getUsers({ roles: [] } as GetUsersQuery);

        await this.lookUpStore.getLookUpValues();

        this.sideMenuStore.initMenu(this.$route.name);
      }
    }

    get isSigned() {
      return this.userStore.isSigned;
    }

    get sideMenuSize() {
      return this.sideMenuStore.sideMenuSize;
    }

    getPageLayout() {
      return this.navMenuConfigs[this.sideMenuSize];
    }

    get isSpinnerVisible() {
      return this.layoutStore.showSpinner;
    }

    get smallMenuClass() {
      return 'navMenu-small';
    }

    get regularMenuClass() {
      return 'navMenu-regular';
    }
  }
