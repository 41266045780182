
  import Cases from '@/components/cases/Cases.vue';
  import Layout from '@/components/Layout.vue';
  import { Options, Vue } from 'vue-class-component';

  @Options({
    components: {
      Layout,
      Cases,
    },
  })
  export default class CasesView extends Vue {}
