
  import AsBlock from '@/components/shared/as-block-component.vue';
  import AsButton from '@/components/shared/as-button.vue';
  import { Options, Vue } from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { AntiSkidCaseFileDto, HistoryItemDto, LookUpValueDto, LookUpValueType } from '@/apiclient/apiclient_generated';
  import AsFilesSelector from '@/components/shared/as-files-selector.vue';
  import { formatDateWithoutTime } from '@/services/dateFunctions';
  import AsTextWithLabel from '@/components/shared/as-textWithLabel.vue';
  import { FilesModule, FilesStore } from '@/store/files';
  import FileService from '@/services/file.service';
  import AsModal from '@/components/shared/as-modal.vue';
  import { CaseHistoryModule, CaseHistoryStore } from '@/store/caseHistory';
  import VuePdfApp from 'vue3-pdf-app';
  import { LookUpValuesModule, LookUpValuesStore } from '@/store/lookUpValues';
  import { NotificationService } from '@/services/notification.service';
  import { DocumentsModule, DocumentsStore } from '@/store/documents';

  @Options({
    components: {
      AsBlock,
      AsButton,
      AsFilesSelector,
      AsTextWithLabel,
      AsModal,
      VuePdfApp,
    },
  })
  export default class LibraryDocument extends Vue {
    @Prop()
    document: AntiSkidCaseFileDto;

    private docsStore: DocumentsModule = DocumentsStore;
    private filesStore: FilesModule = FilesStore;
    private historyStore: CaseHistoryModule = CaseHistoryStore;
    private lookUpStore: LookUpValuesModule = LookUpValuesStore;

    previewDocument = false;
    previewFile = { name: '', arrayBuffer: null };

    get formattedDate() {
      return formatDateWithoutTime(this.document['createdDate']);
    }

    get documentCategory() {
      const result: LookUpValueDto = this.lookUpStore.getLookUpValue({ group: LookUpValueType.LibraryFile, id: this.document.categoryId });

      return result?.value;
    }

    showHistoryItem = false;
    async showHistoryItemModal() {
      await this.historyStore.getHistoryItemAsync(this.document.additionalParentId);
      this.showHistoryItem = true;
    }
    closeHistoryItemModal() {
      this.historyStore.setSelectedHistoryItem({} as HistoryItemDto);
      this.showHistoryItem = false;
    }
    closePreviewDocument() {
      this.resetPreviewFile();
      this.previewDocument = false;
    }

    resetPreviewFile() {
      this.previewFile = {
        name: '',
        arrayBuffer: null,
      };
    }

    get isPdfFile() {
      return this.document.name.toLocaleLowerCase().includes('.pdf');
    }

    get selectedHistoryItem() {
      return this.historyStore.selectedHistoryItem;
    }

    async shareFile(fileId) {
      await navigator.clipboard.writeText(`${window.location.origin}${this.$route.path}?id=${fileId}`);

      NotificationService.NotifySuccess(this.$t('copied'));
    }

    async downloadFile(fileId) {
      const file: any = await this.filesStore.downloadLibraryFile(fileId);

      if (file) {
        FileService.downloadFile(file.data, file.fileName);
      }
    }

    async deleteFile(event, id) {
      this.$confirm.require({
        target: event.currentTarget,
        message: 'Are you sure you want to delete selected file?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const result = await this.filesStore.deleteLibraryFile(id);

          if (result.isSuccess) {
            this.docsStore.deleteLibraryDocuments(id);
            NotificationService.NotifySuccess(this.$t('notifications.fileWasRemoved'));
          }
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    }
    async processPreviewDocumentClick() {
      this.resetPreviewFile();

      const file: any = await this.filesStore.downloadLibraryFile(this.document.id);

      this.previewFile.name = file.fileName;

      const fileLoadHandler = (arrayBuffer) => {
        this.previewFile.arrayBuffer = arrayBuffer;
        this.previewDocument = !this.previewDocument;
      };

      var fileReader = new FileReader();
      fileReader.onload = function (event) {
        fileLoadHandler(event.target.result);
      };

      fileReader.readAsArrayBuffer(file.data);
    }
  }
