import { Action, getModule, Module, VuexModule } from 'vuex-module-decorators';
import store from '@/store/index';
import { WS_URL as ApiUrl } from '@/config';
import axios from 'axios';
import { FilesClient } from '@/apiclient/apiclient_generated';

@Module({ dynamic: true, namespaced: true, store, name: 'files' })
export class FilesModule extends VuexModule {
  private client = new FilesClient(ApiUrl, axios);

  @Action
  async downloadCaseFile(fileId) {
    return await this.client.downloadCaseFile(fileId);
  }

  @Action
  async downloadLibraryFile(fileId) {
    return this.client
      .downloadLibraryFile(fileId)
      .then((t) => {
        return t;
      })
      .catch((ex) => {
        switch (ex.status) {
          case 404:
            return { status: 404 };

          default:
            throw ex;
        }
      });
  }

  @Action
  async deleteCaseFile(fileId) {
    return await this.client.deleteCaseFile(fileId);
  }

  @Action
  async deleteLibraryFile(fileId) {
    return await this.client.deleteLibraryFile(fileId);
  }
}

export const FilesStore = getModule(FilesModule);
