
  import { LookUpValueType } from '@/apiclient/apiclient_generated';
  import AsBlock from '@/components/shared/as-block-component.vue';
  import AsButton from '@/components/shared/as-button.vue';
  import AsDatepicker from '@/components/shared/as-datepicker.vue';
  import AsDropdown from '@/components/shared/as-dropdown.vue';
  import AsEditBlock from '@/components/shared/as-edit-block.vue';
  import AsFilesSelector from '@/components/shared/as-files-selector.vue';
  import AsInput from '@/components/shared/as-input.vue';
  import AsLabTestResultDropdown from '@/components/shared/as-lab-test-result-dropdown.vue';
  import AsModal from '@/components/shared/as-modal.vue';
  import AsMultiSelect from '@/components/shared/as-multi-select.vue';
  import AsNumber from '@/components/shared/as-number.vue';
  import AsReadWriteBlock from '@/components/shared/as-read-write.vue';
  import FileService from '@/services/file.service';
  import { LaboratoryModule, LaboratoryStore } from '@/store/laboratory';
  import { LookUpValuesModule, LookUpValuesStore } from '@/store/lookUpValues';
  import { ButtonTypes } from '@/types/ButtonTypes';
  import { SetCaseLaboratoryTestResultCommand } from '@/types/commands/SetCaseLaboratoryTestResultCommand';
  import { Options, Vue } from 'vue-class-component';
  import { Emit, Prop } from 'vue-property-decorator';

  @Options({
    components: {
      AsBlock,
      AsButton,
      AsEditBlock,
      AsReadWriteBlock,
      AsMultiSelect,
      AsDropdown,
      AsDatepicker,
      AsModal,
      AsFilesSelector,
      AsInput,
      AsNumber,
      AsLabTestResultDropdown,
    },
  })
  export default class CaseLaboratoryModal extends Vue {
    private labsStore: LaboratoryModule = LaboratoryStore;
    private lookUpStore: LookUpValuesModule = LookUpValuesStore;

    @Prop()
    caseLaboratorySettingsId;

    @Prop()
    openLabTestResultDetails;

    testTypes = [
      {
        id: 1,
        label: 'Alcohol',
      },
      {
        id: 2,
        label: 'Drugs',
      },
      {
        id: 3,
        label: 'AlcoholAndDrugs',
      },
    ];

    isLoading = false;
    bTypes = ButtonTypes;
    lvTypes = LookUpValueType;

    getLookUpOptions(groupType: LookUpValueType) {
      return this.lookUpStore.lookUpOptions(groupType);
    }

    getLookUpValue(group: LookUpValueType, id) {
      return this.lookUpStore.getLookUpValueName({ group, id });
    }

    get caseId(): any {
      return this.$route.params.id;
    }

    get isFormValid() {
      return (
        !this.isLoading &&
        this.labsStore.currentTestResultItem.testDate &&
        this.labsStore.currentTestResultItemFiles.length &&
        FileService.isFilesFormValid(this.labsStore.currentTestResultItemFiles)
      );
    }

    get currentTestResult() {
      return this.labsStore.currentTestResultItem;
    }
    get currentTestFiles() {
      return this.labsStore.currentTestResultItemFiles;
    }

    async processLabTestDetailsSubmit() {
      this.isLoading = true;

      const command = {
        Id: this.currentTestResult.id,
        CaseId: this.caseId,
        CaseLaboratorySettingsId: this.caseLaboratorySettingsId,

        TestDate: this.currentTestResult.testDate,
        DoctorId: this.currentTestResult.doctorId,
        TestResultTypeId: this.currentTestResult.testResultType?.id,

        //todo: code smell
        GGT: this.replaceDotByComma(this.currentTestResult.ggt),
        GOT: this.replaceDotByComma(this.currentTestResult.got),
        GPT: this.replaceDotByComma(this.currentTestResult.gpt),

        Remarks: this.currentTestResult.remarks,

        File: this.labsStore.currentTestResultItemFiles.length ? this.labsStore.currentTestResultItemFiles[0] : null,
      } as SetCaseLaboratoryTestResultCommand;

      const isSuccess = await this.labsStore.setCaseLaboratoryTestResultAsync(command);

      this.isLoading = false;

      if (isSuccess) {
        this.closeModal();
      }
    }

    private replaceDotByComma(source) {
      if (!source) {
        return source;
      }

      return `${source}`.replace('.', ',');
    }

    updateSelectedItemFiles(files) {
      this.labsStore.setSelectedItemFiles(files);
    }

    @Emit()
    closeModal() {
      return true;
    }
  }
