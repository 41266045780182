import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c0c206f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "case-users" }
const _hoisted_2 = { class: "case-users-wrapper" }
const _hoisted_3 = { class: "fs-18" }
const _hoisted_4 = { class: "grid-group-of-two-first" }
const _hoisted_5 = { class: "user-name" }
const _hoisted_6 = { class: "role-details" }
const _hoisted_7 = { class: "user grid-group-of-two-second" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "grid-group-of-two-first" }
const _hoisted_10 = { class: "user grid-group-of-two-second" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "new-user-with-rights mt-2" }
const _hoisted_13 = { class: "mt-4 case-users-wrapper" }
const _hoisted_14 = { class: "pb-2 fs-18" }
const _hoisted_15 = { class: "user-name admin-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsButton = _resolveComponent("AsButton")!
  const _component_AsBlock = _resolveComponent("AsBlock")!
  const _component_fa = _resolveComponent("fa")!
  const _component_AsDropdown = _resolveComponent("AsDropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AsBlock, {
      class: "row-wrapper-space-between",
      header: _ctx.$t('case.rights.title')
    }, {
      default: _withCtx(() => [
        (_ctx.canWrite(_ctx.caseScope.UserRights))
          ? (_openBlock(), _createBlock(_component_AsButton, {
              key: 0,
              type: _ctx.buttonTypes.Primary,
              label: _ctx.$t('save'),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.saveRights()))
            }, null, 8, ["type", "label"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["header"]),
    _createVNode(_component_AsBlock, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('case.rights.header')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredCaseUsers, (user, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "grid-group-of-two gray-container",
              key: index
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_fa, { icon: "icon fa-regular fa-user" }),
                  _createElementVNode("div", null, _toDisplayString(_ctx.getUserFullName(user)), 1)
                ]),
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.getRoleDetails(user)), 1)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_AsDropdown, {
                  label: _ctx.$t('case.rights.subHeader'),
                  clearIcon: true,
                  value: user.caseRole,
                  options: _ctx.caseRolesOptions,
                  optionValue: 'key',
                  optionLabel: 'label',
                  placeholder: _ctx.$t('case.rights.selectRightsPlaceholder'),
                  disabled: !_ctx.canWrite(_ctx.caseScope.UserRights),
                  onUpdate: (value) => (user.caseRole = value)
                }, {
                  default: _withCtx(() => [
                    (_ctx.canWrite(_ctx.caseScope.UserRights))
                      ? (_openBlock(), _createElementBlock("i", {
                          key: 0,
                          class: "pi pi-trash",
                          onClick: ($event: any) => (_ctx.removeCaseUser(user))
                        }, null, 8, _hoisted_8))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["label", "value", "options", "placeholder", "disabled", "onUpdate"])
              ])
            ]))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.manuallyAddedCaseUsers, (mUser, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "grid-group-of-two gray-container",
              key: index
            }, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_AsDropdown, {
                  label: _ctx.$t('name'),
                  clearIcon: true,
                  value: mUser.userId,
                  options: _ctx.usersArray,
                  optionValue: 'id',
                  optionLabel: 'fullName',
                  placeholder: _ctx.$t('case.rights.selectUserPlaceholder'),
                  onUpdate: (value) => _ctx.processManualUserSelect(mUser, value)
                }, null, 8, ["label", "value", "options", "placeholder", "onUpdate"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_AsDropdown, {
                  label: _ctx.$t('case.rights.subHeader'),
                  clearIcon: true,
                  value: mUser.caseRole,
                  options: _ctx.caseRolesOptions,
                  optionValue: 'key',
                  optionLabel: 'label',
                  placeholder: _ctx.$t('case.rights.selectRightsPlaceholder'),
                  onUpdate: (value) => (mUser.caseRole = value)
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("i", {
                      class: "pi pi-trash",
                      onClick: ($event: any) => (_ctx.removeDraftCaseUser(index))
                    }, null, 8, _hoisted_11)
                  ]),
                  _: 2
                }, 1032, ["label", "value", "options", "placeholder", "onUpdate"])
              ])
            ]))
          }), 128)),
          _createElementVNode("div", _hoisted_12, [
            (_ctx.canWrite(_ctx.caseScope.UserRights))
              ? (_openBlock(), _createBlock(_component_AsButton, {
                  key: 0,
                  type: _ctx.buttonTypes.Success,
                  label: _ctx.$t('AddNew'),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addNewCaseUser()))
                }, null, 8, ["type", "label"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('case.rights.autoRights')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users.defaultUsers, (admin, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "gray-container",
              key: index
            }, [
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_fa, { icon: "icon fa-regular fa-user" }),
                _createElementVNode("div", null, _toDisplayString(admin.fullName), 1)
              ])
            ]))
          }), 128))
        ])
      ]),
      _: 1
    })
  ]))
}