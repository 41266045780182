
  import { ButtonTypes } from '@/types/ButtonTypes';
  import EditBlockParams from '@/types/EditBlockParams';
  import { Options, Vue, prop } from 'vue-class-component';
  import { Emit, Prop } from 'vue-property-decorator';
  import AsButton from './as-button.vue';

  @Options({
    components: {
      AsButton,
    },
  })
  export default class AsEditBlock extends Vue {
    @Prop()
    header: string;
    @Prop()
    isReadOnly!: boolean;
    @Prop()
    editParams!: EditBlockParams;

    @Prop()
    isDisabled!: boolean;

    buttonTypes = ButtonTypes;

    processEditEnable() {
      this.setEditingState(true);

      this.editModeEnabled();
    }

    processEditCancel() {
      this.setEditingState(false);

      this.editModeCanceled();
    }

    processUpdateSubmit() {
      this.editModeSubmit();
    }

    @Emit()
    editModeEnabled() {
      return this.editParams.isEditing;
    }

    @Emit()
    editModeSubmit() {
      return this.editParams.isEditing;
    }

    @Emit()
    editModeCanceled() {
      return this.editParams.isEditing;
    }

    private setEditingState(state) {
      this.editParams.isEditing = state;
    }
  }
