
  import { Options, Vue } from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';

  @Options({
    components: {},
  })
  export default class AsSwitch extends Vue {
    @Prop() label;
    @Prop() value;
    @Prop() customClass;
    @Prop() disabled;

    update(value) {
      this.$emit('update', value);
    }

    get value_() {
      return this.value;
    }
  }
