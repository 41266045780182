
  import { Options, Vue } from 'vue-class-component';
  import Layout from '@/components/Layout.vue';
  import LookUpValues from '@/components/admin/LookUpValues.vue';

  @Options({
    components: {
      Layout,
      LookUpValues,
    },
  })
  export default class LookUpValuesView extends Vue {}
