
  import { Options, Vue } from 'vue-class-component';
  import AsBlock from '../shared/as-block-component.vue';
  import AsButton from '../shared/as-button.vue';
  import { ButtonTypes } from '@/types/ButtonTypes';
  import AsInput from '../shared/as-input.vue';
  import { CreateLookUpValueCommand, LookUpValueDto, LookUpValueType } from '@/apiclient/apiclient_generated';
  import AsDropdown from '../shared/as-dropdown.vue';
  import { LookUpValuesModule, LookUpValuesStore } from '@/store/lookUpValues';
  import LookUpValue from './LookUpValue.vue';

  @Options({
    components: {
      AsBlock,
      AsButton,
      AsInput,
      AsDropdown,
      LookUpValue,
    },
  })
  export default class NewLookUpValue extends Vue {
    private lookUpStore: LookUpValuesModule = LookUpValuesStore;

    isLoading = false;
    showNewForm = false;
    bTypes = ButtonTypes;
    newLookUpValue: CreateLookUpValueCommand = {} as CreateLookUpValueCommand;

    initNewForm() {
      this.showNewForm = true;
      this.newLookUpValue = {} as LookUpValueDto;
    }

    async createLookUpValue() {
      this.isLoading = true;

      const command = {
        type: this.newLookUpValue.type,
        name: this.newLookUpValue.name,
        value: this.newLookUpValue.value,
        sortOrder: 0,
      } as CreateLookUpValueCommand;

      const result = await this.lookUpStore.createLookUpValue(command);

      this.isLoading = false;

      if (result.isSuccess) {
        this.cancelNewForm();
      }
    }

    cancelNewForm() {
      this.showNewForm = false;
      this.newLookUpValue = {} as CreateLookUpValueCommand;
    }

    get isModelValid() {
      return !this.isLoading && this.newLookUpValue.type && this.newLookUpValue.name && this.newLookUpValue.value;
    }
  }
