
  import InputText from 'primevue/inputtext';
  import { Options, Vue } from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';

  @Options({
    components: {
      InputText,
    },
  })
  export default class AsLabTestResultDropdown extends Vue {
    @Prop() label;
    @Prop() value;
    @Prop() options;
    @Prop() optionLabel;
    @Prop() optionValue;
    @Prop() placeholder;
    @Prop() clearIcon;
    @Prop() disabled;

    get value_() {
      return this.value;
    }

    update($event) {
      return this.$emit('update', $event);
    }

    optionsBackup = [];
    filteredOptions = null;

    filterOptions() {
      if (!this.options || !this.options.length) {
        return;
      }

      this.optionsBackup = [...this.options];

      this.filteredOptions = (this.options || []).filter((t) => !t.isDeleted);
    }

    resetOptions() {
      this.filteredOptions = null;
    }

    get options_() {
      return this.filteredOptions || this.options;
    }
  }
