
  import { Options, Vue } from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';

  @Options({
    components: {},
  })
  export default class AsBlock extends Vue {
    @Prop()
    header: string;
    @Prop() hoverEffect: boolean;
  }
