
  import { ApplicationRoleType, UserDto } from '@/apiclient/apiclient_generated';
  import { Options, Vue } from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import AsTextWithLabel from './as-textWithLabel.vue';
  import { PublicConstants } from '@/types/PublicConstants';

  @Options({
    components: {
      AsTextWithLabel,
    },
  })
  export default class AsUserWithRole extends Vue {
    @Prop() role: ApplicationRoleType;
    @Prop() modelValue: UserDto;

    get valueOrDefault() {
      return this.modelValue?.fullName || PublicConstants.emptyValue;
    }
  }
