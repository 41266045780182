import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsTextWithLabel = _resolveComponent("AsTextWithLabel")!

  return (_openBlock(), _createBlock(_component_AsTextWithLabel, {
    class: "role-container",
    label: _ctx.$t(_ctx.role.split(/(?=[A-Z])/).join(' ')),
    value: _ctx.valueOrDefault
  }, null, 8, ["label", "value"]))
}