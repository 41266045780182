export class PublicConstants {
  static emptyValue = '---';
  static refreshTabContent = 'refresh-tab-content';

  static OtherFileCategoryId = '00000000-0000-0000-0000-000000000001';

  static multiPartHeaders = {
    'Content-Type': 'multipart/form-data',
  };
}
