import { UpdateHistoryItemCommand } from '../types/commands/UpdateHistoryItemCommand';
import { NotificationService } from './../services/notification.service';
import { HistoryClient, CaseHistoryDto, HistoryItemDto, HistoryItemParentType } from './../apiclient/apiclient_generated';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/index';
import { API_URL, WS_URL as ApiUrl } from '@/config';
import axios from 'axios';
import { CreateHistoryItemCommand } from '@/types/commands/CreateHistoryItemCommand';
import { mapFiles } from '@/services/formDataMapper';
import { initDateInEntity } from '@/services/dateFunctions';
import { PublicConstants } from '@/types/PublicConstants';

@Module({ dynamic: true, namespaced: true, store, name: 'caseHistory' })
export class CaseHistoryModule extends VuexModule {
  private client = new HistoryClient(ApiUrl, axios);

  caseHistory: CaseHistoryDto = { historyItems: [] } as CaseHistoryDto;
  selectedHistoryItem = {} as HistoryItemDto;

  @Action
  async getCaseHistory(caseId) {
    const caseHistory = await this.client.getCaseHistory(caseId);

    this.setCaseHistory(caseHistory);
  }

  @Action
  async getHistoryItemAsync(historyItemId) {
    const item = await this.client.getHistoryItem(historyItemId);

    this.setSelectedHistoryItem(item);

    return this.selectedHistoryItem;
  }

  @Action
  async createHistoryItem(command: CreateHistoryItemCommand) {
    const formData = new FormData();

    mapFiles(command.files, 'Files', formData);

    formData.append('Text', command.text);
    formData.append('CaseId', command.caseId.toString());
    formData.append('UseSystemMessage', command.useSystemMessage?.toString());
    formData.append('ParentType', HistoryItemParentType.Case);

    const result: any = await axios.post(`${API_URL}/history/item`, formData, {
      headers: PublicConstants.multiPartHeaders,
    });

    if (result.data.isSuccess) {
      this.addCaseHistoryItem(result.data.entity);
    } else {
      NotificationService.NotifyErrors(result.data.errors);
    }

    return result.data.isSuccess;
  }

  @Action
  async updateHistoryItem(command: UpdateHistoryItemCommand) {
    const formData = new FormData();

    mapFiles(command.files, 'Files', formData);

    formData.append('HistoryItemId', command.historyItemId.toString());
    formData.append('Text', command.text);

    const item: any = await axios.put(`${API_URL}/history/item`, formData, {
      headers: PublicConstants.multiPartHeaders,
    });

    this.updateCaseHistoryItem(item.data);
  }

  @Mutation
  setCaseHistory(caseHistory: CaseHistoryDto) {
    const store = getModule(CaseHistoryModule);

    caseHistory.historyItems.forEach((item: any) => {
      store.initCreatedDate(item);
    });

    this.caseHistory = caseHistory;
  }

  @Mutation
  addCaseHistoryItem(item: HistoryItemDto) {
    const newCase: any = item;

    if (!this.caseHistory.historyItems) {
      this.caseHistory.historyItems = [];
    }

    if (newCase) {
      newCase.createdDate = new Date(item.created);
      this.caseHistory.historyItems.push(newCase);

      NotificationService.NotifySuccess('History item was successfully created');
    }
  }

  @Mutation
  updateCaseHistoryItem(item: HistoryItemDto) {
    const newCase: any = item;
    newCase.createdDate = new Date(item.created);

    const index = this.caseHistory.historyItems.findIndex((t) => t.id == item.id);
    this.caseHistory.historyItems[index] = newCase;

    NotificationService.NotifySuccess('History item was successfully updated');
  }

  @Mutation
  removeAttachment({ historyItemId, fileId }) {
    const index = this.caseHistory.historyItems.findIndex((t) => t.id === historyItemId);

    if (index !== -1) {
      const entity = this.caseHistory.historyItems[index];

      const fileIndex = entity.files.findIndex((t) => t.id == fileId);

      if (fileIndex !== -1) {
        entity.files.splice(fileIndex, 1);
      }
    }
  }

  @Mutation
  private initCreatedDate(entity) {
    entity.createdDate = new Date(entity.created);
  }

  @Mutation
  setSelectedHistoryItem(item: HistoryItemDto) {
    initDateInEntity(item, 'created', 'createdDate');

    this.selectedHistoryItem = item;
  }
}

export const CaseHistoryStore = getModule(CaseHistoryModule);
