import Routes from '@/types/Routes';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import LandingPageView from '../views/LandingPage.vue';
import CasesView from '../views/Cases.vue';
import CaseView from '../views/Case.vue';

import AdminToolView from '../views/LookUpValues.vue';
import UsersManagementView from '../views/UsersManagement.vue';

import GroupsView from '../views/Groups.vue';
import LibraryView from '../views/Library.vue';
import Groups from '@/components/groups/Groups.vue';
import Group from '@/components/groups/Group.vue';
import GroupMeeting from '@/components/groups/GroupMeeting.vue';

import Users from '@/components/admin/users/Users.vue';
import User from '@/components/admin/users/User.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: Routes.root.path,
    name: Routes.root.name,
    component: LandingPageView,
  },

  {
    path: Routes.cases.path,
    name: Routes.cases.name,
    component: CasesView,
  },
  {
    path: Routes.case.path,
    name: Routes.case.name,
    component: CaseView,
  },

  {
    path: Routes.groups.path,
    name: Routes.groups.name,
    component: GroupsView,

    children: [
      {
        path: Routes.groups.path,
        name: Routes.groups.name,
        component: Groups,
      },
      {
        path: Routes.group.path,
        name: Routes.group.name,
        component: Group,
      },
      {
        path: Routes.groupMeeting.path,
        name: Routes.groupMeeting.name,
        component: GroupMeeting,
      },
    ],
  },
  {
    path: Routes.library.path,
    name: Routes.library.name,
    component: LibraryView,
  },
  {
    path: Routes.lookUpValues.path,
    name: Routes.lookUpValues.name,
    component: AdminToolView,
  },
  {
    path: Routes.users.path,
    name: Routes.users.name,
    component: UsersManagementView,

    children: [
      { path: Routes.users.path, name: Routes.users.name, component: Users },
      {
        path: Routes.user.path,
        name: Routes.user.name,
        component: User,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
