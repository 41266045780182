
  import AsBlock from '@/components/shared/as-block-component.vue';
  import AsButton from '@/components/shared/as-button.vue';
  import { Options, Vue } from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { AntiSkidCaseFileDto, HistoryItemDto, LookUpValueDto, LookUpValueType } from '@/apiclient/apiclient_generated';
  import AsFilesSelector from '@/components/shared/as-files-selector.vue';
  import { formatDateWithoutTime } from '@/services/dateFunctions';
  import AsTextWithLabel from '@/components/shared/as-textWithLabel.vue';
  import { FilesModule, FilesStore } from '@/store/files';
  import FileService from '@/services/file.service';
  import AsModal from '@/components/shared/as-modal.vue';
  import ListHistoryItem from '../history/ListHistoryItem.vue';
  import { CaseHistoryModule, CaseHistoryStore } from '@/store/caseHistory';
  import VuePdfApp from 'vue3-pdf-app';
  import { LookUpValuesModule, LookUpValuesStore } from '@/store/lookUpValues';

  @Options({
    components: {
      AsBlock,
      AsButton,
      AsFilesSelector,
      AsTextWithLabel,
      AsModal,
      ListHistoryItem,
      VuePdfApp,
    },
  })
  export default class ListDocument extends Vue {
    @Prop()
    document: AntiSkidCaseFileDto;

    private filesStore: FilesModule = FilesStore;
    private historyStore: CaseHistoryModule = CaseHistoryStore;
    private lookUpStore: LookUpValuesModule = LookUpValuesStore;

    previewDocument = false;
    previewFile = { name: '', arrayBuffer: null };

    get formattedDate() {
      return formatDateWithoutTime(this.document['createdDate']);
    }

    get documentCategory() {
      const result: LookUpValueDto = this.lookUpStore.getLookUpValue({ group: LookUpValueType.FileCategory, id: this.document.categoryId });

      return result?.value;
    }

    showHistoryItem = false;
    async showHistoryItemModal() {
      await this.historyStore.getHistoryItemAsync(this.document.additionalParentId);
      this.showHistoryItem = true;
    }
    closeHistoryItemModal() {
      this.historyStore.setSelectedHistoryItem({} as HistoryItemDto);
      this.showHistoryItem = false;
    }
    closePreviewDocument() {
      this.resetPreviewFile();
      this.previewDocument = false;
    }

    resetPreviewFile() {
      this.previewFile = {
        name: '',
        arrayBuffer: null,
      };
    }

    get selectedHistoryItem() {
      return this.historyStore.selectedHistoryItem;
    }

    get isPdfFile() {
      return this.document.name.toLowerCase().includes('.pdf');
    }
    async downloadFile(fileId) {
      const file = await this.filesStore.downloadCaseFile(fileId);

      if (file) {
        FileService.downloadFile(file.data, file.fileName);
      }
    }

    async processPreviewDocumentClick() {
      this.resetPreviewFile();
      const file = await this.filesStore.downloadCaseFile(this.document.id);

      this.previewFile.name = file.fileName;

      const fileLoadHandler = (arrayBuffer) => {
        this.previewFile.arrayBuffer = arrayBuffer;
        this.previewDocument = !this.previewDocument;
      };

      var fileReader = new FileReader();
      fileReader.onload = function (event) {
        fileLoadHandler(event.target.result);
      };

      fileReader.readAsArrayBuffer(file.data);
    }
  }
