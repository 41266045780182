import { CaseModule } from './../store/case';
import { ApplicationRoleType, CaseRoleType } from '@/apiclient/apiclient_generated';
import { UserModule } from '@/store/user';
import { UserManagementModule } from '@/store/userManagement';
import { CaseReadWriteScope, EditPermissions, WriteRolePermissions } from '@/types/CaseReadWriteScope';
import moment from 'moment';
import { getModule } from 'vuex-module-decorators';

export default class AccessRightsService {
  private static getReadScope() {
    const readScope_ = {};

    readScope_[CaseReadWriteScope.CasePersonalData] = [
      ApplicationRoleType.SystemAdministrator,
      ApplicationRoleType.CaseManager,
      ApplicationRoleType.GroupAdmin,

      CaseRoleType.Peer,
      CaseRoleType.Therapist,
      CaseRoleType.SuperVisor,
      CaseRoleType.ClinicStaff,
      CaseRoleType.TechnicalSuperVisor,
      CaseRoleType.FullAccess,

      // CaseRoleType.Readonly,
      // CaseRoleType.Laboratory,
    ];

    readScope_[CaseReadWriteScope.CaseData] = [
      ApplicationRoleType.SystemAdministrator,
      ApplicationRoleType.CaseManager,
      ApplicationRoleType.GroupAdmin,

      CaseRoleType.Peer,
      CaseRoleType.Therapist,
      CaseRoleType.SuperVisor,
      CaseRoleType.ClinicStaff,
      CaseRoleType.TechnicalSuperVisor,
      CaseRoleType.FullAccess,

      CaseRoleType.Readonly,
      CaseRoleType.Laboratory,
    ];

    readScope_[CaseReadWriteScope.CaseHistory] = [
      ApplicationRoleType.SystemAdministrator,
      ApplicationRoleType.CaseManager,
      ApplicationRoleType.GroupAdmin,

      CaseRoleType.Peer,
      CaseRoleType.Therapist,
      CaseRoleType.SuperVisor,
      CaseRoleType.ClinicStaff,
      CaseRoleType.TechnicalSuperVisor,
      CaseRoleType.FullAccess,

      CaseRoleType.Readonly,
      // CaseRoleType.Laboratory,
    ];

    readScope_[CaseReadWriteScope.Documents] = [
      ApplicationRoleType.SystemAdministrator,
      ApplicationRoleType.CaseManager,
      ApplicationRoleType.GroupAdmin,

      CaseRoleType.Peer,
      CaseRoleType.Therapist,
      CaseRoleType.SuperVisor,
      CaseRoleType.ClinicStaff,
      CaseRoleType.TechnicalSuperVisor,
      CaseRoleType.FullAccess,

      CaseRoleType.Readonly,
      // CaseRoleType.Laboratory,
    ];

    readScope_[CaseReadWriteScope.Laboratory] = [
      ApplicationRoleType.SystemAdministrator,
      ApplicationRoleType.CaseManager,
      ApplicationRoleType.GroupAdmin,

      CaseRoleType.Peer,
      CaseRoleType.Therapist,
      CaseRoleType.SuperVisor,
      CaseRoleType.ClinicStaff,
      CaseRoleType.TechnicalSuperVisor,
      CaseRoleType.FullAccess,

      CaseRoleType.Readonly,
      CaseRoleType.Laboratory,
    ];

    readScope_[CaseReadWriteScope.UserRights] = [
      ApplicationRoleType.SystemAdministrator,
      ApplicationRoleType.CaseManager,
      ApplicationRoleType.GroupAdmin,

      CaseRoleType.Peer,
      CaseRoleType.Therapist,
      CaseRoleType.SuperVisor,
      CaseRoleType.ClinicStaff,
      CaseRoleType.TechnicalSuperVisor,
      CaseRoleType.FullAccess,

      CaseRoleType.Readonly,
      // CaseRoleType.Laboratory,
    ];

    return readScope_;
  }

  private static getWriteScope() {
    const writeScope_ = {};

    writeScope_[CaseReadWriteScope.NewCase] = [
      { role: ApplicationRoleType.SystemAdministrator, permissions: EditPermissions.All },
      { role: ApplicationRoleType.CaseManager, permissions: EditPermissions.All },
    ] as WriteRolePermissions[];

    writeScope_[CaseReadWriteScope.CasePersonalData] = [
      { role: ApplicationRoleType.SystemAdministrator, permissions: EditPermissions.All },
      { role: ApplicationRoleType.CaseManager, permissions: EditPermissions.All },
      { role: ApplicationRoleType.GroupAdmin, permissions: EditPermissions.All },

      { role: CaseRoleType.Peer, permissions: EditPermissions.All },
      { role: CaseRoleType.Therapist, permissions: EditPermissions.All },
      { role: CaseRoleType.SuperVisor, permissions: EditPermissions.All },
      { role: CaseRoleType.ClinicStaff, permissions: EditPermissions.All },
      { role: CaseRoleType.TechnicalSuperVisor, permissions: EditPermissions.All },
      { role: CaseRoleType.FullAccess, permissions: EditPermissions.All },

      // { role: CaseRoleType.Readonly, permissions: EditPermissions.All },
      // { role: CaseRoleType.Laboratory, permissions: EditPermissions.All },
    ] as WriteRolePermissions[];

    writeScope_[CaseReadWriteScope.CaseData] = [
      { role: ApplicationRoleType.SystemAdministrator, permissions: EditPermissions.All },
      { role: ApplicationRoleType.CaseManager, permissions: EditPermissions.All },
      { role: ApplicationRoleType.GroupAdmin, permissions: EditPermissions.All },

      { role: CaseRoleType.Peer, permissions: EditPermissions.All },
      { role: CaseRoleType.Therapist, permissions: EditPermissions.All },
      { role: CaseRoleType.SuperVisor, permissions: EditPermissions.All },
      { role: CaseRoleType.ClinicStaff, permissions: EditPermissions.All },
      { role: CaseRoleType.TechnicalSuperVisor, permissions: EditPermissions.All },
      { role: CaseRoleType.FullAccess, permissions: EditPermissions.All },

      // { role: CaseRoleType.Readonly, permissions: EditPermissions.All },
      // { role: CaseRoleType.Laboratory, permissions: EditPermissions.All },
    ] as WriteRolePermissions[];

    writeScope_[CaseReadWriteScope.CaseHistory] = [
      { role: ApplicationRoleType.SystemAdministrator, permissions: EditPermissions.All },
      { role: ApplicationRoleType.CaseManager, permissions: EditPermissions.All },
      { role: ApplicationRoleType.GroupAdmin, permissions: EditPermissions.Personal },

      { role: CaseRoleType.Peer, permissions: EditPermissions.Personal },
      { role: CaseRoleType.Therapist, permissions: EditPermissions.Personal },
      { role: CaseRoleType.SuperVisor, permissions: EditPermissions.Personal },
      { role: CaseRoleType.ClinicStaff, permissions: EditPermissions.Personal },
      { role: CaseRoleType.TechnicalSuperVisor, permissions: EditPermissions.Personal },

      { role: CaseRoleType.FullAccess, permissions: EditPermissions.All },

      // { role: CaseRoleType.Readonly, permissions: EditPermissions.All },
      // { role: CaseRoleType.Laboratory, permissions: EditPermissions.All },
    ] as WriteRolePermissions[];

    writeScope_[CaseReadWriteScope.Documents] = [
      { role: ApplicationRoleType.SystemAdministrator, permissions: EditPermissions.All },
      { role: ApplicationRoleType.CaseManager, permissions: EditPermissions.All },
      { role: ApplicationRoleType.GroupAdmin, permissions: EditPermissions.Personal },

      { role: CaseRoleType.Peer, permissions: EditPermissions.Personal },
      { role: CaseRoleType.Therapist, permissions: EditPermissions.Personal },
      { role: CaseRoleType.SuperVisor, permissions: EditPermissions.Personal },
      { role: CaseRoleType.ClinicStaff, permissions: EditPermissions.Personal },
      { role: CaseRoleType.TechnicalSuperVisor, permissions: EditPermissions.Personal },

      { role: CaseRoleType.FullAccess, permissions: EditPermissions.All },

      // { role: CaseRoleType.Readonly, permissions: EditPermissions.All },
      // { role: CaseRoleType.Laboratory, permissions: EditPermissions.All },
    ] as WriteRolePermissions[];

    writeScope_[CaseReadWriteScope.Laboratory] = [
      { role: ApplicationRoleType.SystemAdministrator, permissions: EditPermissions.All },
      { role: ApplicationRoleType.CaseManager, permissions: EditPermissions.All },

      { role: CaseRoleType.FullAccess, permissions: EditPermissions.All },

      // { role: ApplicationRoleType.GroupAdmin, permissions: EditPermissions.Personal },
      // { role: CaseRoleType.Peer, permissions: EditPermissions.Personal },
      // { role: CaseRoleType.Therapist, permissions: EditPermissions.Personal },
      // { role: CaseRoleType.SuperVisor, permissions: EditPermissions.Personal },
      // { role: CaseRoleType.ClinicStaff, permissions: EditPermissions.Personal },
      // { role: CaseRoleType.TechnicalSuperVisor, permissions: EditPermissions.Personal },
      // { role: CaseRoleType.Readonly, permissions: EditPermissions.All },

      { role: CaseRoleType.Laboratory, permissions: EditPermissions.All },
    ] as WriteRolePermissions[];

    writeScope_[CaseReadWriteScope.UserRights] = [
      { role: ApplicationRoleType.SystemAdministrator, permissions: EditPermissions.All },
      { role: ApplicationRoleType.CaseManager, permissions: EditPermissions.All },
      { role: CaseRoleType.FullAccess, permissions: EditPermissions.All },

      // { role: ApplicationRoleType.GroupAdmin, permissions: EditPermissions.Personal },
      // { role: CaseRoleType.Peer, permissions: EditPermissions.Personal },
      // { role: CaseRoleType.Therapist, permissions: EditPermissions.Personal },
      // { role: CaseRoleType.SuperVisor, permissions: EditPermissions.Personal },
      // { role: CaseRoleType.ClinicStaff, permissions: EditPermissions.Personal },
      // { role: CaseRoleType.TechnicalSuperVisor, permissions: EditPermissions.Personal },
      // { role: CaseRoleType.FullAccess, permissions: EditPermissions.Personal },
      // { role: CaseRoleType.Readonly, permissions: EditPermissions.All },
      // { role: CaseRoleType.Laboratory, permissions: EditPermissions.All },
    ] as WriteRolePermissions[];

    return writeScope_;
  }

  static canUserRead(context: CaseReadWriteScope) {
    const availableRoles: [] = AccessRightsService.getReadScope()[context];

    if (!availableRoles || !availableRoles.length) {
      return false;
    }

    const currentUserRoles = AccessRightsService.getCurrentUserAllRoles();

    if (!currentUserRoles || !currentUserRoles.length) {
      return false;
    }

    return availableRoles.some((t) => currentUserRoles.includes(t));
  }

  static canUserWrite(context: CaseReadWriteScope, entityCreator = '', entityLastEditDate = '') {
    const userStore = getModule(UserModule);

    const userId_ = +userStore.currentUserId;
    const entityCreator_ = +entityCreator;

    const availableRoles: WriteRolePermissions[] = AccessRightsService.getWriteScope()[context];

    if (!availableRoles || !availableRoles.length) {
      return false;
    }

    const userRoles = AccessRightsService.getCurrentUserAllRoles();

    const rights = availableRoles.find((t) => userRoles.includes(t.role) && !userRoles.includes(CaseRoleType.Readonly));

    if (!rights) {
      return false;
    }

    switch (rights.permissions) {
      case EditPermissions.All:
        return true;

      case EditPermissions.Personal:
        return userId_ == entityCreator_ && AccessRightsService.isValidDateForEditing(entityLastEditDate);

      default:
        return false;
    }
  }

  private static getCurrentUserAllRoles() {
    const caseStore = getModule(CaseModule);
    const userStore = getModule(UserModule);
    const usersStore = getModule(UserManagementModule);

    const userId_ = +userStore.currentUserId;

    const userApplicationPermissions = usersStore.users[userId_]?.roles || [];
    const caseUser = caseStore.caseUsers?.caseUsers?.find((t) => t.userId === userId_);
    const defaultCaseUser = caseStore?.caseUsers.defaultUsers?.find((t) => t.id === userId_);

    return [...userApplicationPermissions, ...[caseUser?.caseRole], defaultCaseUser?.roles].filter((t) => t);
  }

  private static maxAllowedEditHours = 48;

  private static isValidDateForEditing(entityLastEditDate) {
    const startDate = entityLastEditDate;

    const start = moment(startDate);
    const end = moment(new Date());

    const duration = moment.duration(end.diff(start));
    const hours = duration.asHours();
    return hours < AccessRightsService.maxAllowedEditHours;
  }
}
