
  import { Options, Vue } from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';

  @Options({})
  export default class AsModal extends Vue {
    @Prop() open: boolean;
    @Prop({ default: true }) showCloseButton: boolean;
    @Prop({ default: false }) hideFooter: boolean;
    @Prop({ default: false }) size: string;

    private handleKeyup(event) {
      if (event.keyCode === 27) {
        this.close();
      }
    }

    close() {
      this.$emit('close');
    }

    mounted() {
      document.addEventListener('keyup', this.handleKeyup);
    }
    unmounted() {
      document.removeEventListener('keyup', this.handleKeyup);
    }

    get modalInnerStyle() {
      switch (this.size) {
        case 'large':
          return 'as-modal-inner-large';

        default:
          return 'as-modal-inner-regular';
      }
    }
  }
