import {
  CreateLookUpValueCommand,
  GetLookUpValuesQuery,
  LookUpClient,
  LookUpValueDto,
  LookUpValueGroup,
  LookUpValueType,
  ServiceResultOfLookUpValueDto,
  UpdateLookUpValueCommand,
  UpdateLookUpValuesSortOrderCommand,
} from './../apiclient/apiclient_generated';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/index';
import { WS_URL as ApiUrl } from '@/config';
import axios from 'axios';
import { NotificationService } from '@/services/notification.service';
import { PublicConstants } from '@/types/PublicConstants';

@Module({ dynamic: true, namespaced: true, store, name: 'lookUpValues' })
export class LookUpValuesModule extends VuexModule {
  private client = new LookUpClient(ApiUrl, axios);

  lookUpValueGroups: LookUpValueGroup[] = [];

  get testResultLookUpValues(): LookUpValueDto[] {
    const group = this.lookUpValueGroups.find((t) => t.type === LookUpValueType.LaboratoryTestResult);

    return group ? group.lookUpValues : [];
  }

  get getLookUpValueName() {
    return function ({ group, id }) {
      return (
        this.lookUpValueGroups.find((t) => t.type == group)?.lookUpValues.find((t) => t.id === id)?.value || PublicConstants.emptyValue
      );
    };
  }

  get getLookUpValue() {
    return function ({ group, id }) {
      return this.lookUpValueGroups.find((t) => t.type == group)?.lookUpValues.find((t) => t.id === id) || null;
    };
  }

  get lookUpOptions() {
    return function (groupType: LookUpValueType) {
      const options = this.lookUpValueGroups.find((t) => t.type === groupType);

      return (options?.lookUpValues || []).sort((a, b) => a.sortOrder - b.sortOrder);
    };
  }

  @Action
  async getLookUpValues() {
    const result = await this.client.getLookUpValues({} as GetLookUpValuesQuery);

    this.setLookUpValues(result);
  }

  @Action
  async deleteLookUpValue(dto: LookUpValueDto) {
    const result = await this.client.delete(dto.id);

    if (result.isSuccess) {
      dto.isDeleted = true;
      this.setLookUpValue(dto);
    } else {
      NotificationService.NotifyErrors(result.errors);
    }

    return result;
  }

  @Action
  async reorderLookUpValues(command: UpdateLookUpValuesSortOrderCommand) {
    await this.client.reorderLookUpValues(command);
  }

  @Action
  async createLookUpValue(command: CreateLookUpValueCommand): Promise<ServiceResultOfLookUpValueDto> {
    const result = await this.client.create(command);

    if (result.isSuccess) {
      this.addLookUpValue(result.entity);
    } else {
      NotificationService.NotifyErrors(result.errors);
    }

    return result;
  }

  @Action
  async updateLookUpValue(command: UpdateLookUpValueCommand): Promise<ServiceResultOfLookUpValueDto> {
    const result = await this.client.update(command);

    if (result.isSuccess) {
      this.processLookUpValueUpdate(result.entity);
    } else {
      NotificationService.NotifyErrors(result.errors);
    }

    return result;
  }

  @Mutation
  setLookUpValues(values) {
    this.lookUpValueGroups = values;
  }

  @Mutation
  setLookUpValue(value: LookUpValueDto) {
    this.lookUpValueGroups.forEach((group) => {
      const index = group.lookUpValues.findIndex((t) => t.id === value.id);

      if (index >= 0) {
        group.lookUpValues[index] = value;
      }
    });
  }

  @Mutation
  async addLookUpValue(lookUpValue: LookUpValueDto) {
    const group = this.lookUpValueGroups.find((t) => t.type == lookUpValue.type);

    if (!group) {
      this.lookUpValueGroups.push({
        type: lookUpValue.type,
        lookUpValues: [lookUpValue],
      } as LookUpValueGroup);
    } else {
      if (!group.lookUpValues || !group.lookUpValues.length) {
        group.lookUpValues = [];
      }

      group.lookUpValues.push(lookUpValue);
    }
  }

  @Mutation
  async processLookUpValueUpdate(updatedValue: LookUpValueDto) {
    let isAdded = false;
    this.lookUpValueGroups.forEach((group) => {
      const obsoleteLookUpValueIndex = group.lookUpValues.findIndex((item) => item.id == updatedValue.id);

      if (group.type == updatedValue.type) {
        if (obsoleteLookUpValueIndex >= 0) {
          group.lookUpValues[obsoleteLookUpValueIndex] = updatedValue;
        } else {
          group.lookUpValues.push(updatedValue);
          isAdded = true;
        }
      }

      if (obsoleteLookUpValueIndex >= 0 && group.type != updatedValue.type) {
        group.lookUpValues.splice(obsoleteLookUpValueIndex, 1);
      }
    });

    if (!isAdded) {
      let group = this.lookUpValueGroups.find((t) => t.type === updatedValue.type);

      if (group) {
        group.lookUpValues = group.lookUpValues ?? [];
      } else {
        group = {
          type: updatedValue.type,
          lookUpValues: [],
        } as LookUpValueGroup;

        this.lookUpValueGroups.push(group);
      }
      group.lookUpValues.push(updatedValue);
    }
  }
}

export const LookUpValuesStore = getModule(LookUpValuesModule);
