import { notify } from '@kyvg/vue3-notification';

export class NotificationService {
  static NotifySuccess(message: any = '', duration = 5000) {
    if (!message) {
      message = 'Success.';
    }

    notify({
      group: 'foo',
      text: message,
      type: 'success',
      duration: duration,
    });
  }

  static NotifyWarn(message: string, group = 'foo', title = 'Warn', duration = 5000) {
    notify({
      group: group,
      title: title,
      text: message,
      type: 'warn',
      duration: duration,
    });
  }

  static NotifyError(message: string, group = 'foo', title = 'Error', duration = 5000) {
    notify({
      group: group,
      title: title,
      text: message,
      type: 'error',
      duration: duration,
    });
  }

  static NotifyErrors(errors: string[], title = 'Error', duration = 5000) {
    errors.forEach((error) => {
      setTimeout(() => {
        this.NotifyError(error);
      }, 800);
    });
  }
}
