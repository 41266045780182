import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1554960e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cases" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "cases-content" }
const _hoisted_4 = { class: "empty-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewCase = _resolveComponent("NewCase")!
  const _component_AsDropdown = _resolveComponent("AsDropdown")!
  const _component_AsMultiSelect = _resolveComponent("AsMultiSelect")!
  const _component_AsInput = _resolveComponent("AsInput")!
  const _component_AsBlock = _resolveComponent("AsBlock")!
  const _component_Case = _resolveComponent("Case")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.canCreate)
      ? (_openBlock(), _createBlock(_component_NewCase, {
          key: 0,
          onWizardInit: _ctx.processWizardToggle,
          onWizardClose: _ctx.processWizardToggle
        }, null, 8, ["onWizardInit", "onWizardClose"]))
      : _createCommentVNode("", true),
    (!_ctx.isNewCaseFormEnabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_AsBlock, { class: "grid-container" }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_AsDropdown, {
                  options: _ctx.caseFilters.displayFilters,
                  value: _ctx.displayFilter,
                  label: _ctx.$t('case.filters.display'),
                  placeholder: _ctx.$t('case.filters.displayPlaceholder'),
                  optionLabel: 'label',
                  optionValue: 'value',
                  onUpdate: _cache[0] || (_cache[0] = (value) => ((_ctx.displayFilter = value), _ctx.getFilteredCases()))
                }, null, 8, ["options", "value", "label", "placeholder"])
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_AsMultiSelect, {
                  label: _ctx.$t('case.filters.years'),
                  clearIcon: true,
                  options: _ctx.caseFilters.years,
                  value: _ctx.selectedYears,
                  optionLabel: 'label',
                  optionValue: 'value',
                  placeholder: _ctx.$t('case.filters.yearsPlaceholder'),
                  selectedItemsLabel: _ctx.getSelectedLabel(_ctx.selectedYears, _ctx.caseFilters.years),
                  onUpdate: _cache[1] || (_cache[1] = (value) => ((_ctx.selectedYears = value), _ctx.getFilteredCases()))
                }, null, 8, ["label", "options", "value", "placeholder", "selectedItemsLabel"])
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_AsMultiSelect, {
                  label: _ctx.$t('case.filters.programs'),
                  clearIcon: true,
                  options: _ctx.caseFilters.programs,
                  value: _ctx.selectedPrograms,
                  optionLabel: 'label',
                  optionValue: 'value',
                  placeholder: _ctx.$t('case.filters.programsPlaceholder'),
                  selectedItemsLabel: _ctx.getSelectedLabel(_ctx.selectedPrograms, _ctx.caseFilters.programs),
                  onUpdate: _cache[2] || (_cache[2] = (value) => ((_ctx.selectedPrograms = value), _ctx.getFilteredCases()))
                }, null, 8, ["label", "options", "value", "placeholder", "selectedItemsLabel"])
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_AsMultiSelect, {
                  label: _ctx.$t('case.filters.groups'),
                  clearIcon: true,
                  options: _ctx.caseFilters.groups,
                  value: _ctx.selectedGroups,
                  optionLabel: 'label',
                  optionValue: 'value',
                  placeholder: _ctx.$t('case.filters.groupsPlaceholder'),
                  selectedItemsLabel: _ctx.getSelectedLabel(_ctx.selectedGroups, _ctx.caseFilters.groups),
                  onUpdate: _cache[3] || (_cache[3] = (value) => ((_ctx.selectedGroups = value), _ctx.getFilteredCases()))
                }, null, 8, ["label", "options", "value", "placeholder", "selectedItemsLabel"])
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_AsInput, {
                  label: _ctx.$t('case.filters.caseNumber'),
                  placeholder: _ctx.$t('case.filters.caseNumberPlaceholder'),
                  value: _ctx.caseNumberFilter,
                  onUpdate: _cache[4] || (_cache[4] = (value) => ((_ctx.caseNumberFilter = value), _ctx.getFilteredCases()))
                }, null, 8, ["label", "placeholder", "value"])
              ])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredCases, (_, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: _.caseData.id
              }, [
                _createVNode(_component_Case, {
                  modelValue: _ctx.filteredCases[index],
                  "onUpdate:modelValue": ($event: any) => ((_ctx.filteredCases[index]) = $event)
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]))
            }), 128)),
            (!_ctx.filteredCases || !_ctx.filteredCases.length)
              ? (_openBlock(), _createBlock(_component_AsBlock, { key: 0 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('noData')), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}