
  import { Options, Vue } from 'vue-class-component';
  import AsBlock from '../../shared/as-block-component.vue';
  import AsButton from '../../shared/as-button.vue';
  import AsInput from '../../shared/as-input.vue';
  import AsDropdown from '../../shared/as-dropdown.vue';
  import AsReadWriteBlock from '../../shared/as-read-write.vue';
  import AsLabeledBlock from '@/components/shared/as-labeled-block.vue';
  import AsSwitch from '@/components/shared/as-switch.vue';
  import { ButtonTypes } from '@/types/ButtonTypes';
  import Routes from '@/types/Routes';
  import { UserManagementModule, UserManagementStore } from '@/store/userManagement';
  import {
    ApplicationRoleType,
    CreateUserCommand,
    LookUpValueType,
    UpdatePasswordCommand,
    UpdateUserCommand,
    UserDto,
  } from '@/apiclient/apiclient_generated';
  import { ref } from 'vue';
  import { UserModule, UserStore } from '@/store/user';
  import { isEmailValid } from '@/services/formFunctions';
  import { NotificationService } from '@/services/notification.service';
  import { LookUpValuesModule, LookUpValuesStore } from '@/store/lookUpValues';
  import AsEditBlock from '@/components/shared/as-edit-block.vue';
  import { faL } from '@fortawesome/free-solid-svg-icons';
  import { Watch } from 'vue-property-decorator';

  @Options({
    components: {
      AsBlock,
      AsButton,
      AsInput,
      AsDropdown,
      AsReadWriteBlock,
      AsLabeledBlock,
      AsSwitch,
      AsEditBlock,
    },
  })
  export default class UserComponent extends Vue {
    private usersStore: UserManagementModule = UserManagementStore;
    private userStore: UserModule = UserStore;
    private lookUpStore: LookUpValuesModule = LookUpValuesStore;

    appRoles = ApplicationRoleType;
    buttonTypes = ButtonTypes;
    lvTypes = LookUpValueType;

    private model: any = ref({});

    updatePasswordCommand = {
      oldPassword: '',
      newPassword: '',
    } as UpdatePasswordCommand;
    isPassUpdateInProcess = false;

    userPassword = '';

    async mounted() {
      if (this.userId) {
        await this.loadUserDetails();
      }

      await this.lookUpStore.getLookUpValues();
    }

    async loadUserDetails() {
      this.model = await this.usersStore.getUser(this.userId);
    }

    @Watch('$route')
    async onUrlChange(newRoute, oldRoute) {
      if (newRoute.name === Routes.user.name && newRoute.fullPath !== oldRoute.fullPath) {
        await this.loadUserDetails();
      }
    }

    get user(): UserDto {
      return this.model as UserDto;
    }

    get userId(): any {
      return this.$route.params.id;
    }

    get isCurrentUser() {
      const currentUserId = this.userStore.userData.id;

      return this.user.id == currentUserId;
    }

    cancel() {
      const previousUrl = localStorage.getItem(Routes.PreviousUrl) || Routes.users.path;

      this.$router.push({ path: previousUrl });
    }

    userHasRole(role: ApplicationRoleType) {
      const roleIndex = this.user.roles?.findIndex((t) => t == role);

      return roleIndex >= 0;
    }

    toggleUserRole(role: ApplicationRoleType) {
      if (!this.user.roles) {
        this.user.roles = [];
      }

      const roleIndex = this.user.roles.findIndex((t) => t == role);

      if (roleIndex >= 0) {
        this.user.roles.splice(roleIndex, 1);
      } else {
        this.user.roles.push(role);
      }
    }

    get isEmailValid() {
      return isEmailValid(this.user.email);
    }

    get isCurrentUserAdmin() {
      return this.userStore.isCurrentUserAdmin;
    }

    get isPassFormValid() {
      return (
        !this.isPassUpdateInProcess &&
        (this.isCurrentUserAdmin
          ? this.updatePasswordCommand.newPassword
          : this.updatePasswordCommand.oldPassword && this.updatePasswordCommand.newPassword)
      );
    }

    getValueOrEmpty(value) {
      return value || '';
    }

    isPasswordValid() {
      return this.userPassword && this.userPassword.length >= 6;
    }

    get isFormValid() {
      return this.isEmailValid && this.user.lastName && this.user.firstName;
    }

    async processUserSubmit() {
      const command = {
        firstName: this.user.firstName,
        lastName: this.user.lastName,

        email: this.user.email,
        isActive: !this.user.isDeleted,

        roles: this.user.roles,

        phone: this.user.phone,
        city: this.user.city,
        airline: this.user.airline,
        functionId: this.user.functionId,
        nearestAirport: this.user.nearestAirport,
      } as CreateUserCommand;

      if (this.userId) {
        await this.updateUser(command);
      } else {
        await this.createUser(command);
      }
    }

    private async createUser(command: CreateUserCommand) {
      command.password = this.userPassword;
      const result = await this.usersStore.createUser(command);

      if (!result.isSuccess) {
        NotificationService.NotifyErrors(result.errors);
      } else {
        NotificationService.NotifySuccess(this.$t('user.createdNotification'));

        this.$router.push({ name: Routes.user.name, params: { id: result.entity } });
      }
    }
    private async updateUser(command: CreateUserCommand) {
      const updateCommand = command as UpdateUserCommand;
      updateCommand.id = this.userId;

      const result = await this.usersStore.updateUser(updateCommand);

      if (!result.isSuccess) {
        result.errors.forEach((error) => {
          NotificationService.NotifyError(this.$t(error));
        });
      } else {
        this.model = result.entity;

        NotificationService.NotifySuccess(this.$t('user.updatedNotification'));
      }
    }

    getLookUpOptions(groupType: LookUpValueType) {
      return this.lookUpStore.lookUpOptions(groupType);
    }

    async processEditPasswordSubmit(event) {
      this.$confirm.require({
        target: event.currentTarget,
        message: this.$t('user.password.changeWarn'),
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          this.isPassUpdateInProcess = true;

          this.updatePasswordCommand.userId = this.user.id;
          const isSuccess = await this.userStore.updatePasswordAsync(this.updatePasswordCommand);

          this.isPassUpdateInProcess = false;

          if (isSuccess) {
            NotificationService.NotifySuccess(this.$t('user.password.passwordWasUpdated'));
            this.processEditPasswordCancel();
          }
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    }

    processEditPasswordCancel() {
      this.updatePasswordCommand = {} as UpdatePasswordCommand;
    }
  }
