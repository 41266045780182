
  import { Options, Vue } from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { useDropzone } from 'vue3-dropzone';
  import { ref } from 'vue';
  import AsButton from './as-button.vue';
  import { ButtonTypes } from '@/types/ButtonTypes';
  import AsBlock from './as-block-component.vue';
  import AsInput from './as-input.vue';
  import AsDropdown from './as-dropdown.vue';
  import { FileDto } from '@/types/FileDto';
  import { LookUpValueType } from '@/apiclient/apiclient_generated';
  import { LookUpValuesModule, LookUpValuesStore } from '@/store/lookUpValues';

  @Options({
    components: {
      AsButton,
      AsDropdown,
      AsBlock,
      AsInput,
    },
  })
  export default class AsFilesSelector extends Vue {
    @Prop() type;
    @Prop() label;
    @Prop() files: FileDto[];
    @Prop() hideText;
    @Prop() singleMode;
    @Prop() allowAnyFiles;

    @Prop() filesCategory: LookUpValueType;

    defaultFileTypes = '.pdf';

    private lookUpStore: LookUpValuesModule = LookUpValuesStore;

    filesInternal: FileDto[] = [];

    getRootProps;
    getInputProps;

    isDragActive: any = ref(true);
    isInitialized = false;
    bTypes = ButtonTypes;

    mounted() {
      const accept = this.allowAnyFiles ? '' : this.defaultFileTypes;
      const dropZoneData = useDropzone({ onDrop: this.onDrop, multiple: !this.singleMode, accept: accept });

      this.getRootProps = dropZoneData.getRootProps;
      this.getInputProps = dropZoneData.getInputProps;
      this.isDragActive = dropZoneData.isDragActive;

      if (this.files && this.files.length) {
        this.files.forEach((f) => this.filesInternal.push(f));
      }

      this.isInitialized = true;
    }

    get filesPlaceholder() {
      return this.singleMode ? 'file' : 'files';
    }

    onDrop(acceptFiles) {
      acceptFiles.forEach((file) => {
        const newFile = {
          Name: file.name,
          // Category: this.defaultCategory ?? FileCategory.None,
          Description: file.Description,
          File: file,
        } as FileDto;

        this.filesInternal.push(newFile);
      });
      return this.$emit('update', this.filesInternal);
    }

    handleClickDeleteFile(index) {
      this.filesInternal.splice(index, 1);

      return this.$emit('update', this.filesInternal);
    }

    get selectFilesPlaceHolder() {
      const key = this.singleMode ? 'file.selectFilePlaceHolder' : 'file.selectFilesPlaceHolder';

      return this.$t(key);
    }

    get dropFilesPlaceHolder() {
      const key = this.singleMode ? 'file.dropFilePlaceHolder' : 'file.dropFilesPlaceHolder';

      return this.$t(key);
    }

    get fileCategories() {
      return this.lookUpStore.lookUpOptions(this.category);
    }

    get category() {
      return this.filesCategory ? this.filesCategory : LookUpValueType.FileCategory;
    }
  }
