import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4768427e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "block-header" }
const _hoisted_4 = { class: "right-aligned-items" }
const _hoisted_5 = { class: "new-case-form grid-container" }
const _hoisted_6 = { class: "form-item" }
const _hoisted_7 = { class: "form-item pipeline-wrapper" }
const _hoisted_8 = { class: "pipeline-number" }
const _hoisted_9 = { class: "form-item form-item-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!
  const _component_as_button = _resolveComponent("as-button")!
  const _component_AsBlock = _resolveComponent("AsBlock")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_CasePersonalData = _resolveComponent("CasePersonalData")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_AsFilesSelector = _resolveComponent("AsFilesSelector")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.showNewCaseForm)
      ? (_openBlock(), _createBlock(_component_AsBlock, {
          key: 0,
          class: "new-case-wrapper"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('newCase.Cases')), 1),
            _createVNode(_component_as_button, {
              type: _ctx.bTypes.Primary,
              label: _ctx.$t('newCase.Title'),
              onClick: _ctx.initNewCase
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_fa, { icon: "icon fa-plus" })
              ]),
              _: 1
            }, 8, ["type", "label", "onClick"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.showNewCaseForm)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_AsBlock, { class: "new-case-submit-block" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('newCase.CreateNewCase')), 1),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_as_button, {
                  type: _ctx.bTypes.Primary,
                  disabled: !_ctx.isFormValid,
                  label: 'Create',
                  onClick: _ctx.createNewCase
                }, null, 8, ["type", "disabled", "onClick"]),
                _createVNode(_component_as_button, {
                  type: _ctx.bTypes.Outlined,
                  label: 'Cancel',
                  onClick: _ctx.closeNewCase
                }, null, 8, ["type", "onClick"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_AsBlock, {
            header: _ctx.$t('case.CaseData')
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", null, _toDisplayString(_ctx.$t('case.Manager')), 1),
                  _createVNode(_component_Dropdown, {
                    class: "form-control",
                    modelValue: _ctx.newCaseCommand.CaseManagerId,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newCaseCommand.CaseManagerId) = $event)),
                    filter: true,
                    options: _ctx.getCaseManagers(),
                    optionLabel: "fullName",
                    optionValue: "id",
                    placeholder: "Select a User"
                  }, null, 8, ["modelValue", "options"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('case.PipelineNumber')), 1),
                    _createVNode(_component_fa, {
                      class: "pipeline-refresh",
                      icon: "icon fa-refresh",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.generatePipelineCaseNumber()))
                    })
                  ]),
                  _createVNode(_component_InputText, {
                    class: "form-control",
                    type: "text",
                    modelValue: _ctx.newCaseCommand.PersonalData.PipelineCaseNumber,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newCaseCommand.PersonalData.PipelineCaseNumber) = $event))
                  }, null, 8, ["modelValue"])
                ])
              ])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_AsBlock, {
            header: _ctx.$t('case.personalData.Title')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_CasePersonalData, { personalData: _ctx.personalData }, null, 8, ["personalData"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_AsBlock, {
            header: _ctx.$t('case.FirstContact')
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_Textarea, {
                  class: "full-width",
                  rows: "4",
                  modelValue: _ctx.historyData.HistoryItemText,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.historyData.HistoryItemText) = $event)),
                  autoResize: true
                }, null, 8, ["modelValue"])
              ]),
              _createVNode(_component_AsFilesSelector, {
                files: _ctx.historyData,
                onUpdate: _cache[4] || (_cache[4] = (files) => (_ctx.historyData.Files = files))
              }, null, 8, ["files"])
            ]),
            _: 1
          }, 8, ["header"])
        ]))
      : _createCommentVNode("", true)
  ]))
}