import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c22c016a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-item" }
const _hoisted_2 = { class: "form-control-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Dropdown, {
        class: "form-control",
        modelValue: _ctx.value_,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value_) = $event)),
          _ctx.update
        ],
        filter: true,
        options: _ctx.options_,
        optionLabel: _ctx.optionLabel,
        optionValue: _ctx.optionValue,
        placeholder: _ctx.placeholder,
        showClear: _ctx.clearIcon,
        disabled: _ctx.disabled,
        onBeforeShow: _ctx.filterOptions,
        onHide: _ctx.resetOptions
      }, null, 8, ["modelValue", "options", "optionLabel", "optionValue", "placeholder", "showClear", "disabled", "onBeforeShow", "onHide", "onUpdate:modelValue"]),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}