
  import { Options, Vue } from 'vue-class-component';
  import AsBlock from '../shared/as-block-component.vue';
  import { CaseModule, CaseStore } from '@/store/case';
  import {
    CaseClinicalInformationDto,
    CasePersonalDataDto,
    CaseResponsibilityDto,
    CaseUserDto,
    DataHistoryChange,
    GenderType,
    GetCaseQuery,
    GetUsersQuery,
    UpdateCaseClinicalInformationCommand,
    UpdateCasePersonalDataCommand,
    UpdateCaseResponsibilityCommand,
  } from '@/apiclient/apiclient_generated';
  import AsInput from '../shared/as-input.vue';
  import AsDatepicker from '../shared/as-datepicker.vue';
  import CasePersonalData from './shared/CasePersonalData.vue';
  import AsEditBlock from '../shared/as-edit-block.vue';
  import EditBlockParams from '@/types/EditBlockParams';
  import AsDropdown from '../shared/as-dropdown.vue';
  import CaseResponsibility from './shared/CaseResponsibility.vue';
  import { UserManagementModule, UserManagementStore } from '@/store/userManagement';
  import CaseClinicalInformation from './shared/CaseClinicalInformation.vue';
  import emitter from '@/services/eventBus';
  import Events from '@/types/Events';
  import { PublicConstants } from '@/types/PublicConstants';
  import { isEmailValid } from '@/services/formFunctions';
  import AccessRightsService from '@/services/accessRightsService';
  import { CaseReadWriteScope } from '@/types/CaseReadWriteScope';
  import { formatDate, formatStringDate } from '@/services/dateFunctions';

  @Options({
    components: {
      AsBlock,
      AsInput,
      AsDropdown,
      AsDatepicker,
      CasePersonalData,
      AsEditBlock,
      CaseResponsibility,
      CaseClinicalInformation,
    },
  })
  export default class CaseData extends Vue {
    private caseStore: CaseModule = CaseStore;
    private usersStore: UserManagementModule = UserManagementStore;

    pc = PublicConstants;
    selectedChangeDate = {} as DataHistoryChange;
    personalDataBackup = {} as CasePersonalDataDto;
    responsibilityBackup = {} as CasePersonalDataDto;
    clinicalInformationBackup = {
      entity: {},
      historyInputs: [],
      hospitalizationHistory: [],
    };

    formatDateWithTime = formatDate;

    caseScope = CaseReadWriteScope;

    genderOptions = [GenderType.Unknown, GenderType.Male, GenderType.Female];

    personalDataEditParams = {
      isEditing: false,
    } as EditBlockParams;
    caseResponsibilityParams = {
      isEditing: false,
    } as EditBlockParams;
    clinicalInformationParams = {
      isEditing: false,
    } as EditBlockParams;

    get caseId(): any {
      return this.$route.params.id;
    }

    get currentCase() {
      return this.caseStore.currentCase;
    }

    get caseChanges() {
      return this.caseStore.caseChanges;
    }

    getChangesDateLabel(changeDate: DataHistoryChange) {
      if (changeDate.periodEnd === null) {
        return this.$t('Latest version');
      }

      return `${this.formatDateWithTime(changeDate.periodEnd)} - ${this.getUserFullName(changeDate.changedBy)}`;
    }

    get today() {
      return new Date();
    }

    get isPersonalDataValid() {
      return isEmailValid(this.personalData.email);
    }

    get personalData() {
      return this.currentCase.personalData;
    }
    get caseResponsibility() {
      return this.currentCase.caseResponsibility;
    }
    get caseClinicalInformation() {
      return this.currentCase.caseClinicalInformation;
    }

    processEditPersonalDataEnable() {
      this.personalDataBackup = { ...this.personalData } as CasePersonalDataDto;
    }
    processEditPersonalDataCancel() {
      this.caseStore.setCasePersonalData(this.personalDataBackup);
    }
    async processEditPersonalDataSubmit() {
      const command = {
        personalData: this.personalData,
      } as UpdateCasePersonalDataCommand;
      command.personalData.caseId ??= this.caseId;

      const isSuccess = await this.caseStore.updateCasePersonalData(command);

      this.personalDataEditParams.isEditing = !isSuccess;

      await this.loadCaseHistoryChanges();
    }

    processEditResponsibilityEnable() {
      this.responsibilityBackup = { ...this.caseResponsibility } as CaseResponsibilityDto;
    }
    processEditResponsibilityCancel() {
      this.caseStore.setCaseResponsibility(this.responsibilityBackup);
    }
    async processEditResponsibilitySubmit() {
      const command = {
        caseResponsibility: this.caseResponsibility,
      } as UpdateCaseResponsibilityCommand;
      command.caseResponsibility.caseId ??= this.caseId;

      const isSuccess = await this.caseStore.updateCaseResponsibilityAsync(command);

      this.caseResponsibilityParams.isEditing = !isSuccess;

      await this.loadCaseHistoryChanges();
    }

    processClinicalInformationEnable() {
      this.clinicalInformationBackup = {
        entity: { ...this.caseClinicalInformation },
        historyInputs: [...this.caseClinicalInformation.historyInputs],
        hospitalizationHistory: [...this.caseClinicalInformation.hospitalizationHistory],
      };
    }
    processClinicalInformationCancel() {
      const restoredEntity = this.clinicalInformationBackup.entity as CaseClinicalInformationDto;
      restoredEntity.historyInputs = this.clinicalInformationBackup.historyInputs;
      restoredEntity.hospitalizationHistory = this.clinicalInformationBackup.hospitalizationHistory;

      this.caseStore.setCaseClinicalInformation(restoredEntity);
    }
    async processClinicalInformationSubmit() {
      const command = {
        caseClinicalInformation: { ...this.caseClinicalInformation },
      } as UpdateCaseClinicalInformationCommand;

      command.caseClinicalInformation.caseId ??= this.caseId;
      command.caseClinicalInformation.historyInputs = command.caseClinicalInformation.historyInputs.filter(
        (t) => t.date || t.historyInputTypeId
      );
      command.caseClinicalInformation.hospitalizationHistory = command.caseClinicalInformation.hospitalizationHistory.filter(
        (t) => t.hospitalizationTypeId || t.fromDate || t.toDate
      );

      const isSuccess = await this.caseStore.updateCaseClinicalInformationAsync(command);

      this.clinicalInformationParams.isEditing = !isSuccess;

      await this.loadCaseHistoryChanges();

      if (isSuccess) {
        emitter.$emit(Events.case.useLaboratoryUpdated);
      }
    }

    async mounted() {
      await this.usersStore.getUsers({ roles: [] } as GetUsersQuery);

      if (localStorage.getItem(PublicConstants.refreshTabContent)) {
        Promise.all([this.loadCaseHistoryChanges()]).then(async () => {
          const query = {
            caseId: this.caseId,
            periodStart: null,
            periodEnd: null,
          } as GetCaseQuery;

          await this.caseStore.getCase(query);

          localStorage.removeItem(PublicConstants.refreshTabContent);
        });
      } else {
        await this.loadCaseHistoryChanges();
      }
    }

    private async loadCaseHistoryChanges() {
      await this.caseStore.getCaseDataHistoryChangesAsync(this.caseId);

      this.selectedChangeDate = this.caseChanges[0];
    }

    async loadSpecificCaseState() {
      const query = {
        caseId: this.caseId,
        periodStart: this.selectedChangeDate.periodStart,
        periodEnd: this.selectedChangeDate.periodEnd,
      } as GetCaseQuery;

      await this.caseStore.getCase(query);
    }

    get isSpecificCaseStateSelected() {
      return this.selectedChangeDate.periodEnd;
    }

    private get allAppUsers() {
      return this.usersStore.users;
    }

    private get users() {
      return this.caseStore.caseUsers;
    }

    private get filteredCaseUsers() {
      return this.users.caseUsers.filter((t: CaseUserDto) => !t.isDeleted);
    }

    private getUserFullName(userId) {
      return this.allAppUsers[userId]?.fullName;
    }

    get caseUsersWithRights() {
      const result = this.filteredCaseUsers.map((t) => `${this.getUserFullName(t.userId)} - ${t.caseRole}`).join(' | ');

      return result;
    }

    canRead(caseScope: CaseReadWriteScope) {
      return AccessRightsService.canUserRead(caseScope);
    }

    canWrite(caseScope: CaseReadWriteScope, entityCreator, createdDate, modifiedDate) {
      return AccessRightsService.canUserWrite(caseScope, entityCreator, modifiedDate || createdDate);
    }
  }
