
  import InputText from 'primevue/inputtext';
  import { Options, Vue } from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';

  @Options({
    components: {
      InputText,
    },
  })
  export default class AsNumber extends Vue {
    @Prop() label;
    @Prop() value: number;
    @Prop() placeholder: string;
    @Prop() disabled;
    @Prop() minFractionDigits;

    get value_() {
      return this.value;
    }

    update(value) {
      this.$emit('update', value);
    }

    get locale() {
      return this.$i18n.locale;
    }
  }
