import axios from 'axios';
import { UserStore } from '@/store/user';
import { LayoutStore } from './store/layout';

export function setupAxios(context) {
  axios.defaults.withCredentials = true;

  axios.interceptors.request.use(
    async (request: any) => {
      LayoutStore.setSpinnerState(true);

      context.$Progress.start();

      request.headers.Authorization = `Bearer ${localStorage.getItem('_token')}`;

      return request;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    (response: any) => {
      context.$Progress.finish();

      LayoutStore.setSpinnerState(false);

      resetRetryCounter();

      return response;
    },

    async (error) => {
      LayoutStore.setSpinnerState(false);

      const originalConfig = error.config;
      if ((originalConfig.url !== '/auth/login' || originalConfig.url !== 'auth/logout') && error.response) {
        if (error.response.status === 401) {
          const retryCount = getRetryCount();

          if (!retryCount || retryCount < 10) {
            incrementRetryCount();

            await UserStore.refresh();
            return axios(error.config);
          } else {
            resetRetryCounter();

            UserStore.logout();
            UserStore.loginWithReturnUrl();
          }
        } else {
          console.log(error);
        }
      }

      return Promise.reject(error);
    }
  );
}

const _asRetryCount = '_as_retry_count';
function getRetryCount(): number {
  const value = localStorage.getItem(_asRetryCount);

  if (!value) {
    return initRetryCounter();
  }

  return +value;
}
function initRetryCounter() {
  localStorage.setItem(_asRetryCount, `${0}`);
  return 0;
}
function resetRetryCounter() {
  localStorage.removeItem(_asRetryCount);
}
function incrementRetryCount() {
  let count = getRetryCount();
  if (!count) {
    count = 0;
  }
  count++;

  localStorage.setItem(_asRetryCount, `${count}`);
}
