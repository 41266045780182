
  import {
    CaseLaboratorySettingsDto,
    LaboratoryTestResultDto,
    LookUpValueType,
    SetCaseLaboratorySettingsCommand,
  } from '@/apiclient/apiclient_generated';
  import AsBlock from '@/components/shared/as-block-component.vue';
  import AsButton from '@/components/shared/as-button.vue';
  import AsDatepicker from '@/components/shared/as-datepicker.vue';
  import AsDropdown from '@/components/shared/as-dropdown.vue';
  import AsEditBlock from '@/components/shared/as-edit-block.vue';
  import AsFilesSelector from '@/components/shared/as-files-selector.vue';
  import AsInput from '@/components/shared/as-input.vue';
  import AsModal from '@/components/shared/as-modal.vue';
  import AsMultiSelect from '@/components/shared/as-multi-select.vue';
  import AsReadWriteBlock from '@/components/shared/as-read-write.vue';
  import { formatDateWithoutTime, formatStringDate } from '@/services/dateFunctions';
  import { isFormReadOnly } from '@/services/formFunctions';
  import { LaboratoryModule, LaboratoryStore } from '@/store/laboratory';
  import { ButtonTypes } from '@/types/ButtonTypes';
  import EditBlockParams from '@/types/EditBlockParams';
  import { Options, Vue } from 'vue-class-component';
  import CaseLaboratoryModal from './CaseLaboratoryModal.vue';
  import { NotificationService } from '@/services/notification.service';
  import { FilesModule, FilesStore } from '@/store/files';
  import VuePdfApp from 'vue3-pdf-app';
  import { CaseModule, CaseStore } from '@/store/case';
  import AsTextWithLabel from '@/components/shared/as-textWithLabel.vue';
  import { LookUpValuesModule, LookUpValuesStore } from '@/store/lookUpValues';
  import { CaseReadWriteScope } from '@/types/CaseReadWriteScope';
  import AccessRightsService from '@/services/accessRightsService';

  @Options({
    components: {
      AsBlock,
      AsButton,
      AsEditBlock,
      AsReadWriteBlock,
      AsMultiSelect,
      AsDropdown,
      AsDatepicker,
      AsModal,
      AsFilesSelector,
      AsInput,
      CaseLaboratoryModal,
      VuePdfApp,
      AsTextWithLabel,
    },
  })
  export default class CaseLaboratory extends Vue {
    private labsStore: LaboratoryModule = LaboratoryStore;
    private filesStore: FilesModule = FilesStore;
    private caseStore: CaseModule = CaseStore;
    private lookUpStore: LookUpValuesModule = LookUpValuesStore;

    caseScope = CaseReadWriteScope;

    formatDate = formatDateWithoutTime;
    formatStringDate = formatStringDate;
    bTypes = ButtonTypes;
    lvTypes = LookUpValueType;

    previewFile = { name: '', arrayBuffer: null };
    backupData = {
      labData: {} as CaseLaboratorySettingsDto,
    };
    previewDocument = false;
    openTestResultModal = false;

    currentTestResultId = null;
    settingsConfig = {
      isEditing: false,
    } as EditBlockParams;

    monthsIntervalOptions = [];
    private monthsIntervalOptions_ = [
      {
        value: 0,
        label: 'None',
      },

      {
        value: 1,
        label: 'Month',
      },

      {
        value: 2,
        label: 'Months',
      },

      {
        value: 3,
        label: 'Months',
      },
      {
        value: 6,
        label: 'Months',
      },
    ];

    async mounted() {
      this.monthsIntervalOptions = this.monthsIntervalOptions_.map((t) => {
        const labelPrefix = t.value ? `${t.value} ` : '';

        return {
          value: t.value,
          label: `${labelPrefix}${this.$t(`lookUpValues.${t.label}`)}`,
        };
      });

      await this.labsStore.getCaseLaboratoryDataAsync(this.caseId);
    }

    unmounted() {
      this.labsStore.resetCaseLaboratoryData();
    }

    get caseId(): any {
      return this.$route.params.id;
    }

    get isSettingsFormReadOnly() {
      return isFormReadOnly(true, this.settingsConfig.isEditing);
    }

    get labData() {
      return this.labsStore.caseLaboratoryData;
    }

    get labSettings() {
      return this.labData.laboratorySettings;
    }

    get labTests() {
      return this.labData.testResults;
    }

    get isCaseDeleted() {
      return this.caseStore.isCaseDeleted;
    }

    resetPreviewFile() {
      this.previewFile = {
        name: '',
        arrayBuffer: null,
      };
    }
    closePreviewDocument() {
      this.resetPreviewFile();
      this.previewDocument = false;
    }

    async processPreviewResultClick(item: LaboratoryTestResultDto) {
      this.resetPreviewFile();
      const file = await this.filesStore.downloadCaseFile(item.testResultFile.id);

      this.previewFile.name = file.fileName;

      const fileLoadHandler = (arrayBuffer) => {
        this.previewFile.arrayBuffer = arrayBuffer;
        this.previewDocument = !this.previewDocument;
      };

      var fileReader = new FileReader();
      fileReader.onload = function (event) {
        fileLoadHandler(event.target.result);
      };

      fileReader.readAsArrayBuffer(file.data);
    }

    removeLabResult(event, labResultId) {
      this.$confirm.require({
        target: event.currentTarget,
        message: 'Are you sure you want to delete selected results?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const params = { caseId: this.caseId, testResultId: labResultId };

          await this.labsStore.deleteLabResultAsync(params);
          NotificationService.NotifySuccess(this.$t('notifications.testResultsWereRemoved'));
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    }

    initTestResultModal() {
      this.labsStore.setCurrentTestResultItem({ caseLaboratorySettingsId: this.labSettings.id } as LaboratoryTestResultDto);
      this.openTestResultModal = true;
    }

    editLabResult(item: LaboratoryTestResultDto) {
      const newItem: LaboratoryTestResultDto = JSON.parse(JSON.stringify(item));

      const query = { group: this.lvTypes.LaboratoryTestResult, id: newItem.testResultTypeId };
      newItem.testResultType = this.lookUpStore.getLookUpValue(query);

      this.labsStore.setCurrentTestResultItem(newItem);
      this.openTestResultModal = true;
    }

    getIntervalReadonlyName(monthsCounter) {
      if (monthsCounter) {
        const monthKey = monthsCounter > 1 ? 'Months' : 'Month';

        return `${monthsCounter} ${this.$t(`lookUpValues.${monthKey}`)}`;
      } else {
        return this.$t('lookUpValues.None');
      }
    }

    getTestTypesNames(ids: string[]) {
      let result = '';

      (ids || []).forEach((id) => {
        const testType = this.getLookUpValue(this.lvTypes.LaboratoryResultTestType, id);

        if (testType) {
          result += `${testType}; `;
        }
      });

      return result;
    }

    processSettingsEditEnable() {
      this.backupData.labData = { ...this.labData } as CaseLaboratorySettingsDto;
    }
    async processSettingsSubmit() {
      const command = {
        caseId: this.caseId,
        firstTestDate: this.labSettings.firstTestDate,
        monthsInterval: this.labSettings.monthsInterval,
        testTypes: this.labSettings.testTypes,
      } as SetCaseLaboratorySettingsCommand;

      await this.labsStore.setCaseLaboratorySettingsAsync(command);

      NotificationService.NotifySuccess(this.$t('notifications.caseLabDataWasUpdated'));

      this.settingsConfig.isEditing = false;
    }

    processSettingsCancel() {
      this.labsStore.setLaboratorySettings(this.backupData.labData);
    }

    processCloseModal() {
      this.currentTestResultId = null;
      this.openTestResultModal = false;
      this.labsStore.setCurrentTestResultItem({} as LaboratoryTestResultDto);
    }

    get testResultLookUpValues() {
      return this.lookUpStore.testResultLookUpValues;
    }

    getLookUpValue(group: LookUpValueType, id) {
      return this.lookUpStore.getLookUpValueName({ group, id });
    }

    getLookUpOptions(groupType: LookUpValueType) {
      return this.lookUpStore.lookUpOptions(groupType);
    }

    getTestStyle(lookUpValueId) {
      return `background-color: ${this.getLookUpValue(this.lvTypes.LaboratoryTestResult, lookUpValueId)};`;
    }

    canRead(caseScope: CaseReadWriteScope) {
      return AccessRightsService.canUserRead(caseScope);
    }

    canWrite(caseScope: CaseReadWriteScope) {
      return AccessRightsService.canUserWrite(caseScope, '', '');
    }
  }
