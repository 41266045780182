
  import InputText from 'primevue/inputtext';
  import { Options, Vue } from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';

  @Options({
    components: {
      InputText,
    },
  })
  export default class AsTags extends Vue {
    @Prop() label;
    @Prop() value: string[];
    @Prop() placeholder: string;

    get value_() {
      return this.value;
    }

    processUpdate($event) {
      this.$emit('update', $event);
    }
  }
