
  import { ApplicationRoleType, GroupMeetingDto, GroupDetailsDto, MeetingStatus, LookUpValueType } from '@/apiclient/apiclient_generated';
  import Routes from '@/types/Routes';
  import { Options, Vue } from 'vue-class-component';
  import AsUserWithRole from '../shared/as-userWithRole.vue';
  import { UserManagementModule, UserManagementStore } from '@/store/userManagement';
  import AsBlock from '../shared/as-block-component.vue';
  import AsTextWithLabel from '../shared/as-textWithLabel.vue';
  import { GroupsModule, GroupsStore } from '@/store/groups';
  import { ButtonTypes } from '@/types/ButtonTypes';
  import AsButton from '../shared/as-button.vue';
  import AsLabeledBlock from '../shared/as-labeled-block.vue';
  import AsDropdown from '../shared/as-dropdown.vue';
  import AsInput from '../shared/as-input.vue';
  import { formatStringDate } from '@/services/dateFunctions';
  import AsEditBlock from '../shared/as-edit-block.vue';
  import EditBlockParams from '@/types/EditBlockParams';
  import AsReadWriteBlock from '../shared/as-read-write.vue';
  import AsDatepicker from '../shared/as-datepicker.vue';
  import { NotificationService } from '@/services/notification.service';
  import { LookUpValuesModule, LookUpValuesStore } from '@/store/lookUpValues';

  @Options({
    components: {
      AsUserWithRole,
      AsBlock,
      AsTextWithLabel,
      AsButton,
      AsLabeledBlock,
      AsDropdown,
      AsInput,
      AsEditBlock,
      AsReadWriteBlock,
      AsDatepicker,
    },
  })
  export default class GroupMeeting extends Vue {
    private groupsStore: GroupsModule = GroupsStore;
    private usersStore: UserManagementModule = UserManagementStore;
    private lookUpStore: LookUpValuesModule = LookUpValuesStore;

    lvTypes = LookUpValueType;
    bTypes = ButtonTypes;

    formatDate = formatStringDate;
    applicationRoleType = ApplicationRoleType;
    newMeeting: GroupMeetingDto = null;
    groupBackup = {} as GroupDetailsDto;
    meetingMemberOptions = [MeetingStatus.Excused, MeetingStatus.NotExcused];
    isLoading = false;
    groupEditParams = {
      isEditing: false,
    } as EditBlockParams;

    async mounted() {
      await this.groupsStore.getGroupMeetingDetailsAsync({ groupId: this.groupId, meetingId: this.meetingId });
    }

    unmounted(): void {
      // clean current meeting from store
      this.groupsStore.setGroupMeeting({} as GroupMeetingDto);
    }

    get groupId() {
      return this.$route.params.id;
    }

    get meetingId() {
      return this.$route.params.meetingId;
    }
    get groupMeeting() {
      return this.groupsStore.groupMeeting;
    }

    navigateToGroup() {
      this.$router.push({ name: Routes.group.name, params: { id: this.groupId } });
    }

    get isFormValid() {
      return !this.isLoading && this.groupMeeting.meetingDate && this.groupMeeting.meetingTypeId;
    }

    async updateGroupMeeting() {
      this.isLoading = true;
      await this.groupsStore.updateGroupMeetingAsync(this.groupMeeting);

      NotificationService.NotifySuccess(this.$t('group.meetingUpdatePopup'));
      this.isLoading = false;
    }

    async cancelGroupMeeting(event) {
      this.$confirm.require({
        target: event.currentTarget,
        message: this.$t('group.cancelMeetingWarn'),
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          await this.groupsStore.cancelGroupMeetingAsync({ groupId: this.groupMeeting.groupId, meetingId: this.meetingId });
          this.navigateToGroup();
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    }

    openCaseDetails(caseId) {
      this.$router.push({ name: Routes.case.name, params: { id: caseId } });
    }

    get isMeetingActive() {
      return this.groupMeeting.id && !this.groupMeeting.isDeleted;
    }

    getLookUpOptions(groupType: LookUpValueType) {
      return this.lookUpStore.lookUpOptions(groupType);
    }
  }
