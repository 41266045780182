import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "label" }
const _hoisted_2 = {
  key: 1,
  class: "row-wrapper-space-between"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!
  const _component_AsButton = _resolveComponent("AsButton")!
  const _component_AsBlock = _resolveComponent("AsBlock")!
  const _component_LookUpValue = _resolveComponent("LookUpValue")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AsBlock, { class: "row-wrapper-space-between" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('lookUpValues.title')), 1),
        (!_ctx.showNewForm)
          ? (_openBlock(), _createBlock(_component_AsButton, {
              key: 0,
              type: _ctx.bTypes.Primary,
              label: _ctx.$t('AddNew'),
              onClick: _ctx.initNewForm
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_fa, { icon: "icon fa-plus" })
              ]),
              _: 1
            }, 8, ["type", "label", "onClick"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_AsButton, {
                type: _ctx.bTypes.Primary,
                disabled: !_ctx.isModelValid,
                label: _ctx.$t('Create'),
                onClick: _ctx.createLookUpValue
              }, null, 8, ["type", "disabled", "label", "onClick"]),
              _createVNode(_component_AsButton, {
                type: _ctx.bTypes.Outlined,
                label: _ctx.$t('cancel'),
                onClick: _ctx.cancelNewForm
              }, null, 8, ["type", "label", "onClick"])
            ]))
      ]),
      _: 1
    }),
    (_ctx.showNewForm)
      ? (_openBlock(), _createBlock(_component_AsBlock, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_LookUpValue, {
              class: "mb-4",
              lookUpValue: _ctx.newLookUpValue
            }, null, 8, ["lookUpValue"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}