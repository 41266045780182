
  import { Options, Vue } from 'vue-class-component';
  import Case from '@/components/cases/Case.vue';
  import {
    ApplicationRoleType,
    CaseDisplayType,
    CaseDto,
    CaseFilters,
    FilterOfCaseDisplayType,
    GetCasesQuery,
    GetUsersQuery,
  } from '@/apiclient/apiclient_generated';
  import NewCase from '../new-case/NewCase.vue';
  import { UserManagementModule, UserManagementStore } from '@/store/userManagement';
  import AsBlock from '../shared/as-block-component.vue';
  import { CaseModule, CaseStore } from '@/store/case';
  import { GroupsModule, GroupsStore } from '@/store/groups';
  import AsDropdown from '../shared/as-dropdown.vue';
  import AsMultiSelect from '../shared/as-multi-select.vue';
  import AsInput from '../shared/as-input.vue';
  import AccessRightsService from '@/services/accessRightsService';
  import { CaseReadWriteScope } from '@/types/CaseReadWriteScope';

  @Options({
    components: {
      NewCase,
      Case,
      AsBlock,
      AsDropdown,
      AsMultiSelect,
      AsInput,
    },
  })
  export default class Cases extends Vue {
    private caseStore: CaseModule = CaseStore;
    private usersStore: UserManagementModule = UserManagementStore;
    private groupsStore: GroupsModule = GroupsStore;

    isNewCaseFormEnabled = false;

    search = {
      value: '',
      searchInArchive: false,
    };

    get filteredCases() {
      const _cases = this.search.searchInArchive ? this.allCases : this.activeCases;

      return this.search.value
        ? _cases.filter(
            (t) => this.includes(t.caseData.caseNumber, this.search.value) || this.includes(t.caseData.caseNumber, this.search.value)
          )
        : _cases;
    }

    private includes(source: string, pattern: string) {
      return source?.toLowerCase().includes(pattern?.toLowerCase());
    }

    get allCases() {
      return this.caseStore.sortedCases;
    }

    get activeCases() {
      return this.allCases.filter((t) => !t.caseData.isDeleted);
    }

    allItem = { label: '', value: 'all' };
    displayFilter = '';

    selectedYears = [];
    selectedPrograms = [];
    selectedGroups = [];
    caseFilters = {} as CaseFilters;
    caseNumberFilter = '';

    async mounted() {
      this.allItem.label = await this.$t('all');

      // await this.usersStore.getUsers({
      //   roles: [ApplicationRoleType.CaseManager],
      // } as GetUsersQuery);

      await this.groupsStore.getGroupsLightInfoAsync();

      const caseFilters = await this.caseStore.getCaseFilters();

      caseFilters.displayFilters = caseFilters.displayFilters.map((t) => {
        const filter = {
          label: this.$t(t.label),
          value: t.value,
          isSelected: t.isSelected,
        } as FilterOfCaseDisplayType;

        return filter;
      });

      this.caseFilters = caseFilters;

      this.selectedYears = this.caseFilters.years.filter((t) => t.isSelected).map((t) => t.value);
      this.selectedPrograms = this.caseFilters.programs.filter((t) => t.isSelected).map((t) => t.value);
      this.selectedGroups = this.caseFilters.groups.filter((t) => t.isSelected).map((t) => t.value);

      const selectedDisplayFilter = this.caseFilters.displayFilters.filter((t) => t.isSelected);
      this.displayFilter = selectedDisplayFilter?.length ? selectedDisplayFilter[0].value : CaseDisplayType.MyCases;

      await this.getFilteredCases();
    }

    async getFilteredCases() {
      const query = {
        caseNumber: this.caseNumberFilter,
        displayFilter: this.displayFilter,
        years: this.selectedYears,
        programs: this.selectedPrograms,
        groups: this.selectedGroups,
      } as GetCasesQuery;

      await this.caseStore.getCases(query);
    }

    unmounted(): void {
      this.caseStore.setCases([] as CaseDto[]);
      this.caseFilters = {} as CaseFilters;
    }

    processWizardToggle(wizardToggleState) {
      this.isNewCaseFormEnabled = wizardToggleState;
    }

    getSelectedLabel(selectedFilters, caseFilters) {
      return selectedFilters?.length === caseFilters?.length ? 'All' : '';
    }

    get canCreate() {
      return AccessRightsService.canUserWrite(CaseReadWriteScope.NewCase);
    }
  }
