import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-283f0299"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn-with-text" }
const _hoisted_2 = { class: "new-doc-item" }
const _hoisted_3 = { class: "form-item" }
const _hoisted_4 = { class: "right-aligned-items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!
  const _component_AsButton = _resolveComponent("AsButton")!
  const _component_AsBlock = _resolveComponent("AsBlock")!
  const _component_AsFilesSelector = _resolveComponent("AsFilesSelector")!

  return (!_ctx.showNewForm)
    ? (_openBlock(), _createBlock(_component_AsBlock, {
        key: 0,
        class: "new-doc",
        header: _ctx.$t('library.title')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_AsButton, {
              type: _ctx.bTypes.Primary,
              label: _ctx.$t('AddNew'),
              onClick: _ctx.initNewForm
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_fa, { icon: "icon fa-plus" })
              ]),
              _: 1
            }, 8, ["type", "label", "onClick"])
          ]),
          _createElementVNode("div", {
            class: "new-btn-icon",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.initNewForm && _ctx.initNewForm(...args)))
          }, [
            _createVNode(_component_fa, { icon: "icon fa-plus" }),
            _createElementVNode("div", null, _toDisplayString(_ctx.$t('AddNew')), 1)
          ])
        ]),
        _: 1
      }, 8, ["header"]))
    : (_openBlock(), _createBlock(_component_AsBlock, { key: 1 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_AsFilesSelector, {
                files: _ctx.command.files,
                onUpdate: _cache[1] || (_cache[1] = (files) => (_ctx.command.files = files))
              }, null, 8, ["files"]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_AsButton, {
                  type: _ctx.bTypes.Primary,
                  label: 'Create',
                  disabled: !_ctx.isFormValid,
                  onClick: _ctx.createDocumentAsync
                }, null, 8, ["type", "disabled", "onClick"]),
                _createVNode(_component_AsButton, {
                  type: _ctx.bTypes.Outlined,
                  label: 'Cancel',
                  onClick: _ctx.resetNewForm
                }, null, 8, ["type", "onClick"])
              ])
            ])
          ])
        ]),
        _: 1
      }))
}