
  import { Options, Vue } from 'vue-class-component';
  import { UserManagementModule, UserManagementStore } from '@/store/userManagement';
  import AsBlock from '../shared/as-block-component.vue';
  import { GroupsModule, GroupsStore } from '@/store/groups';
  import GroupListItem from './GroupListItem.vue';
  import { ButtonTypes } from '@/types/ButtonTypes';
  import AsButton from '../shared/as-button.vue';
  import Routes from '@/types/Routes';
  import { GroupDto } from '@/apiclient/apiclient_generated';

  @Options({
    components: {
      AsBlock,
      AsButton,
      GroupListItem,
    },
  })
  export default class Groups extends Vue {
    private groupsStore: GroupsModule = GroupsStore;
    private usersStore: UserManagementModule = UserManagementStore;

    isGroupOpened = false;
    bTypes = ButtonTypes;

    search = {
      value: '',
    };

    get filteredGroups() {
      return this.search.value ? this.groupsStore.groups.filter((t) => this.includes(t.name, this.search.value)) : this.groupsStore.groups;
    }

    private includes(source: string, pattern: string) {
      return source?.toLowerCase().includes(pattern?.toLowerCase());
    }

    async mounted() {
      await this.groupsStore.getGroupsAsync();
    }

    unmounted(): void {
      this.groupsStore.setGroups([] as GroupDto[]);
    }

    navigateToDetails() {
      this.$router.push({ name: Routes.group.name, params: { id: '' } });
    }
  }
