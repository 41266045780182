
  import { Options, Vue } from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';

  @Options({})
  export default class AsTextWithLabel extends Vue {
    @Prop() label;
    @Prop() value;
    @Prop() valueClass?;

    @Prop() useSlot?;
  }
