import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-106e9e17"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login-container" }
const _hoisted_2 = { class: "login" }
const _hoisted_3 = { class: "login-body" }
const _hoisted_4 = {
  key: 0,
  class: "error-message"
}
const _hoisted_5 = { class: "submit-form-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_AsButton = _resolveComponent("AsButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      class: "edit-form",
      onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.showFormError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('login.error')), 1))
            : _createCommentVNode("", true),
          _createVNode(_component_InputText, {
            class: "form-control",
            disabled: _ctx.isLoading,
            type: 'text',
            placeholder: _ctx.$t('login.email'),
            modelValue: _ctx.email,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
            onKeyup: _withKeys(_ctx.login, ["enter"])
          }, null, 8, ["disabled", "placeholder", "modelValue", "onKeyup"]),
          _createVNode(_component_InputText, {
            class: "form-control",
            disabled: _ctx.isLoading,
            type: 'password',
            placeholder: _ctx.$t('login.password'),
            modelValue: _ctx.password,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
            onKeyup: _withKeys(_ctx.login, ["enter"])
          }, null, 8, ["disabled", "placeholder", "modelValue", "onKeyup"]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_AsButton, {
              disabled: !_ctx.isFormValid,
              type: _ctx.buttonTypes.Primary,
              label: _ctx.$t('login.submit'),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.login()))
            }, null, 8, ["disabled", "type", "label"])
          ])
        ])
      ])
    ], 32)
  ]))
}