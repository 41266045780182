
  import { setupI18n } from '@/services/translations';
  import { UserModule, UserStore } from '@/store/user';
  import { UserManagementModule, UserManagementStore } from '@/store/userManagement';
  import { Options, Vue } from 'vue-class-component';
  import { nextTick } from 'vue';

  @Options({})
  export default class Header extends Vue {
    private userStore: UserModule = UserStore;
    private usersStore: UserManagementModule = UserManagementStore;

    selectedLang = '';
    localeKey = '';

    private SUPPORT_LOCALES = ['en', 'de'];

    async mounted() {
      this.selectedLang = this.getSavedLocaleOrDefault();

      await this.loadLocaleMessages(this.selectedLang);
    }

    get en() {
      return this.SUPPORT_LOCALES[0];
    }

    get de() {
      return this.SUPPORT_LOCALES[1];
    }

    async loadLocaleMessages(locale) {
      localStorage.setItem(this.localeKey, locale);

      setupI18n({ locale: this.de, legacy: true });

      const messages = await import(`@/assets/i18n/${locale}.json`);

      const i18n: any = this.$i18n;

      i18n.locale = locale;
      i18n.setLocaleMessage(locale, messages);

      return nextTick();
    }

    private getSavedLocaleOrDefault() {
      const savedLocale = localStorage.getItem(this.localeKey);
      return savedLocale ?? this.de;
    }
  }
