import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "case-board-item" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "case-info" }
const _hoisted_4 = { class: "case-number" }
const _hoisted_5 = { class: "additional-info" }
const _hoisted_6 = {
  key: 0,
  class: "item"
}
const _hoisted_7 = { style: {"padding":"0 8px"} }
const _hoisted_8 = {
  key: 1,
  class: "item"
}
const _hoisted_9 = {
  key: 2,
  class: "item"
}
const _hoisted_10 = {
  key: 0,
  class: "no-hover"
}
const _hoisted_11 = {
  key: 1,
  class: "details-btn"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!
  const _component_AsUserWithRole = _resolveComponent("AsUserWithRole")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_AsBlock = _resolveComponent("AsBlock")!

  return (_openBlock(), _createBlock(_component_AsBlock, {
    hoverEffect: true,
    onClick: _ctx.navigateToCaseDetails
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_fa, { icon: "icon fa-regular fa-user" }),
              _createElementVNode("div", null, _toDisplayString(_ctx.model.caseData.caseNumber ?? _ctx.model.personalData?.pipelineCaseNumber), 1)
            ]),
            _createVNode(_component_AsUserWithRole, {
              modelValue: _ctx.manager,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.manager) = $event)),
              role: _ctx.applicationRoleType.CaseManager
            }, null, 8, ["modelValue", "role"]),
            _createVNode(_component_AsUserWithRole, {
              modelValue: _ctx.therapist,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.therapist) = $event)),
              role: _ctx.applicationRoleType.Therapist
            }, null, 8, ["modelValue", "role"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.model.personalData?.programId)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_Badge, {
                    severity: "warning",
                    class: "no-hover"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.getLookUpValue(_ctx.lvTypes.Program, _ctx.model.personalData.programId)), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.isLabBadgeAvailable)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  (_ctx.model.nextTestingInfo && _ctx.model.nextTestingInfo.isNextTestOverdue)
                    ? (_openBlock(), _createBlock(_component_Badge, {
                        key: 0,
                        severity: "danger",
                        class: "no-hover"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(_ctx.$t('case.laboratory.NextTestOverdue')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.model.nextTestingInfo && !_ctx.model.nextTestingInfo.isNextTestOverdue && _ctx.model.nextTestingInfo.nextLaboratoryTestDate)
                    ? (_openBlock(), _createBlock(_component_Badge, {
                        key: 1,
                        class: "no-hover"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(`${_ctx.$t('case.laboratory.NextTesting')} ${_ctx.formatStringDate(_ctx.model.nextTestingInfo.nextLaboratoryTestDate)}`), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.model.caseClinicalInformation?.groupId)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_Badge, {
                    severity: "warning",
                    class: "no-hover"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, _toDisplayString(_ctx.getGroupNameOrDefault(_ctx.model.caseClinicalInformation?.groupId)), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.navigateToCaseDetails && _ctx.navigateToCaseDetails(...args))),
              class: "case-details item"
            }, [
              (_ctx.model.caseData.isDeleted)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    (_ctx.model.caseData.isDeleted)
                      ? (_openBlock(), _createBlock(_component_Badge, {
                          key: 0,
                          value: "Deleted",
                          severity: "danger"
                        }))
                      : _createCommentVNode("", true)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t('details')), 1))
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["onClick"]))
}