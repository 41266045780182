
  import InputText from 'primevue/inputtext';
  import { Options, Vue } from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';

  @Options({
    components: {
      InputText,
    },
  })
  export default class AsInput extends Vue {
    @Prop() label;
    @Prop() value: string;
    @Prop() placeholder: string;
    @Prop() disabled;
    @Prop() type;

    update(value) {
      this.$emit('update', value);
    }
  }
