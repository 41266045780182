import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b6756642"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "history-item gray-container" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "metadata" }
const _hoisted_4 = { class: "edit" }
const _hoisted_5 = {
  key: 0,
  class: "meeting-info"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 1,
  class: "save-actions"
}
const _hoisted_8 = {
  key: 0,
  class: "text"
}
const _hoisted_9 = { class: "text-content" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  key: 3,
  class: "files row"
}
const _hoisted_12 = { class: "file-wrapper" }
const _hoisted_13 = { class: "file" }
const _hoisted_14 = { class: "row-wrapper file-content" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "file-name-wrapper" }
const _hoisted_18 = { class: "file-name" }
const _hoisted_19 = { class: "file-uploader" }
const _hoisted_20 = { class: "file-actions" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = ["onClick"]
const _hoisted_23 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_fa = _resolveComponent("fa")!
  const _component_AsButton = _resolveComponent("AsButton")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_AsFilesSelector = _resolveComponent("AsFilesSelector")!
  const _component_ConfirmPopup = _resolveComponent("ConfirmPopup")!
  const _component_vue_pdf_app = _resolveComponent("vue-pdf-app")!
  const _component_AsModal = _resolveComponent("AsModal")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, _toDisplayString(_ctx.getFormattedDate(_ctx.item)) + " - " + _toDisplayString(_ctx.item.createdUserFullName), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.item.parentType === _ctx.parentType.Meeting)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_ctx.item.meetingStatus)
                  ? (_openBlock(), _createBlock(_component_Badge, {
                      key: 0,
                      value: _ctx.item.meetingStatus,
                      severity: "warning"
                    }, null, 8, ["value"]))
                  : _createCommentVNode("", true),
                _createElementVNode("i", {
                  class: "pi pi-link cursor-pointer i-navigate-button",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.navigateToMeetingDetails && _ctx.navigateToMeetingDetails(...args)))
                })
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (!_ctx.isReadOnly && !_ctx.isEditing)
                  ? (_openBlock(), _createBlock(_component_fa, {
                      key: 0,
                      icon: "icon fa-pencil",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.enableEditMode())),
                      class: "cursor-pointer"
                    }))
                  : _createCommentVNode("", true),
                (!_ctx.isReadOnly && _ctx.isEditing)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(_component_AsButton, {
                        type: _ctx.buttonTypes.Success,
                        label: "Update",
                        disabled: !_ctx.isFormValid,
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateHistoryItem()))
                      }, null, 8, ["type", "disabled"]),
                      _createVNode(_component_AsButton, {
                        type: _ctx.buttonTypes.Danger,
                        label: "Cancel",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.cancelEditHistoryItem()))
                      }, null, 8, ["type"])
                    ]))
                  : _createCommentVNode("", true)
              ]))
        ])
      ]),
      (!_ctx.isEditing)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", {
                innerHTML: _ctx.highlightText(_ctx.item.text)
              }, null, 8, _hoisted_10)
            ])
          ]))
        : (_openBlock(), _createBlock(_component_Textarea, {
            key: 1,
            class: "full-width",
            rows: "3",
            modelValue: _ctx.item.text,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.item.text) = $event)),
            autoResize: true
          }, null, 8, ["modelValue"])),
      (_ctx.isEditing)
        ? (_openBlock(), _createBlock(_component_AsFilesSelector, {
            key: 2,
            files: _ctx.command.files,
            onUpdate: _cache[5] || (_cache[5] = (files) => (_ctx.command.files = files))
          }, null, 8, ["files"]))
        : _createCommentVNode("", true),
      (!_ctx.isEditing)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.files, (file) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "column col-sm-12 col-md-6 col-lg-4",
                key: file.id
              }, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      (file.description)
                        ? _withDirectives((_openBlock(), _createElementBlock("i", {
                            key: 0,
                            class: "pi pi-file-pdf",
                            onClick: ($event: any) => (_ctx.processPreviewDocumentClick(file.id))
                          }, null, 8, _hoisted_15)), [
                            [_directive_tooltip, {
                    value: file.description,
                    showDelay: 1000,
                    hideDelay: 300,
                  }]
                          ])
                        : (_openBlock(), _createElementBlock("i", {
                            key: 1,
                            class: "pi pi-file-pdf",
                            onClick: ($event: any) => (_ctx.processPreviewDocumentClick(file.id))
                          }, null, 8, _hoisted_16)),
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, _toDisplayString(file.name), 1),
                        _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.getUserRole(file)), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("i", {
                        class: "pi pi-eye",
                        onClick: ($event: any) => (_ctx.processPreviewDocumentClick(file.id))
                      }, null, 8, _hoisted_21),
                      _createElementVNode("i", {
                        class: "pi pi-download",
                        onClick: ($event: any) => (_ctx.downloadFile(file.id))
                      }, null, 8, _hoisted_22),
                      (_ctx.canWrite(_ctx.caseScope.Documents, file.createdBy, file.created, '') && !_ctx.isReadOnly)
                        ? (_openBlock(), _createElementBlock("i", {
                            key: 0,
                            class: "pi pi-trash",
                            onClick: ($event: any) => (_ctx.deleteFile($event, _ctx.item.id, file.id))
                          }, null, 8, _hoisted_23))
                        : _createCommentVNode("", true)
                    ]),
                    _createVNode(_component_ConfirmPopup)
                  ])
                ])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.previewDocument)
      ? (_openBlock(), _createBlock(_component_AsModal, {
          key: 0,
          showCloseButton: true,
          hideFooter: true,
          open: _ctx.previewDocument,
          onClose: _ctx.closePreviewDocument,
          size: 'large'
        }, {
          header: _withCtx(() => [
            _createElementVNode("h3", null, _toDisplayString(_ctx.previewFile.name), 1)
          ]),
          body: _withCtx(() => [
            _createVNode(_component_vue_pdf_app, {
              style: {"height":"72vh"},
              pdf: _ctx.previewFile.arrayBuffer
            }, null, 8, ["pdf"])
          ]),
          _: 1
        }, 8, ["open", "onClose"]))
      : _createCommentVNode("", true)
  ]))
}