
  import { setupAxios } from './axios-setup';
  import { Options, Vue } from 'vue-class-component';
  import { UserModule, UserStore } from '@/store/user';
  import Routes from './types/Routes';

  @Options({
    components: {},
  })
  export default class App extends Vue {
    private userStore: UserModule = UserStore;

    mounted() {
      setupAxios(this);

      (this as any).$Progress.finish();
    }

    created() {
      //  [App.vue specific] When App.vue is first loaded start the progress bar
      (this as any).$Progress.start();

      //  hook the progress bar to start before we move router-view
      this.$router.beforeEach((to, from, next) => {
        this.manageRouteChanges(from, to);

        //  does the page we want to go to have a meta.progress object
        if (to.meta.progress !== undefined) {
          let meta = to.meta.progress;
          // parse meta tags
          (this as any).$Progress.parseMeta(meta);
        }

        //  start the progress bar
        (this as any).$Progress.start();

        //  continue to next page
        next();
      });

      //  hook the progress bar to finish after we've finished moving router-view
      this.$router.afterEach((to, from) => {
        //  finish the progress bar
        (this as any).$Progress.finish();

        this.userStore.initAuthDataFromLocalStorage();
      });
    }

    isNavigationToCase(from, to) {
      return (from.name === Routes.group.name || from.name === Routes.groupMeeting.name) && to.name === Routes.case.name;
    }

    manageRouteChanges(from, to) {
      if (this.isNavigationToCase(from, to) || to.name === Routes.user.name) {
        return localStorage.setItem(Routes.PreviousUrl, from.path);
      }

      if (from.name === Routes.case.name || from.name === Routes.user.name) {
        localStorage.removeItem(Routes.PreviousUrl);
      }
    }
  }
