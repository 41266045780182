import {
  CreateGroupCommand,
  CreateGroupMeetingCommand,
  GroupDetailsDto,
  GroupLightDto,
  GroupMeetingDetailsDto,
  GroupMeetingDto,
  GroupMemberDto,
  UpdateGroupCommand,
} from './../apiclient/apiclient_generated';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/index';
import { WS_URL as ApiUrl } from '@/config';
import axios from 'axios';
import { GroupDto, GroupsClient } from '@/apiclient/apiclient_generated';
import { NotificationService } from '@/services/notification.service';

@Module({ dynamic: true, namespaced: true, store, name: 'groups' })
export class GroupsModule extends VuexModule {
  private client = new GroupsClient(ApiUrl, axios);

  groups: GroupDto[] = [];
  groupsLightInfo: GroupLightDto[] = [];
  group: GroupDetailsDto = {} as GroupDetailsDto;
  groupMeeting: GroupMeetingDetailsDto = {} as GroupMeetingDetailsDto;

  @Action
  async getGroupsAsync() {
    const groups = await this.client.getGroups();
    this.setGroups(groups);
  }

  @Action
  async updateGroupMeetingAsync(dto: GroupMeetingDetailsDto) {
    const meeting = await this.client.updateGroupMeeting(dto);

    this.setGroupMeeting(meeting);
  }

  @Action
  async cancelGroupMeetingAsync({ groupId, meetingId }) {
    await this.client.cancelGroupMeeting(groupId, meetingId);
  }

  @Action
  async getGroupMeetingDetailsAsync({ groupId, meetingId }) {
    const groupMeeting = await this.client.getGroupMeetingDetails(groupId, meetingId);

    this.setGroupMeeting(groupMeeting);
  }

  @Action
  async getGroupsLightInfoAsync() {
    const groups = await this.client.getGroupsLightInfo();
    this.setGroupsLightInfo(groups);
  }

  @Action
  async createGroupAsync(command: CreateGroupCommand) {
    return await this.client.createGroup(command);
  }

  @Action
  async createGroupMeetingAsync(command: CreateGroupMeetingCommand) {
    const newMeeting = await this.client.createGroupMeeting(command);

    this.addNewMeeting(newMeeting);
  }

  @Action
  async updateGroupAsync(command: UpdateGroupCommand) {
    const result = await this.client.updateGroup(command);

    if (result.isSuccess) {
      this.updateGroup(command);
    } else {
      NotificationService.NotifyErrors(result.errors);
    }

    return result.isSuccess;
  }

  @Action
  async getGroupAsync(id) {
    const group = id ? await this.client.getGroupDetails(id) : ({} as GroupDetailsDto);

    this.setGroup(group);
  }

  @Mutation
  setGroups(groups: GroupDto[]) {
    this.groups = groups;
  }

  @Mutation
  setGroupsLightInfo(groups: GroupLightDto[]) {
    this.groupsLightInfo = groups;
  }

  @Mutation
  addNewMeeting(newMeeting: GroupMeetingDto) {
    if (!this.group.meetings) {
      this.group.meetings = [];
    }

    this.group.meetings.push(newMeeting);
  }

  @Mutation
  setGroupMeeting(groupMeeting: GroupMeetingDetailsDto) {
    this.groupMeeting = groupMeeting;
  }

  @Mutation
  setGroup(group: GroupDetailsDto) {
    this.group = group;
  }

  @Mutation
  updateGroup(command: UpdateGroupCommand) {
    this.group.name = command.groupName;
    this.group.organizerId = command.groupOrganizerId;
  }
}

export const GroupsStore = getModule(GroupsModule);
