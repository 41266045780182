import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-742ff8d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "case-laboratory-result" }
const _hoisted_2 = { class: "row-wrapper row-wrapper-grid-2-2 pb-2" }
const _hoisted_3 = { class: "form-item" }
const _hoisted_4 = { class: "form-item" }
const _hoisted_5 = { class: "row-wrapper row-wrapper-grid-2-2 pb-2" }
const _hoisted_6 = { class: "form-item" }
const _hoisted_7 = { class: "form-item" }
const _hoisted_8 = { class: "row-wrapper row-wrapper-grid-2-2 pb-2" }
const _hoisted_9 = { class: "form-item" }
const _hoisted_10 = { class: "row-wrapper row-wrapper-grid-2-2 pb-2" }
const _hoisted_11 = { class: "form-item" }
const _hoisted_12 = { class: "form-item" }
const _hoisted_13 = { class: "right-aligned-items pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsDatepicker = _resolveComponent("AsDatepicker")!
  const _component_AsDropdown = _resolveComponent("AsDropdown")!
  const _component_AsNumber = _resolveComponent("AsNumber")!
  const _component_AsInput = _resolveComponent("AsInput")!
  const _component_AsLabTestResultDropdown = _resolveComponent("AsLabTestResultDropdown")!
  const _component_AsFilesSelector = _resolveComponent("AsFilesSelector")!
  const _component_AsBlock = _resolveComponent("AsBlock")!
  const _component_AsButton = _resolveComponent("AsButton")!
  const _component_AsModal = _resolveComponent("AsModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.openLabTestResultDetails)
      ? (_openBlock(), _createBlock(_component_AsModal, {
          key: 0,
          open: _ctx.openLabTestResultDetails,
          showCloseButton: true,
          hideFooter: true,
          onClose: _ctx.closeModal
        }, {
          header: _withCtx(() => [
            _createElementVNode("h3", null, _toDisplayString(_ctx.$t('case.laboratory.result.modalTitle')), 1)
          ]),
          body: _withCtx(() => [
            _createVNode(_component_AsBlock, {
              header: _ctx.$t('case.laboratory.result.title')
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_AsDatepicker, {
                      placeholder: _ctx.$t('date'),
                      label: _ctx.$t('date'),
                      value: _ctx.currentTestResult.testDate,
                      enableTimePicker: false,
                      onUpdate: _cache[0] || (_cache[0] = (date) => (_ctx.currentTestResult.testDate = date))
                    }, null, 8, ["placeholder", "label", "value"])
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_AsDropdown, {
                      placeholder: _ctx.$t('case.laboratory.result.doctorPlaceholder'),
                      label: _ctx.$t('case.laboratory.result.doctor'),
                      clearIcon: true,
                      optionValue: 'id',
                      optionLabel: 'value',
                      value: _ctx.currentTestResult.doctorId,
                      options: _ctx.getLookUpOptions(_ctx.lvTypes.LaboratoryDoctor),
                      onUpdate: _cache[1] || (_cache[1] = (value) => (_ctx.currentTestResult.doctorId = value))
                    }, null, 8, ["placeholder", "label", "value", "options"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_AsNumber, {
                      label: _ctx.$t('case.laboratory.result.ggt'),
                      placeholder: _ctx.$t('case.laboratory.result.ggt'),
                      value: _ctx.currentTestResult.ggt,
                      minFractionDigits: 1,
                      onUpdate: _cache[2] || (_cache[2] = (value) => (_ctx.currentTestResult.ggt = value))
                    }, null, 8, ["label", "placeholder", "value"])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_AsInput, {
                      label: _ctx.$t('case.laboratory.result.remarks'),
                      placeholder: _ctx.$t('case.laboratory.result.remarksPlaceholder'),
                      type: "text",
                      value: _ctx.currentTestResult.remarks,
                      onUpdate: _cache[3] || (_cache[3] = (value) => (_ctx.currentTestResult.remarks = value))
                    }, null, 8, ["label", "placeholder", "value"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_AsNumber, {
                      label: _ctx.$t('case.laboratory.result.got'),
                      placeholder: _ctx.$t('case.laboratory.result.got'),
                      value: _ctx.currentTestResult.got,
                      minFractionDigits: 1,
                      onUpdate: _cache[4] || (_cache[4] = (value) => (_ctx.currentTestResult.got = value))
                    }, null, 8, ["label", "placeholder", "value"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_AsNumber, {
                      label: _ctx.$t('case.laboratory.result.gpt'),
                      placeholder: _ctx.$t('case.laboratory.result.gpt'),
                      value: _ctx.currentTestResult.gpt,
                      minFractionDigits: 1,
                      onUpdate: _cache[5] || (_cache[5] = (value) => (_ctx.currentTestResult.gpt = value))
                    }, null, 8, ["label", "placeholder", "value"])
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_AsLabTestResultDropdown, {
                      placeholder: _ctx.$t('case.laboratory.result.result'),
                      label: _ctx.$t('case.laboratory.result.result'),
                      clearIcon: true,
                      value: _ctx.currentTestResult.testResultType,
                      options: _ctx.getLookUpOptions(_ctx.lvTypes.LaboratoryTestResult),
                      onUpdate: _cache[6] || (_cache[6] = (value) => (_ctx.currentTestResult.testResultType = value))
                    }, null, 8, ["placeholder", "label", "value", "options"])
                  ])
                ]),
                _createVNode(_component_AsFilesSelector, {
                  class: "pb-2",
                  singleMode: true,
                  files: _ctx.currentTestFiles,
                  onUpdate: _cache[7] || (_cache[7] = (files) => _ctx.updateSelectedItemFiles(files))
                }, null, 8, ["files"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_AsButton, {
                type: _ctx.bTypes.Primary,
                label: _ctx.currentTestResult.id ? _ctx.$t('update') : _ctx.$t('create'),
                disabled: !_ctx.isFormValid,
                onClick: _ctx.processLabTestDetailsSubmit
              }, null, 8, ["type", "label", "disabled", "onClick"]),
              _createVNode(_component_AsButton, {
                type: _ctx.bTypes.Outlined,
                label: 'Cancel',
                onClick: _ctx.closeModal
              }, null, 8, ["type", "onClick"])
            ])
          ]),
          _: 1
        }, 8, ["open", "onClose"]))
      : _createCommentVNode("", true)
  ]))
}