export default class Events {
  static identity = {
    signIn: 'signIn',
    signOut: 'signOut',
  };

  static window = {
    resize: 'resize',
  };

  static case = {
    wizardInit: 'wizardInit',
    wizardClose: 'wizardClose',
    useLaboratoryUpdated: 'useLaboratoryUpdated',
  };
}
