
  import { Options, Vue } from 'vue-class-component';
  import AsBlock from './shared/as-block-component.vue';

  @Options({
    components: {
      AsBlock,
    },
  })
  export default class Dashboard extends Vue {}
