
  import InputText from 'primevue/inputtext';
  import { Options, Vue } from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';

  @Options({
    components: {
      InputText,
    },
  })
  export default class AsDatepicker extends Vue {
    @Prop() label;
    @Prop() value;
    @Prop() placeholder;
    @Prop() enableTimePicker;
    @Prop() min;
    @Prop() max;
    @Prop() disabled;

    get value_() {
      return this.value;
    }

    update(value) {
      this.$emit('update', value);
    }
  }
