
  import { Options, Vue } from 'vue-class-component';
  import { UserModule, UserStore } from '@/store/user';
  import { GetUsersQuery, LoginUserCommand } from '@/apiclient/apiclient_generated';
  import Events from '@/types/Events';
  import emitter from '@/services/eventBus';
  import { ButtonTypes } from '@/types/ButtonTypes';
  import AsButton from './shared/as-button.vue';
  import AsInput from './shared/as-input.vue';
  import { UserManagementModule, UserManagementStore } from '@/store/userManagement';

  @Options({
    components: {
      AsButton,
      AsInput,
    },
  })
  export default class Login extends Vue {
    private userStore: UserModule = UserStore;
    private usersStore: UserManagementModule = UserManagementStore;

    buttonTypes = ButtonTypes;
    email = '';
    password = '';
    showFormError = false;

    isLoading = false;

    created() {
      this.resetCredentials();
    }

    private resetCredentials() {
      this.email = '';
      this.password = '';
    }

    get loginFormIsValid() {
      return this.email && this.password;
    }

    get isFormValid() {
      return !this.isLoading && this.email && this.password;
    }

    async login() {
      if (!this.loginFormIsValid || this.isLoading) {
        this.showFormError = !this.isFormValid;
        return;
      }

      this.isLoading = true;

      const command = {
        email: this.email,
        password: this.password,
      } as LoginUserCommand;

      const success = await this.userStore.login(command);

      this.isLoading = false;

      if (success) {
        await this.usersStore.getUsers({
          roles: [],
        } as GetUsersQuery);

        emitter.$emit(Events.identity.signIn);

        this.resetCredentials();
      }
    }
  }
