
  import { Options, Vue } from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import AsTextWithLabel from './as-textWithLabel.vue';

  @Options({
    components: {
      AsTextWithLabel,
    },
  })
  export default class AsReadWriteBlock extends Vue {
    @Prop()
    label;

    @Prop()
    value;

    @Prop()
    labelClass;

    @Prop()
    isReadOnly;
  }
