export default class Routes {
  static root = {
    name: 'root',
    path: '/',
  };

  static dashboard = {
    path: '/dashboard',
    name: 'dashboard',
    title: 'header.nav.dashboard.title',
  };

  static cases = {
    path: '/cases',
    name: 'cases',
    title: 'header.nav.cases.title',
  };

  static case = {
    path: '/case/:id',
    name: 'case',
    title: 'header.nav.case.title',
  };

  static groups = {
    path: '/groups',
    name: 'groups',
    title: 'header.nav.groups.title',
  };

  static group = {
    path: '/group/:id?',
    name: 'group',
    title: 'header.nav.group.title',
  };

  static groupMeeting = {
    path: '/group/:id/meeting/:meetingId',
    name: 'group|meeting',
    title: 'header.nav.meeting.title',
  };

  static lookUpValues = {
    path: '/lookUp',
    name: 'lookUp',
    title: 'header.nav.admin.lookUpValuesTitle',
  };

  static library = {
    path: '/library',
    name: 'library',
    title: 'header.nav.library.title',
  };

  static users = {
    path: '/users',
    name: 'users',
    title: 'header.nav.admin.usersTitle',
  };
  static user = {
    path: '/user/:id?',
    name: 'user',
    title: 'header.nav.admin.userTitle',
  };

  static ReturnUrl = 'return-url';
  static PreviousUrl = 'previous-url';
}
