import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fa15535c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-item files-selector" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "files"
}
const _hoisted_6 = { class: "file-item-name" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "file-item-type grid-group-of-three-second" }
const _hoisted_9 = { class: "file-item-type grid-group-of-three-third" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!
  const _component_AsBlock = _resolveComponent("AsBlock")!
  const _component_AsDropdown = _resolveComponent("AsDropdown")!
  const _component_AsInput = _resolveComponent("AsInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isInitialized)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(["dropzone-wrapper", { isDragActive: _ctx.isDragActive }])
          }, [
            _createElementVNode("div", _mergeProps({ class: "dropzone" }, _ctx.getRootProps()), [
              _createElementVNode("div", null, [
                _createElementVNode("input", _normalizeProps(_guardReactiveProps(_ctx.getInputProps())), null, 16),
                (_ctx.isDragActive)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.dropFilesPlaceHolder), 1))
                  : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.selectFilesPlaceHolder), 1))
              ])
            ], 16)
          ], 2),
          (_ctx.filesInternal.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filesInternal, (file, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "file-item grid-group-of-three",
                    key: index
                  }, [
                    _createVNode(_component_AsBlock, { class: "file-item-content grid-group-of-three-first" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_6, _toDisplayString(file.Name), 1),
                        _createElementVNode("div", {
                          class: "delete-file",
                          onClick: ($event: any) => (_ctx.handleClickDeleteFile(index))
                        }, [
                          _createVNode(_component_fa, { icon: "icon fa-trash" })
                        ], 8, _hoisted_7)
                      ]),
                      _: 2
                    }, 1024),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_AsDropdown, {
                        value: file.CategoryId,
                        options: _ctx.fileCategories,
                        optionValue: 'id',
                        optionLabel: 'value',
                        placeholder: _ctx.$t('SelectTypePlaceholder'),
                        onUpdate: (value) => (file.CategoryId = value)
                      }, null, 8, ["value", "options", "placeholder", "onUpdate"])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_AsInput, {
                        value: file.Description,
                        onUpdate: (value) => (file.Description = value),
                        placeholder: _ctx.$t('file.descriptionPlaceholder')
                      }, null, 8, ["value", "onUpdate", "placeholder"])
                    ])
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}