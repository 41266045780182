
  import { Options, Vue } from 'vue-class-component';
  import AsButton from '../shared/as-button.vue';
  import { ButtonTypes } from '@/types/ButtonTypes';
  import AsModal from '../shared/as-modal.vue';
  import { UserManagementModule, UserManagementStore } from '@/store/userManagement';
  import { GenderType } from '@/apiclient/apiclient_generated';
  import AsBlock from '../shared/as-block-component.vue';
  import { Emit } from 'vue-property-decorator';
  import Events from '@/types/Events';
  import AsFilesSelector from '../shared/as-files-selector.vue';
  import { CaseModule, CaseStore } from '@/store/case';
  import AsDropdown from '../shared/as-dropdown.vue';
  import CasePersonalData from '../case/shared/CasePersonalData.vue';
  import { newCreateCaseCommand } from '@/services/entitiesInitializer';
  import FileService from '@/services/file.service';
  import Routes from '@/types/Routes';
  import { UserModule, UserStore } from '@/store/user';
  import moment from 'moment';
  import { isEmailValid } from '@/services/formFunctions';
  import AccessRightsService from '@/services/accessRightsService';
  import { CaseReadWriteScope } from '@/types/CaseReadWriteScope';
  import { NotificationService } from '@/services/notification.service';

  @Options({
    components: {
      AsButton,
      AsModal,
      AsBlock,
      AsFilesSelector,
      AsDropdown: AsDropdown,
      CasePersonalData,
    },
  })
  export default class NewCase extends Vue {
    private usersStore: UserManagementModule = UserManagementStore;
    private userStore: UserModule = UserStore;
    private caseStore: CaseModule = CaseStore;

    bTypes = ButtonTypes;
    showNewCaseForm = false;

    genderOptions = [GenderType.Unknown, GenderType.Male, GenderType.Female];

    isLoading = false;
    newCaseCommand = newCreateCaseCommand();

    get personalData() {
      return this.newCaseCommand.PersonalData;
    }

    get historyData() {
      return this.newCaseCommand.HistoryData;
    }

    get pipelineCaseNumber() {
      return `PL${this.caseNumberDate}${this.caseNumberManagerInitials}`;
    }

    private get caseNumberDate() {
      return moment(new Date()).format('YYMMDD');
    }

    private get caseNumberManagerInitials() {
      const personalData: any = this.personalData;

      const firstName = personalData.firstName;
      const lastName = personalData.lastName;

      const leftPart = this.getConsonants(lastName);
      const rightPart = firstName ? firstName[0] : '';

      const resultNumber = leftPart && rightPart ? `${leftPart}${rightPart}` : 'XXXX';
      return resultNumber.toUpperCase();
    }

    get isFormValid() {
      const caseDataValidationResult = !!this.newCaseCommand.CaseManagerId;

      const pd: any = this.personalData;

      return !this.isLoading && caseDataValidationResult && this.isHistoryDataValid && isEmailValid(pd?.email);
    }

    getCaseManagers() {
      return [...this.usersStore.caseManagers, ...this.usersStore.admins];
    }

    @Emit()
    onWizardInit() {
      return this.showNewCaseForm;
    }

    async initNewCase() {
      this.newCaseCommand = newCreateCaseCommand();

      if (AccessRightsService.canUserWrite(CaseReadWriteScope.NewCase)) {
        this.newCaseCommand.CaseManagerId = +this.userStore.userData.id;
      }

      this.newCaseCommand.PersonalData.PipelineCaseNumber = this.pipelineCaseNumber;

      this.showNewCaseForm = true;
      this.$emit(Events.case.wizardInit, this.showNewCaseForm);
    }

    async createNewCase() {
      try {
        this.isLoading = true;

        const newCaseId = await this.caseStore.createCase(this.newCaseCommand);

        NotificationService.NotifySuccess(this.$t('notifications.caseWasCreated'));

        this.$router.push({ name: Routes.case.name, params: { id: newCaseId } });
      } finally {
        this.isLoading = false;
      }
    }

    generatePipelineCaseNumber() {
      this.newCaseCommand.PersonalData.PipelineCaseNumber = this.pipelineCaseNumber;
    }

    getConsonants(source) {
      if (!source) {
        return source;
      }

      const vowels = ['a', 'e', 'i', 'o', 'u', 'ä', 'ö', 'ü'];

      const str_array = source.replace(/[^a-zA-Z]/g, '').split('');

      // const vowelsFinal = str_array.filter((a) => vowels.includes(a.toLowerCase()));

      const consonants = str_array.filter((a) => !vowels.includes(a.toLowerCase()));

      return consonants[0] && consonants[1] ? `${consonants[0]}${consonants[1]}` : '';
    }

    closeNewCase() {
      this.isLoading = false;
      this.showNewCaseForm = false;
      this.$emit(Events.case.wizardClose, this.showNewCaseForm);
    }

    private get isHistoryDataValid(): boolean {
      return FileService.isFilesFormValid(this.historyData.Files);
    }
  }
