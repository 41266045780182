
  import InputText from 'primevue/inputtext';
  import { Options, Vue } from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';

  @Options({
    components: {
      InputText,
    },
  })
  export default class AsMultiSelect extends Vue {
    @Prop() label;
    @Prop() value;
    @Prop() options;
    @Prop() optionLabel;
    @Prop() optionValue;
    @Prop() placeholder;
    @Prop() clearIcon;
    @Prop() selectedItemsLabel;

    optionsBackup = [];
    filteredOptions = null;

    get value_() {
      return this.value;
    }

    update($event) {
      return this.$emit('update', $event);
    }
  }
