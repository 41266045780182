import { FileDto } from '@/types/FileDto';

export function mapFile(file: FileDto, sourceName, formData: FormData) {
  const keys = Object.keys(file);

  keys.forEach((key) => {
    const value = file[key];

    if (value) {
      const path = `${sourceName}.${key}`;

      formData.append(path, value);
    }
  });
}

export function mapFiles(files: FileDto[], sourceName, formData: FormData) {
  (files || []).forEach((file, index) => {
    const keys = Object.keys(file);

    keys.forEach((key) => {
      const value = file[key];

      if (value) {
        const path = `${sourceName}[${index}].${key}`;

        formData.append(path, value);
      }
    });
  });

  return formData;
}

export function mapObjectToForm(source, sourceName, formData) {
  const caseDataProps = Object.keys(source);

  caseDataProps.forEach((prop) => {
    mapProperty(source, sourceName, prop, formData);
  });
}

function mapProperty(source, sourceName, prop, formData) {
  let value = source[prop];

  const lowerCaseProp = prop.toLowerCase();

  if (value && (lowerCaseProp.includes('datetime') || lowerCaseProp.includes('dateofbirth'))) {
    value = value.toISOString();
  }

  if (typeof value == 'boolean' && !value) {
    value = 'false';
  }

  const parentKey = sourceName ? `${sourceName}.` : '';

  formData.append(`${parentKey}${prop}`, value || '');
}

export function mapPropertyIfNotNull(prop, value, formData) {
  if (value != undefined) {
    formData.append(prop, value);
  }
}
