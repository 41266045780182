
  import { LookUpValueType } from '@/apiclient/apiclient_generated';
  import AsBlock from '@/components/shared/as-block-component.vue';
  import AsButton from '@/components/shared/as-button.vue';
  import AsFilesSelector from '@/components/shared/as-files-selector.vue';
  import FileService from '@/services/file.service';
  import { DocumentsModule, DocumentsStore } from '@/store/documents';
  import { ButtonTypes } from '@/types/ButtonTypes';
  import { CreateLibraryDocumentCommand } from '@/types/commands/CreateLibraryDocumentCommand';
  import { Options, Vue } from 'vue-class-component';

  @Options({
    components: {
      AsBlock,
      AsButton,
      AsFilesSelector,
    },
  })
  export default class NewLibraryDocument extends Vue {
    private docsStore: DocumentsModule = DocumentsStore;

    isLoading = false;
    showNewForm = false;
    bTypes = ButtonTypes;
    lvTypes = LookUpValueType;

    command = { files: [] } as CreateLibraryDocumentCommand;

    mounted(): void {
      this.initNewDocCommand();
    }

    initNewDocCommand() {
      this.isLoading = false;

      this.command = { files: [] } as CreateLibraryDocumentCommand;
    }

    initNewDocForm() {
      this.initNewDocCommand();

      this.showNewForm = true;
    }

    resetNewForm() {
      this.initNewDocCommand();

      this.showNewForm = false;
    }

    async createDocumentAsync() {
      this.isLoading = true;

      const createdEntityId = await this.docsStore.createLibraryDocumentAsync(this.command);

      if (createdEntityId) {
        this.resetNewForm();
      } else {
        this.isLoading = false;
      }
    }

    get isFormValid() {
      return !this.isLoading && this.command.files.length && FileService.isFilesFormValid(this.command.files);
    }

    get caseId(): any {
      return this.$route.params.id;
    }
  }
