import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-290804e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "details-btn-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsTextWithLabel = _resolveComponent("AsTextWithLabel")!
  const _component_AsBlock = _resolveComponent("AsBlock")!

  return (_openBlock(), _createBlock(_component_AsBlock, { "hover-effect": true }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "user-list-item",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.navigateToDetails && _ctx.navigateToDetails(...args)))
      }, [
        _createVNode(_component_AsTextWithLabel, {
          label: _ctx.$t('case.personalData.LastName'),
          value: _ctx.model.lastName
        }, null, 8, ["label", "value"]),
        _createVNode(_component_AsTextWithLabel, {
          label: _ctx.$t('case.personalData.FirstName'),
          value: _ctx.model.firstName
        }, null, 8, ["label", "value"]),
        _createVNode(_component_AsTextWithLabel, {
          class: "email",
          label: _ctx.$t('email'),
          value: _ctx.model.email
        }, null, 8, ["label", "value"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.navigateToDetails && _ctx.navigateToDetails(...args))),
            class: "details-btn"
          }, _toDisplayString(_ctx.$t('details')), 1)
        ])
      ])
    ]),
    _: 1
  }))
}