import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08ebccfc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "text-with-label"
}
const _hoisted_2 = { class: "label" }
const _hoisted_3 = {
  key: 1,
  class: "text-with-label"
}
const _hoisted_4 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.useSlot && _ctx.label && _ctx.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.label), 1),
          _createElementVNode("div", {
            class: _normalizeClass(["value", _ctx.valueClass])
          }, _toDisplayString(_ctx.value), 3)
        ]))
      : _createCommentVNode("", true),
    (_ctx.useSlot && _ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.label), 1),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}