import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70aafdc8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "as-button-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_component_Button, {
    class: _normalizeClass(["as-button", [_ctx.buttonClass, _ctx.buttonType, _ctx.justifyContent]]),
    disabled: _ctx.disabled
  }, {
    default: _withCtx(() => [
      (!_ctx.hideIcon)
        ? _renderSlot(_ctx.$slots, "icon", { key: 0 }, undefined, true)
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.label), 1),
      _renderSlot(_ctx.$slots, "label", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["disabled", "class"]))
}