
  import AsBlock from '@/components/shared/as-block-component.vue';
  import AsButton from '@/components/shared/as-button.vue';
  import { Options, Vue } from 'vue-class-component';

  @Options({
    components: {
      AsBlock,
      AsButton,
    },
  })
  export default class CaseCalendar extends Vue {}
