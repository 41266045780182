import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notifications = _resolveComponent("notifications")!
  const _component_vue_progress_bar = _resolveComponent("vue-progress-bar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_notifications, {
      position: "top right",
      group: "foo"
    }),
    _createVNode(_component_vue_progress_bar),
    _createVNode(_component_router_view)
  ], 64))
}