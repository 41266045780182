import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61303b00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-item" }
const _hoisted_2 = { class: "form-control-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "flex align-items-center"
}
const _hoisted_4 = { class: "drop-down-item" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "drop-down-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Dropdown, {
        class: "form-control",
        modelValue: _ctx.value_,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value_) = $event)),
          _ctx.update
        ],
        filter: true,
        options: _ctx.options_,
        placeholder: _ctx.placeholder,
        showClear: _ctx.clearIcon,
        disabled: _ctx.disabled,
        onBeforeShow: _ctx.filterOptions,
        onHide: _ctx.resetOptions
      }, {
        value: _withCtx((slotProps) => [
          (slotProps.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", {
                    class: "colored-box",
                    style: _normalizeStyle([`background-color: ${slotProps.value.value};`])
                  }, null, 4),
                  _createElementVNode("div", null, _toDisplayString(slotProps.value.name), 1)
                ])
              ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(slotProps.placeholder), 1))
        ]),
        option: _withCtx((slotProps) => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              class: "colored-box",
              style: _normalizeStyle([`background-color: ${slotProps.option.value};`])
            }, null, 4),
            _createElementVNode("div", null, _toDisplayString(slotProps.option.name), 1)
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "options", "placeholder", "showClear", "disabled", "onBeforeShow", "onHide", "onUpdate:modelValue"])
    ])
  ]))
}