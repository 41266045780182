import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b944394"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block-header" }
const _hoisted_2 = { class: "right-aligned-items" }
const _hoisted_3 = { class: "grid-container" }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { class: "grid-container" }
const _hoisted_6 = { class: "form-item" }
const _hoisted_7 = { class: "form-item" }
const _hoisted_8 = {
  key: 0,
  class: "create-new-meeting"
}
const _hoisted_9 = {
  key: 1,
  class: "right-aligned-items update-meeting"
}
const _hoisted_10 = {
  key: 0,
  class: "grid-container"
}
const _hoisted_11 = { class: "form-item" }
const _hoisted_12 = { class: "form-item" }
const _hoisted_13 = { class: "form-item" }
const _hoisted_14 = { class: "empty-block" }
const _hoisted_15 = { class: "meeting" }
const _hoisted_16 = { class: "details-btn-wrapper" }
const _hoisted_17 = {
  key: 0,
  class: "meeting-badge no-hover"
}
const _hoisted_18 = {
  key: 1,
  class: "details-btn"
}
const _hoisted_19 = { class: "empty-block" }
const _hoisted_20 = { class: "meeting-members" }
const _hoisted_21 = { class: "case-number" }
const _hoisted_22 = { class: "details-btn-wrapper" }
const _hoisted_23 = { class: "details-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsButton = _resolveComponent("AsButton")!
  const _component_AsBlock = _resolveComponent("AsBlock")!
  const _component_AsInput = _resolveComponent("AsInput")!
  const _component_AsDropdown = _resolveComponent("AsDropdown")!
  const _component_AsLabeledBlock = _resolveComponent("AsLabeledBlock")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_AsReadWriteBlock = _resolveComponent("AsReadWriteBlock")!
  const _component_AsEditBlock = _resolveComponent("AsEditBlock")!
  const _component_fa = _resolveComponent("fa")!
  const _component_AsDatepicker = _resolveComponent("AsDatepicker")!
  const _component_AsTextWithLabel = _resolveComponent("AsTextWithLabel")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_AsUserWithRole = _resolveComponent("AsUserWithRole")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.group.id)
      ? (_openBlock(), _createBlock(_component_AsBlock, {
          key: 0,
          class: "new-group-submit-block"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('group.createNewGroup')), 1),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_AsButton, {
                type: _ctx.bTypes.Primary,
                disabled: !_ctx.isFormValid,
                label: _ctx.$t('create'),
                onClick: _ctx.createGroup
              }, null, 8, ["type", "disabled", "label", "onClick"]),
              _createVNode(_component_AsButton, {
                type: _ctx.bTypes.Outlined,
                label: 'Cancel',
                onClick: _ctx.cancel
              }, null, 8, ["type", "onClick"])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_ctx.group.id)
      ? (_openBlock(), _createBlock(_component_AsBlock, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_AsLabeledBlock, {
              header: _ctx.$t('group.details')
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_AsInput, {
                    label: _ctx.$t('group.name'),
                    value: _ctx.group.name,
                    onUpdate: _cache[0] || (_cache[0] = (value) => (_ctx.group.name = value))
                  }, null, 8, ["label", "value"]),
                  _createVNode(_component_AsDropdown, {
                    clearIcon: true,
                    value: _ctx.group.organizerId,
                    options: _ctx.groupAdmins,
                    placeholder: _ctx.$t('group.selectOrganizer'),
                    optionValue: 'id',
                    optionLabel: 'fullName',
                    label: _ctx.$t('group.organizer'),
                    onUpdate: _cache[1] || (_cache[1] = (value) => (_ctx.group.organizerId = value))
                  }, null, 8, ["value", "options", "placeholder", "label"])
                ])
              ]),
              _: 1
            }, 8, ["header"])
          ]),
          _: 1
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_AsEditBlock, {
            header: _ctx.$t('group.details'),
            editParams: _ctx.groupEditParams,
            isDisabled: !_ctx.group.name || !_ctx.group.organizerId,
            onEditModeEnabled: _ctx.processEditDataEnable,
            onEditModeSubmit: _ctx.processEditSubmit,
            onEditModeCanceled: _ctx.processEditCancel
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_AsReadWriteBlock, {
                    label: _ctx.$t('group.name'),
                    value: _ctx.group.name,
                    isReadOnly: _ctx.isReadOnly
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_InputText, {
                        class: "form-control",
                        type: "text",
                        modelValue: _ctx.group.name,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.group.name) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["label", "value", "isReadOnly"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_AsReadWriteBlock, {
                    label: _ctx.$t('group.organizer'),
                    value: _ctx.groupOrganizer,
                    isReadOnly: _ctx.isReadOnly
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AsDropdown, {
                        clearIcon: true,
                        value: _ctx.group.organizerId,
                        options: _ctx.groupAdmins,
                        placeholder: _ctx.$t('group.selectOrganizer'),
                        optionValue: 'id',
                        optionLabel: 'fullName',
                        onUpdate: _cache[3] || (_cache[3] = (value) => (_ctx.group.organizerId = value))
                      }, null, 8, ["value", "options", "placeholder"])
                    ]),
                    _: 1
                  }, 8, ["label", "value", "isReadOnly"])
                ])
              ])
            ]),
            _: 1
          }, 8, ["header", "editParams", "isDisabled", "onEditModeEnabled", "onEditModeSubmit", "onEditModeCanceled"]),
          _createVNode(_component_AsBlock, {
            class: "new-meeting-wrapper",
            header: _ctx.$t('group.meetingDetails')
          }, {
            header: _withCtx(() => [
              (!_ctx.newMeeting)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(_component_AsButton, {
                      type: _ctx.bTypes.Primary,
                      label: _ctx.$t('AddNew'),
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addNewMeeting()))
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_fa, { icon: "icon fa-plus" })
                      ]),
                      _: 1
                    }, 8, ["type", "label"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(_component_AsButton, {
                      type: _ctx.bTypes.Primary,
                      disabled: _ctx.isLoading || !_ctx.newMeeting.meetingDate || !_ctx.newMeeting.meetingTypeId,
                      label: _ctx.$t('create'),
                      onClick: _ctx.createGroupMeeting
                    }, null, 8, ["type", "disabled", "label", "onClick"]),
                    _createVNode(_component_AsButton, {
                      type: _ctx.bTypes.Outlined,
                      label: _ctx.$t('cancel'),
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.newMeeting = null))
                    }, null, 8, ["type", "label"])
                  ]))
            ]),
            default: _withCtx(() => [
              (_ctx.newMeeting)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_AsDatepicker, {
                        placeholder: _ctx.$t('date'),
                        value: _ctx.newMeeting.meetingDate,
                        label: _ctx.$t('date'),
                        enableTimePicker: false,
                        onUpdate: _cache[6] || (_cache[6] = (date) => (_ctx.newMeeting.meetingDate = date))
                      }, null, 8, ["placeholder", "value", "label"])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(_component_AsDropdown, {
                          placeholder: _ctx.$t('group.meetingType'),
                          label: _ctx.$t('group.meetingType'),
                          clearIcon: true,
                          optionValue: 'id',
                          optionLabel: 'value',
                          value: _ctx.newMeeting.meetingTypeId,
                          options: _ctx.getLookUpOptions(_ctx.lvTypes.GroupMeeting),
                          onUpdate: _cache[7] || (_cache[7] = (value) => (_ctx.newMeeting.meetingTypeId = value))
                        }, null, 8, ["placeholder", "label", "value", "options"])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header"]),
          (!_ctx.group.meetings || !_ctx.group.meetings.length)
            ? (_openBlock(), _createBlock(_component_AsBlock, { key: 0 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('noData')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredGroupMeetings, (meeting) => {
            return (_openBlock(), _createBlock(_component_AsBlock, {
              key: meeting.id,
              "hover-effect": true,
              onClick: ($event: any) => (_ctx.navigateToMeetingDetails(meeting.id))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_AsTextWithLabel, {
                    label: _ctx.$t('date'),
                    value: _ctx.formatDate(meeting.meetingDate)
                  }, null, 8, ["label", "value"]),
                  _createVNode(_component_AsTextWithLabel, {
                    label: _ctx.$t('group.membersCount'),
                    value: _ctx.getMembersLabel(meeting.membersCount)
                  }, null, 8, ["label", "value"]),
                  _createElementVNode("div", _hoisted_16, [
                    (meeting.isDeleted)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                          _createVNode(_component_Badge, {
                            value: _ctx.$t('canceled'),
                            severity: "warning"
                          }, null, 8, ["value"])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(_ctx.$t('details')), 1))
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["onClick"]))
          }), 128)),
          _createVNode(_component_AsBlock, {
            class: "mt-4",
            header: _ctx.$t('group.members')
          }, {
            header: _withCtx(() => []),
            _: 1
          }, 8, ["header"]),
          (!_ctx.group.members || !_ctx.group.members.length)
            ? (_openBlock(), _createBlock(_component_AsBlock, { key: 1 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('noData')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.group.members, (member) => {
            return (_openBlock(), _createBlock(_component_AsBlock, {
              key: member.id,
              "hover-effect": true,
              onClick: ($event: any) => (_ctx.openCaseDetails(member.caseId))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    _createVNode(_component_fa, { icon: "icon fa-regular fa-user" }),
                    _createElementVNode("div", null, _toDisplayString(member.caseNumber), 1)
                  ]),
                  _createVNode(_component_AsUserWithRole, {
                    modelValue: _ctx.getUser(member.caseManagerId),
                    role: _ctx.applicationRoleType.CaseManager
                  }, null, 8, ["modelValue", "role"]),
                  _createVNode(_component_AsUserWithRole, {
                    modelValue: _ctx.getUser(member.therapistId),
                    role: _ctx.applicationRoleType.Therapist
                  }, null, 8, ["modelValue", "role"]),
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t('details')), 1)
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["onClick"]))
          }), 128))
        ]))
  ], 64))
}