import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54017410"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "as-modal" }
const _hoisted_2 = { class: "as-modal-content" }
const _hoisted_3 = { class: "as-modal-header" }
const _hoisted_4 = { class: "as-modal-body" }
const _hoisted_5 = {
  key: 0,
  class: "as-modal-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createVNode(_Transition, { name: "drop-in" }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", {
              class: _normalizeClass(_ctx.modalInnerStyle)
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _renderSlot(_ctx.$slots, "header", {}, undefined, true),
                  (_ctx.showCloseButton)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "close-icon",
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
                      }, [
                        _createVNode(_component_fa, {
                          class: "cursor-pointer",
                          icon: "icon fa-times"
                        })
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "body", {}, undefined, true)
                ]),
                (!_ctx.hideFooter)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], 2), [
              [_vShow, _ctx.open]
            ])
          ]),
          _: 3
        })
      ], 512), [
        [_vShow, _ctx.open]
      ])
    ]),
    _: 3
  }))
}