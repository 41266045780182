
  import { Options, Vue } from 'vue-class-component';
  import LibraryDocuments from '@/components/library/LibraryDocuments.vue';
  import AsBlock from '../shared/as-block-component.vue';

  @Options({
    components: {
      AsBlock,
      LibraryDocuments,
    },
  })
  export default class Library extends Vue {}
