import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-740a00d7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "case-history"
}
const _hoisted_2 = { class: "history-search" }
const _hoisted_3 = { class: "history-search-button" }
const _hoisted_4 = { class: "btn-with-text" }
const _hoisted_5 = { class: "history-search-text" }
const _hoisted_6 = {
  key: 0,
  class: "history-search-items"
}
const _hoisted_7 = { class: "empty-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewHistoryItem = _resolveComponent("NewHistoryItem")!
  const _component_fa = _resolveComponent("fa")!
  const _component_AsButton = _resolveComponent("AsButton")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_ListHistoryItem = _resolveComponent("ListHistoryItem")!
  const _component_AsBlock = _resolveComponent("AsBlock")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.canCreate)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (!_ctx.isCaseDeleted)
            ? (_openBlock(), _createBlock(_component_NewHistoryItem, {
                key: 0,
                caseId: _ctx.caseId
              }, null, 8, ["caseId"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.canRead(_ctx.caseScope.CaseHistory))
      ? (_openBlock(), _createBlock(_component_AsBlock, { key: 1 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_AsButton, {
                    type: _ctx.buttonTypes.Outlined,
                    class: _normalizeClass(_ctx.buttonTypes.Secondary),
                    label: _ctx.$t('date'),
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isAscSort = !_ctx.isAscSort))
                  }, {
                    icon: _withCtx(() => [
                      (_ctx.isAscSort)
                        ? (_openBlock(), _createBlock(_component_fa, {
                            key: 0,
                            icon: "icon fa-arrow-up"
                          }))
                        : (_openBlock(), _createBlock(_component_fa, {
                            key: 1,
                            icon: "icon fa-arrow-down"
                          }))
                    ]),
                    _: 1
                  }, 8, ["type", "class", "label"])
                ]),
                _createElementVNode("div", {
                  class: "btn-with-icon",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isAscSort = !_ctx.isAscSort))
                }, [
                  (_ctx.isAscSort)
                    ? (_openBlock(), _createBlock(_component_fa, {
                        key: 0,
                        icon: "icon fa-arrow-up"
                      }))
                    : (_openBlock(), _createBlock(_component_fa, {
                        key: 1,
                        icon: "icon fa-arrow-down"
                      }))
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: _ctx.search,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.search) = $event)),
                  placeholder: _ctx.$t('search')
                }, null, 8, ["modelValue", "placeholder"])
              ])
            ]),
            (_ctx.historyItems && _ctx.historyItems.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.historyItems, (item) => {
                    return (_openBlock(), _createBlock(_component_ListHistoryItem, {
                      class: "list-history-item",
                      item: item,
                      isReadOnly: _ctx.isCaseDeleted || !_ctx.canWrite(_ctx.caseScope.CaseHistory, item.createdByUserId, item.created, item.modified),
                      key: item.id,
                      searchText: _ctx.search
                    }, null, 8, ["item", "isReadOnly", "searchText"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_ctx.historyItems || !_ctx.historyItems.length)
      ? (_openBlock(), _createBlock(_component_AsBlock, { key: 2 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('noData')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}