import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22eaed8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-item" }
const _hoisted_2 = { class: "form-item-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Datepicker, {
        format: _ctx.enableTimePicker ? 'dd.MM.yyyy  HH:mm' : 'dd.MM.yyyy',
        placeholder: _ctx.placeholder,
        "min-date": _ctx.min,
        "max-date": _ctx.max,
        modelValue: _ctx.value_,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value_) = $event)),
          _ctx.update
        ],
        disabled: _ctx.disabled,
        enableTimePicker: _ctx.enableTimePicker,
        "select-text": _ctx.$t('datepicker.select'),
        "cancel-text": _ctx.$t('datepicker.cancel')
      }, null, 8, ["format", "placeholder", "min-date", "max-date", "modelValue", "disabled", "enableTimePicker", "select-text", "cancel-text", "onUpdate:modelValue"]),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}