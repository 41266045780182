
  import { ITabMenu } from '@/types/ITabMenu';
  import { Options, Vue } from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import TabMenu from 'primevue/tabmenu';
  import Routes from '@/types/Routes';
  import { PublicConstants } from '@/types/PublicConstants';

  @Options({
    components: {
      TabMenu,
    },
  })
  export default class AsTabMenu extends Vue {
    @Prop()
    menu: ITabMenu;

    mounted() {
      const tabAlias = this.$route.query?.p;

      if (tabAlias) {
        this.menu.items.forEach((tab) => (tab.isActive = false));

        const activeTabIndex = this.menu.items.findIndex((t) => t.alias === tabAlias);

        const activeTab = this.menu.items[activeTabIndex];
        activeTab.isActive = true;
      }
    }

    get activeIndex() {
      return this.menu.items.findIndex((t) => t.isActive);
    }

    processTabChange({ index }) {
      const path = this.menu.items[index].path;
      if (path === Routes.cases.path) {
        return this.$router.push({ name: Routes.cases.name });
      } else if (path && path.includes(Routes.group.name)) {
        return this.$router.push(path);
      }

      if (this.menu.items[index].component === 'CaseData') {
        localStorage.setItem(PublicConstants.refreshTabContent, '1');
      } else {
        localStorage.removeItem(PublicConstants.refreshTabContent);
      }

      this.menu.items.forEach((tab) => (tab.isActive = false));

      const activeTab = this.menu.items[index];
      activeTab.isActive = true;

      this.$router.replace({ query: { p: activeTab.alias } });
    }
  }
