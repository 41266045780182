
  import Layout from '@/components/Layout.vue';
  import Library from '@/components/library/Library.vue';
  import { Options, Vue } from 'vue-class-component';

  @Options({
    components: {
      Layout,
      Library,
    },
  })
  export default class LibraryView extends Vue {}
