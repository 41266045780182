import { CreateCaseCommand } from '@/types/commands/CreateCaseCommand';

export function newCreateCaseCommand() {
  return {
    CaseManagerId: null,

    PersonalData: {},
    HistoryData: { Files: [] },
  } as CreateCaseCommand;
}
