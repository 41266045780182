
  import AsBlock from '@/components/shared/as-block-component.vue';
  import AsButton from '@/components/shared/as-button.vue';
  import { CaseHistoryModule, CaseHistoryStore } from '@/store/caseHistory';
  import { Options, Vue } from 'vue-class-component';
  import NewHistoryItem from './NewHistoryItem.vue';
  import { ButtonTypes } from '@/types/ButtonTypes';
  import { FilesModule, FilesStore } from '@/store/files';
  import FileService from '@/services/file.service';
  import { NotificationService } from '@/services/notification.service';
  import { Prop } from 'vue-property-decorator';
  import { AntiSkidCaseFileDto, HistoryItemDto, HistoryItemParentType } from '@/apiclient/apiclient_generated';
  import AsFilesSelector from '@/components/shared/as-files-selector.vue';
  import { UpdateHistoryItemCommand } from '@/types/commands/UpdateHistoryItemCommand';
  import { ref } from 'vue';
  import { FileDto } from '@/types/FileDto';
  import { formatDateWithoutTime } from '@/services/dateFunctions';
  import AsModal from '@/components/shared/as-modal.vue';
  import VuePdfApp from 'vue3-pdf-app';
  import Routes from '@/types/Routes';
  import { UserManagementModule, UserManagementStore } from '@/store/userManagement';
  import AccessRightsService from '@/services/accessRightsService';
  import { CaseReadWriteScope } from '@/types/CaseReadWriteScope';

  @Options({
    components: {
      NewHistoryItem,
      AsBlock,
      AsButton,
      AsFilesSelector,
      AsModal,
      VuePdfApp,
    },
  })
  export default class ListHistoryItem extends Vue {
    private historyStore: CaseHistoryModule = CaseHistoryStore;
    private filesStore: FilesModule = FilesStore;
    private usersStore: UserManagementModule = UserManagementStore;

    caseScope = CaseReadWriteScope;

    parentType = HistoryItemParentType;
    originalText = '';
    isLoading = false;
    isEditing = false;

    buttonTypes = ButtonTypes;
    command: any = ref({} as UpdateHistoryItemCommand);

    previewDocument = false;
    previewFile = { name: '', arrayBuffer: null };

    @Prop()
    item: HistoryItemDto;
    @Prop()
    searchText;
    @Prop()
    isReadOnly;

    get caseId() {
      return this.$route.params.id;
    }

    getUserRole(file: AntiSkidCaseFileDto) {
      const fileCreator = this.usersStore.users[file.createdBy];
      return fileCreator?.fullName || '';
    }

    getFormattedDate(historyItem) {
      return historyItem && historyItem.createdDate ? formatDateWithoutTime(historyItem.createdDate) : '';
    }

    async downloadFile(fileId) {
      const file = await this.filesStore.downloadCaseFile(fileId);

      if (file) {
        FileService.downloadFile(file.data, file.fileName);
      }
    }

    deleteFile(event, historyItemId, fileId) {
      this.$confirm.require({
        target: event.currentTarget,
        message: 'Are you sure you want to delete selected file?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const result = await this.filesStore.deleteCaseFile(fileId);

          if (result.isSuccess) {
            this.historyStore.removeAttachment({ historyItemId, fileId });
            NotificationService.NotifySuccess(this.$t('notifications.fileWasRemoved'));
          }
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    }

    enableEditMode() {
      this.originalText = this.item.text;

      this.command = {
        files: this.item.files.map((t) => {
          return {
            Id: t.id,
            Name: t.name,
            CategoryId: t.categoryId,
            Description: t.description,
          } as FileDto;
        }),
      } as UpdateHistoryItemCommand;

      this.isEditing = true;
    }

    highlightText(text) {
      if (!this.searchText) {
        return text;
      }
      return text.replace(new RegExp(this.searchText, 'gi'), (match) => {
        return '<span class="highlight-text">' + match + '</span>';
      });
    }

    async updateHistoryItem() {
      this.command.historyItemId = this.item.id;
      this.command.text = this.item.text;
      // this.command.files = this.filesContainer.files;

      this.isLoading = true;
      await this.historyStore.updateHistoryItem(this.command);
      this.isLoading = false;
      this.isEditing = false;
    }

    cancelEditHistoryItem() {
      this.isEditing = false;
      this.item.text = this.originalText;
    }

    get isFormValid() {
      return !this.isLoading && this.item.text && FileService.isFilesFormValid(this.command.files);
    }

    closePreviewDocument() {
      this.resetPreviewFile();
      this.previewDocument = false;
    }

    resetPreviewFile() {
      this.previewFile = {
        name: '',
        arrayBuffer: null,
      };
    }

    async processPreviewDocumentClick(fileId) {
      this.resetPreviewFile();
      const file = await this.filesStore.downloadCaseFile(fileId);

      this.previewFile.name = file.fileName;

      const fileLoadHandler = (arrayBuffer) => {
        this.previewFile.arrayBuffer = arrayBuffer;
        this.previewDocument = !this.previewDocument;
      };

      var fileReader = new FileReader();
      fileReader.onload = function (event) {
        fileLoadHandler(event.target.result);
      };

      fileReader.readAsArrayBuffer(file.data);
    }

    navigateToMeetingDetails() {
      const params = { id: this.item.groupId, meetingId: this.item.groupMeetingId };

      this.$router.push({ name: Routes.groupMeeting.name, params });
    }

    canWrite(caseScope: CaseReadWriteScope, entityCreator, createdDate, modifiedDate) {
      return AccessRightsService.canUserWrite(caseScope, entityCreator, modifiedDate || createdDate);
    }
  }
