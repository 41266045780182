
  import { SideMenuModule, SideMenuStore } from '@/store/sideMenu';
  import { UserModule, UserStore } from '@/store/user';
  import NavigationMenuItem from '@/types/NavigationMenuItem';
  import { Options, Vue } from 'vue-class-component';
  import emitter from '@/services/eventBus';
  import Events from '@/types/Events';
  import Routes from '@/types/Routes';

  @Options({})
  export default class SideMenu extends Vue {
    sideMenuStore: SideMenuModule = SideMenuStore;
    userStore: UserModule = UserStore;

    mounted() {
      emitter.$on(Events.identity.signIn, this.processLogin);
    }

    // created() {
    //   window.addEventListener(Events.window.resize, this.windowResizeHandler);
    // }
    // destroyed() {
    //   window.removeEventListener('resize', this.windowResizeHandler);
    // }

    // windowResizeHandler() {
    //   const windowSize = window.innerWidth;

    //   if (windowSize < 992) {
    //     if (!this.sideMenuStore.isSideMenuClosed) {
    //       this.sideMenuStore.toggleSideMenu();
    //     }
    //   } else {
    //     if (this.sideMenuStore.isSideMenuClosed) {
    //       this.sideMenuStore.toggleSideMenu();
    //     }
    //   }
    // }

    get userData() {
      return this.userStore.userData;
    }

    get menuItems() {
      const items = this.sideMenuStore.menu.items;

      return items ? items.filter((t) => !t.isHidden) : items;
    }
    get isClosed() {
      return this.sideMenuStore.isSideMenuClosed;
    }
    get isSignedIn() {
      return this.userStore.isSigned;
    }

    processLogin() {
      this.sideMenuStore.initMenu(this.$route.name);
    }

    async signOut() {
      await this.userStore.logout();

      this.sideMenuStore.resetMenu();

      this.$forceUpdate();
      this.$router.push({ name: Routes.root.name });
    }

    processMenuItemClick(menuItem: NavigationMenuItem) {
      this.sideMenuStore.updateMenuItem(menuItem);
      this.processRouteChange();
    }

    processRouteChange() {
      const activeMenu = this.sideMenuStore.activeMenuItem;
      this.$router.push({ path: activeMenu.activeRoute, params: { id: '0' } });
    }

    navigateToProfile() {
      this.$router.push({ name: Routes.user.name, params: { id: this.userStore.currentUserId } });
    }

    toggleMenu() {
      this.sideMenuStore.toggleSideMenu();
    }
  }
