import { getModule, Module, Mutation, VuexModule, MutationAction } from 'vuex-module-decorators';
import store from '@/store/index';
import { SideMenuSize } from '@/types/SideMenuSize';
import NavigationMenu from '@/types/NavigationMenu';
import Routes from '@/types/Routes';
import NavigationMenuItem from '@/types/NavigationMenuItem';
import { UserModule } from './user';
import { UserManagementModule } from './userManagement';
import { ApplicationRoleType } from '@/apiclient/apiclient_generated';

@Module({ dynamic: true, namespaced: true, store, name: 'sideMenu' })
export class SideMenuModule extends VuexModule {
  private _sideBarSizeKey = '_sideBarSize';
  private _sideBarSize = SideMenuSize.None;

  activeMenuItem = {
    activeRoute: '',
    NavigationMenuItem: {},
  };
  menu: NavigationMenu = {} as NavigationMenu;

  initialMenu: NavigationMenu = {
    items: [
      {
        isActive: false,
        name: Routes.root.name,
        path: Routes.root.path,

        title: Routes.dashboard.title,
        class: 'chart-column',
      },
    ],
  } as NavigationMenu;

  lookUpMenuItems: NavigationMenuItem[] = [
    {
      isActive: false,
      name: Routes.lookUpValues.name,
      path: Routes.lookUpValues.path,

      title: Routes.lookUpValues.title,
      class: 'chart-simple',
    },
  ] as NavigationMenuItem[];

  libraryItems: NavigationMenuItem[] = [
    {
      isActive: false,
      name: Routes.library.name,
      path: Routes.library.path,

      title: Routes.library.title,
      class: 'fa-book',
    },
  ] as NavigationMenuItem[];

  casesMenuItems = [
    {
      isActive: false,
      name: Routes.cases.name,
      path: Routes.cases.path,

      title: Routes.cases.title,
      class: 'table-cells-large',
    },
    {
      isHidden: true,
      name: Routes.case.name,
      path: Routes.case.path,
      friendlyItemName: Routes.cases.name,
    },
  ] as NavigationMenuItem[];

  groupMenuItems = [
    {
      isActive: false,
      name: Routes.groups.name,
      path: Routes.groups.path,

      title: Routes.groups.title,
      class: 'people-group',
    },
    {
      isHidden: true,
      name: Routes.group.name,
      path: Routes.group.path,
      friendlyItemName: Routes.groups.name,
    },
    {
      isHidden: true,
      name: Routes.groupMeeting.name,
      path: Routes.groupMeeting.path,
      friendlyItemName: Routes.groups.name,
    },
  ] as NavigationMenuItem[];

  usersManagementMenuItems = [
    {
      isActive: false,
      name: Routes.users.name,
      path: Routes.users.path,
      title: Routes.users.title,
      class: 'users',
    },

    {
      name: Routes.user.name,
      path: Routes.user.path,
      title: Routes.user.title,
      class: 'user',
      isHidden: true,
      friendlyItemName: Routes.users.name,
    },
  ] as NavigationMenuItem[];

  get isSideMenuClosed() {
    return this.sideMenuSize === SideMenuSize.Small;
  }

  get sideMenuSize(): SideMenuSize {
    let size = localStorage.getItem(this._sideBarSizeKey);

    if (!size) {
      size = SideMenuSize.Regular;
    }

    if (!this._sideBarSize) {
      const store = getModule(SideMenuModule);
      store.changeSideMenuSize(size ? size : SideMenuSize.Regular);
    }

    return this._sideBarSize;
  }

  get menuBasedOnUserRoles() {
    const currentUserStore = getModule(UserModule);
    const usersStore = getModule(UserManagementModule);

    const currentUser = usersStore.users[currentUserStore.currentUserId];

    const initialMenu = JSON.parse(JSON.stringify(this.initialMenu));

    if (
      currentUser &&
      currentUser.roles &&
      currentUser.roles.length === 1 &&
      currentUser.roles.includes(ApplicationRoleType.StatisticUser)
    ) {
      return initialMenu;
    }

    initialMenu.items = initialMenu.items.concat(JSON.parse(JSON.stringify(this.casesMenuItems)));

    if (
      !currentUser.roles.includes(ApplicationRoleType.SystemAdministrator) &&
      !currentUser.roles.includes(ApplicationRoleType.GroupAdmin)
    ) {
      return initialMenu;
    }

    initialMenu.items = initialMenu.items.concat(JSON.parse(JSON.stringify(this.groupMenuItems)));

    initialMenu.items = initialMenu.items.concat(JSON.parse(JSON.stringify(this.libraryItems)));

    if (!currentUser.roles.includes(ApplicationRoleType.SystemAdministrator)) {
      return initialMenu;
    }

    initialMenu.items = initialMenu.items.concat(JSON.parse(JSON.stringify(this.lookUpMenuItems)));
    initialMenu.items = initialMenu.items.concat(JSON.parse(JSON.stringify(this.usersManagementMenuItems)));

    return initialMenu;
  }

  @Mutation
  initMenu(route: string | any) {
    if (this.activeMenuItem.activeRoute === route) {
      return;
    }

    const store = getModule(SideMenuModule);
    const menu = store.menuBasedOnUserRoles;

    // init default first menu item
    if (!route || route === Routes.root.name) {
      this.menu = menu;
      const activeItem = this.menu.items[0];
      activeItem.isActive = true;
      this.activeMenuItem.NavigationMenuItem = activeItem;
      this.activeMenuItem.activeRoute = activeItem.path;
      return;
    } else {
      this.menu = menu;

      const mainMenuItem = this.menu.items.find((_) => _.name === route);

      if (!mainMenuItem) {
        this.menu.items.forEach((menu) => {
          menu.isActive = false;
        });

        this.activeMenuItem.NavigationMenuItem = {};

        return;
      }

      if (mainMenuItem.friendlyItemName) {
        const friendlyItem = this.menu.items.find((_) => _.name === mainMenuItem.friendlyItemName);
        if (friendlyItem) {
          friendlyItem.isActive = true;
        }
      }

      mainMenuItem.isActive = true;
      const futureActiveRoute = mainMenuItem.path;

      this.activeMenuItem.activeRoute = futureActiveRoute;
      this.activeMenuItem.NavigationMenuItem = mainMenuItem;
    }
  }

  @Mutation
  toggleSideMenu() {
    const store = getModule(SideMenuModule);

    const newSize = store.isSideMenuClosed ? SideMenuSize.Regular : SideMenuSize.Small;

    localStorage.setItem(this._sideBarSizeKey, newSize);
    this._sideBarSize = newSize;
  }

  @Mutation
  changeSideMenuSize(newSize) {
    localStorage.setItem(this._sideBarSizeKey, newSize);
    this._sideBarSize = newSize;
  }

  @Mutation
  updateMenuItem(newActiveItem: NavigationMenuItem) {
    const store = getModule(SideMenuModule);
    const route = newActiveItem.name;

    store.initMenu(route);
  }

  @Mutation
  resetMenu() {
    this.menu = { items: [] };
    this.activeMenuItem = { activeRoute: '', NavigationMenuItem: { items: [] } };
  }
}

export const SideMenuStore = getModule(SideMenuModule);
