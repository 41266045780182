
  import { ITabMenu, ITabMenuItem } from '@/types/ITabMenu';
  import { Options, Vue } from 'vue-class-component';

  import AsTabMenu from '@/components/shared/as-tabMenu.vue';
  import CaseData from './CaseData.vue';
  import CaseHistory from './history/CaseHistory.vue';
  import CaseLaboratory from './laboratory/CaseLaboratory.vue';
  import CaseCalendar from './calendar/CaseCalendar.vue';
  import Routes from '@/types/Routes';
  import AsBlock from '../shared/as-block-component.vue';
  import { CaseDataDto, CaseDto, CasePersonalDataDto, GetCaseQuery, LookUpValueType } from '@/apiclient/apiclient_generated';
  import CaseDocuments from './documents/CaseDocuments.vue';
  import { CaseModule, CaseStore } from '@/store/case';
  import AsTextWithLabel from '../shared/as-textWithLabel.vue';
  import Events from '@/types/Events';
  import emitter from '@/services/eventBus';
  import { GroupsModule, GroupsStore } from '@/store/groups';
  import { LookUpValuesModule, LookUpValuesStore } from '@/store/lookUpValues';
  import CaseUsers from './users/CaseUsers.vue';
  import { PublicConstants } from '@/types/PublicConstants';

  @Options({
    components: {
      AsTabMenu,
      CaseData,
      CaseHistory,
      CaseDocuments,
      CaseLaboratory,
      CaseUsers,
      CaseCalendar,
      AsBlock,
      AsTextWithLabel,
    },
  })
  export default class CaseDetails extends Vue {
    private caseStore: CaseModule = CaseStore;
    private groupsStore: GroupsModule = GroupsStore;
    private lookUpStore: LookUpValuesModule = LookUpValuesStore;

    lvTypes = LookUpValueType;

    private labMenuItem = {
      label: 'case.tabs.laboratory',
      icon: 'pi pi-chart-bar',
      component: 'CaseLaboratory',
      alias: 'lb',
      isActive: false,
    } as ITabMenuItem;

    private caseUsersMenuItem = {
      label: 'case.tabs.users',
      class: 'case-users',
      icon: 'pi pi-users',
      component: 'CaseUsers',
      alias: 'cu',
      isActive: false,
    } as ITabMenuItem;

    private menuTemplate = {
      items: [
        {
          icon: 'pi pi-arrow-circle-left back-url',
          path: Routes.cases.path,
          order: 0,
        },
        {
          label: 'case.tabs.caseData',
          icon: 'pi pi-table',
          component: 'CaseData',
          isActive: true,
          alias: 'cd',
          order: 1,
        },
        {
          label: 'case.tabs.history',
          icon: 'pi pi-history',
          component: 'CaseHistory',
          isActive: false,
          alias: 'ch',
          order: 2,
        },
        {
          label: 'case.tabs.documents',
          icon: 'pi pi-file-pdf',
          component: 'CaseDocuments',
          isActive: false,
          alias: 'cdo',
          order: 3,
        },
        // {
        //   label: 'Calendar',
        //   icon: 'pi pi-calendar',
        //   component: 'CaseCalendar',
        //   isActive: false,
        //   order: 4,
        // },
      ],
    } as ITabMenu;

    menu = { items: [] } as ITabMenu;

    slotProps = {};

    getLookUpValue(group: LookUpValueType, id) {
      return this.lookUpStore.getLookUpValueName({ group, id });
    }

    get caseId(): any {
      return this.$route.params.id;
    }

    get caseNumber() {
      return this.currentCase.caseData?.caseNumber || this.currentCase.personalData?.pipelineCaseNumber || PublicConstants.emptyValue;
    }

    get getCaseNumberSeverity(): any {
      if (this.currentCase.caseData?.caseNumber) {
        return 'success';
      }

      if (this.currentCase.personalData?.pipelineCaseNumber) {
        return 'warning';
      }

      return '';
    }

    async mounted() {
      await this.groupsStore.getGroupsLightInfoAsync();

      const query = {
        caseId: this.caseId,
        periodStart: null,
        periodEnd: null,
      } as GetCaseQuery;

      await this.caseStore.getCase(query);

      await this.caseStore.getCaseUsers(this.caseId);

      this.initMenu();

      emitter.$on(Events.case.useLaboratoryUpdated, this.processLaboratoryUpdated);
    }

    async processLaboratoryUpdated() {
      this.initMenu();

      await this.caseStore.getCaseUsers(this.caseId);
    }

    unmounted(): void {
      this.caseStore.setCase({} as CaseDto);
    }

    get currentCase(): CaseDto {
      return this.caseStore.currentCase.caseData?.id
        ? this.caseStore.currentCase
        : ({ caseData: { useLaboratory: false } as CaseDataDto, personalData: {} as CasePersonalDataDto } as CaseDto);
    }

    get isCaseDetailsLoaded() {
      return this.currentCase.caseData?.id;
    }

    initMenu() {
      const menu = [...this.menuTemplate.items];

      const previousUrl = localStorage.getItem(Routes.PreviousUrl);
      if (previousUrl) {
        menu[0].path = previousUrl;
      }

      if (this.currentCase.caseClinicalInformation?.useLaboratory) {
        menu.push(this.labMenuItem);
      }

      menu.push(this.caseUsersMenuItem);

      const t = this.$t;

      menu.forEach((item) => {
        if (item.label) {
          if (t) {
            item.label = t(item.label);
          }
        }
      });

      this.menu.items = menu;
    }
  }
