import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b578f006"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "block-header"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["as-block", { hoverEffect: _ctx.hoverEffect }])
  }, [
    (_ctx.header)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.header) + " ", 1),
          _renderSlot(_ctx.$slots, "header", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "standAloneHeader", { class: "block-header" }, undefined, true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 2))
}