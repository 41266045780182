
  import { GroupDto } from '@/apiclient/apiclient_generated';
  import { Options, Vue } from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import AsUserWithRole from '../shared/as-userWithRole.vue';
  import AsBlock from '../shared/as-block-component.vue';
  import AsTextWithLabel from '../shared/as-textWithLabel.vue';
  import { formatStringDate } from '@/services/dateFunctions';
  import Routes from '@/types/Routes';

  @Options({
    components: {
      AsUserWithRole,
      AsBlock,
      AsTextWithLabel,
    },
  })
  export default class GroupListItem extends Vue {
    @Prop()
    modelValue: GroupDto;

    get model() {
      return this.modelValue;
    }

    getCountLabel(count, singularLabel, pluralLabel) {
      return `${count} ${count !== 1 ? pluralLabel : singularLabel}`;
    }

    formatStringDate = formatStringDate;

    navigateToDetails() {
      this.$router.push({ name: Routes.group.name, params: { id: this.modelValue.id } });
    }
  }
