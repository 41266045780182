
  import { Options, Vue } from 'vue-class-component';
  import AsBlock from '../shared/as-block-component.vue';
  import AsButton from '../shared/as-button.vue';
  import { ButtonTypes } from '@/types/ButtonTypes';
  import AsInput from '../shared/as-input.vue';
  import { LookUpValueDto, LookUpValueType, UpdateLookUpValueCommand } from '@/apiclient/apiclient_generated';
  import AsDropdown from '../shared/as-dropdown.vue';
  import { Emit, Prop } from 'vue-property-decorator';
  import AsReadWriteBlock from '../shared/as-read-write.vue';
  import { LookUpValuesModule, LookUpValuesStore } from '@/store/lookUpValues';

  @Options({
    components: {
      AsBlock,
      AsButton,
      AsInput,
      AsDropdown,
      AsReadWriteBlock,
    },
  })
  export default class LookUpValue extends Vue {
    private lookUpStore: LookUpValuesModule = LookUpValuesStore;

    isReadOnly = true;
    isLoading = false;
    bTypes = ButtonTypes;
    @Prop()
    lookUpValue!: LookUpValueDto;
    backup: LookUpValueDto;

    private lookUpValues_ = [
      LookUpValueType.Airline,
      LookUpValueType.Insurance,
      LookUpValueType.Program,
      LookUpValueType.Function,
      LookUpValueType.Contact,
      LookUpValueType.HistoryInput,
      LookUpValueType.Hospitalization,
      LookUpValueType.ProgramFailReason,
      LookUpValueType.FollowUpConsent,
      LookUpValueType.LaboratoryTestResult,
      LookUpValueType.LaboratoryDoctor,
      LookUpValueType.LaboratoryResultTestType,
      LookUpValueType.GroupMeeting,
      LookUpValueType.FileCategory,
      LookUpValueType.LibraryFile,
    ];

    lookUpValueOptions = [];

    mounted(): void {
      this.lookUpValueOptions = this.lookUpValues_
        .sort((a, b) => a.localeCompare(b))
        .map((key) => {
          return {
            key: key,
            value: this.$t(`lookUpValues.${key}`),
          };
        });
    }

    @Emit()
    updateModel() {
      return this.lookUpValue;
    }

    async updateLookUpValue() {
      this.isLoading = true;

      const command = {
        value: this.lookUpValue,
      } as UpdateLookUpValueCommand;

      try {
        const result = await this.lookUpStore.updateLookUpValue(command);

        this.isReadOnly = true;
      } finally {
        this.isLoading = false;
      }
    }

    enableEditMode() {
      this.backup = { ...this.lookUpValue } as LookUpValueDto;
      this.isReadOnly = false;
    }

    cancel() {
      this.lookUpStore.setLookUpValue(this.backup);
      this.isReadOnly = true;
    }

    async deleteLookUpValue(event) {
      this.$confirm.require({
        target: event.currentTarget,
        message: this.$t('lookUpValues.deleteWarn'),
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          await this.lookUpStore.deleteLookUpValue(this.lookUpValue);
          this.isReadOnly = true;
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    }

    get isFormValid() {
      return !this.isLoading && this.lookUpValue.type && this.lookUpValue.name && this.lookUpValue.value;
    }
  }
