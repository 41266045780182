
  import AsBlock from '@/components/shared/as-block-component.vue';
  import AsButton from '@/components/shared/as-button.vue';
  import AsFilesSelector from '@/components/shared/as-files-selector.vue';
  import FileService from '@/services/file.service';
  import { DocumentsModule, DocumentsStore } from '@/store/documents';
  import { ButtonTypes } from '@/types/ButtonTypes';
  import { CreateHistoryItemCommand } from '@/types/commands/CreateHistoryItemCommand';
  import { Options, Vue } from 'vue-class-component';

  @Options({
    components: {
      AsBlock,
      AsButton,
      AsFilesSelector,
    },
  })
  export default class NewDocument extends Vue {
    private docsStore: DocumentsModule = DocumentsStore;

    isLoading = false;
    showNewForm = false;
    bTypes = ButtonTypes;

    command = { files: [], useSystemMessage: true } as CreateHistoryItemCommand;

    initNewForm() {
      this.resetModel();

      this.showNewForm = true;
    }

    resetNewForm() {
      this.resetModel();

      this.showNewForm = false;
    }

    resetModel() {
      this.isLoading = false;

      this.command = { files: [], useSystemMessage: true } as CreateHistoryItemCommand;
    }

    async createDocumentAsync() {
      this.isLoading = true;

      const command = {
        caseId: this.caseId,
        files: this.command.files,
        useSystemMessage: true,
      } as CreateHistoryItemCommand;

      const createdEntityId = await this.docsStore.createCaseDocumentAsync(command);

      if (createdEntityId) {
        this.resetNewForm();
      } else {
        this.isLoading = false;
      }
    }

    get isFormValid() {
      return !this.isLoading && this.command.files.length && FileService.isFilesFormValid(this.command.files);
    }

    get caseId(): any {
      return this.$route.params.id;
    }
  }
