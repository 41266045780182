
  import { Options, Vue } from 'vue-class-component';
  import AsBlock from '../shared/as-block-component.vue';
  import NewLookUpValue from './NewLookUpValue.vue';
  import { LookUpValuesModule, LookUpValuesStore } from '@/store/lookUpValues';
  import LookUpValue from './LookUpValue.vue';
  import { LookUpValueDto, UpdateLookUpValuesSortOrderCommand } from '@/apiclient/apiclient_generated';
  import { NotificationService } from '@/services/notification.service';

  @Options({
    components: {
      AsBlock,
      NewLookUpValue,
      LookUpValue,
    },
  })
  export default class LookUpValues extends Vue {
    private lookUpStore: LookUpValuesModule = LookUpValuesStore;

    drag = false;

    get lookUpGroups() {
      return this.lookUpStore.lookUpValueGroups.sort((a, b) => a.type.localeCompare(b.type));
    }

    get dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    }

    async refreshSortOrder(lookUpValues: LookUpValueDto[]) {
      const updates = {} as { [key: string]: number };

      lookUpValues.forEach((t) => {
        updates[t.id] = t.sortOrder;
      });

      const command = {
        orderedLookUpValues: updates,
      } as UpdateLookUpValuesSortOrderCommand;

      await this.lookUpStore.reorderLookUpValues(command);

      NotificationService.NotifySuccess(this.$t('reordered'));
    }
  }
