
  import { Options, Vue } from 'vue-class-component';
  import AsBlock from '../../shared/as-block-component.vue';
  import AsButton from '../../shared/as-button.vue';
  import Routes from '@/types/Routes';
  import { UserDto } from '@/apiclient/apiclient_generated';
  import { Prop } from 'vue-property-decorator';
  import AsTextWithLabel from '@/components/shared/as-textWithLabel.vue';

  @Options({
    components: {
      AsBlock,
      AsButton,
      AsTextWithLabel,
    },
  })
  export default class UserListItem extends Vue {
    @Prop()
    modelValue!: UserDto;

    get model() {
      return this.modelValue;
    }

    navigateToDetails() {
      this.$router.push({ name: Routes.user.name, params: { id: this.modelValue.id } });
    }
  }
