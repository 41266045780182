
  import Dashboard from '@/components/Dashboard.vue';
  import Login from '@/components/Login.vue';
  import { SideMenuModule, SideMenuStore } from '@/store/sideMenu';
  import { UserModule, UserStore } from '@/store/user';
  import Routes from '@/types/Routes';
  import { Options, Vue } from 'vue-class-component';
  import emitter from '@/services/eventBus';
  import Events from '@/types/Events';
  import Layout from '@/components/Layout.vue';

  @Options({
    components: {
      Login,
      Dashboard,
      Layout,
    },
  })
  export default class LandingPageView extends Vue {
    private sideMenuStore: SideMenuModule = SideMenuStore;
    private userStore: UserModule = UserStore;

    async mounted() {
      emitter.$on(Events.identity.signIn, await this.processLogin);
    }

    async processLogin() {
      this.sideMenuStore.initMenu(this.$route.name);

      const returnUrl = localStorage.getItem(Routes.ReturnUrl);
      if (returnUrl) {
        this.$router.push(returnUrl);
        localStorage.removeItem(Routes.ReturnUrl);
      } else {
        this.$router.push(Routes.root.path);
      }
    }

    get user() {
      return this.userStore.authData;
    }
  }
