import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, renderList as _renderList, createSlots as _createSlots, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-203cd4ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "case" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_AsTextWithLabel = _resolveComponent("AsTextWithLabel")!
  const _component_AsBlock = _resolveComponent("AsBlock")!
  const _component_AsTabMenu = _resolveComponent("AsTabMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isCaseDetailsLoaded)
      ? (_openBlock(), _createBlock(_component_AsBlock, {
          key: 0,
          class: "case-header"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AsTextWithLabel, {
              label: _ctx.$t('case.CaseNumber'),
              useSlot: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Badge, {
                  severity: _ctx.getCaseNumberSeverity,
                  class: "no-hover"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.caseNumber), 1)
                  ]),
                  _: 1
                }, 8, ["severity"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_AsTextWithLabel, {
              label: _ctx.$t('case.personalData.Phone'),
              value: _ctx.currentCase.personalData?.phone || '---'
            }, null, 8, ["label", "value"]),
            _createVNode(_component_AsTextWithLabel, {
              label: _ctx.$t('case.personalData.Airline'),
              value: _ctx.getLookUpValue(_ctx.lvTypes.Airline, _ctx.currentCase.personalData?.airlineId)
            }, null, 8, ["label", "value"]),
            _createVNode(_component_AsTextWithLabel, {
              label: _ctx.$t('case.personalData.Program'),
              value: _ctx.getLookUpValue(_ctx.lvTypes.Program, _ctx.currentCase.personalData?.programId)
            }, null, 8, ["label", "value"]),
            _createVNode(_component_AsTextWithLabel, {
              class: "email",
              label: _ctx.$t('email'),
              value: _ctx.currentCase.personalData?.email || '---'
            }, null, 8, ["label", "value"]),
            _createVNode(_component_AsTextWithLabel, {
              label: _ctx.$t('case.clinicalInformation.AME'),
              value: _ctx.currentCase.caseClinicalInformation?.aeromedicalExaminer || '---'
            }, null, 8, ["label", "value"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isCaseDetailsLoaded && _ctx.menu.items.length)
      ? (_openBlock(), _createBlock(_component_AsTabMenu, {
          key: 1,
          menu: _ctx.menu
        }, _createSlots({ _: 2 }, [
          _renderList(_ctx.menu.items, (item) => {
            return {
              name: `${item.label}`,
              fn: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(item.component)))
              ])
            }
          })
        ]), 1032, ["menu"]))
      : _createCommentVNode("", true)
  ]))
}