
  import {
    ApplicationRoleType,
    GetUsersQuery,
    GroupMeetingDto,
    CreateGroupCommand,
    GroupDetailsDto,
    UpdateGroupCommand,
    CreateGroupMeetingCommand,
    LookUpValueType,
    ServiceResultOfInteger,
  } from '@/apiclient/apiclient_generated';
  import Routes from '@/types/Routes';
  import { Options, Vue } from 'vue-class-component';
  import AsUserWithRole from '../shared/as-userWithRole.vue';
  import { UserManagementModule, UserManagementStore } from '@/store/userManagement';
  import AsBlock from '../shared/as-block-component.vue';
  import AsTextWithLabel from '../shared/as-textWithLabel.vue';
  import { GroupsModule, GroupsStore } from '@/store/groups';
  import { ButtonTypes } from '@/types/ButtonTypes';
  import AsButton from '../shared/as-button.vue';
  import AsLabeledBlock from '../shared/as-labeled-block.vue';
  import AsDropdown from '../shared/as-dropdown.vue';
  import AsInput from '../shared/as-input.vue';
  import { formatStringDate } from '@/services/dateFunctions';
  import AsEditBlock from '../shared/as-edit-block.vue';
  import EditBlockParams from '@/types/EditBlockParams';
  import { isFormReadOnly } from '@/services/formFunctions';
  import AsReadWriteBlock from '../shared/as-read-write.vue';
  import AsDatepicker from '../shared/as-datepicker.vue';
  import { PublicConstants } from '@/types/PublicConstants';
  import { LookUpValuesModule, LookUpValuesStore } from '@/store/lookUpValues';
  import { NotificationService } from '@/services/notification.service';

  @Options({
    components: {
      AsUserWithRole,
      AsBlock,
      AsTextWithLabel,
      AsButton,
      AsLabeledBlock,
      AsDropdown,
      AsInput,
      AsEditBlock,
      AsReadWriteBlock,
      AsDatepicker,
    },
  })
  export default class Group extends Vue {
    private groupsStore: GroupsModule = GroupsStore;
    private usersStore: UserManagementModule = UserManagementStore;
    private lookUpStore: LookUpValuesModule = LookUpValuesStore;

    private groupId_ = null;

    lvTypes = LookUpValueType;
    bTypes = ButtonTypes;
    formatDate = formatStringDate;
    applicationRoleType = ApplicationRoleType;
    newMeeting: GroupMeetingDto = null;
    groupBackup = {} as GroupDetailsDto;
    groupEditParams = {
      isEditing: false,
    } as EditBlockParams;
    isLoading = false;

    async mounted() {
      // await this.usersStore.getUsers({ roles: [ApplicationRoleType.CaseManager] } as GetUsersQuery);

      this.initGroup();
    }

    unmounted(): void {
      this.groupsStore.setGroup({
        meetings: [],
        members: [],
      } as GroupDetailsDto);
    }

    getLookUpOptions(groupType: LookUpValueType) {
      return this.lookUpStore.lookUpOptions(groupType);
    }

    addNewMeeting() {
      this.newMeeting = {} as GroupMeetingDto;
    }

    get isReadOnly() {
      const state = isFormReadOnly(true, this.groupEditParams.isEditing);

      return state;
    }

    get groupOrganizer() {
      const organizer = this.group.organizerId ? this.usersStore.users[this.group.organizerId] : null;

      return organizer ? organizer.fullName : PublicConstants.emptyValue;
    }

    async initGroup() {
      const id = this.groupId;

      await this.groupsStore.getGroupAsync(id);
    }

    get groupAdmins() {
      return this.usersStore.groupAdmins;
    }

    get groupId() {
      return this.groupId_ || this.$route.params.id;
    }

    get group() {
      return this.groupsStore.group;
    }

    get filteredGroupMeetings() {
      return this.group.meetings.sort((a, b) => new Date(b.meetingDate).getTime() - new Date(a.meetingDate).getTime());
    }

    get isFormValid() {
      return this.group.name && this.group.organizerId;
    }

    getUser(userId) {
      return this.usersStore.users[userId];
    }

    async createGroup() {
      const createGroupResult: ServiceResultOfInteger = await this.groupsStore.createGroupAsync({
        groupName: this.group.name,
        groupOrganizerId: this.group.organizerId,
      } as CreateGroupCommand);

      if (createGroupResult.isSuccess) {
        const groupId = createGroupResult.entity;

        history.pushState({}, null, `${this.$route.path}/${groupId}`);
        this.groupId_ = groupId;

        this.initGroup();
      } else {
        createGroupResult.errors.forEach((error) => {
          NotificationService.NotifyError(this.$t(error));
        });
      }
    }

    async createGroupMeeting() {
      this.isLoading = true;

      const command = {
        groupId: this.group.id,
        meetingDate: this.newMeeting.meetingDate,
        meetingTypeId: this.newMeeting.meetingTypeId,
      } as CreateGroupMeetingCommand;

      await this.groupsStore.createGroupMeetingAsync(command);

      this.isLoading = false;
      this.newMeeting = null;
    }

    cancel() {
      this.$router.push({ path: Routes.groups.path, params: {} });
    }

    getMembersLabel(membersCount) {
      return `${membersCount} Member${membersCount !== 1 ? 's' : ''}`;
    }

    openCaseDetails(caseId) {
      this.$router.push({ name: Routes.case.name, params: { id: caseId } });
    }

    processEditDataEnable() {
      this.groupBackup = { ...this.group } as GroupDetailsDto;
    }
    processEditCancel() {
      this.groupsStore.setGroup(this.groupBackup);
    }
    async processEditSubmit() {
      const command = {
        groupId: this.group.id,
        groupName: this.group.name,
        groupOrganizerId: this.group.organizerId,
      } as UpdateGroupCommand;

      const isSuccess = await this.groupsStore.updateGroupAsync(command);

      this.groupEditParams.isEditing = !isSuccess;
    }

    navigateToMeetingDetails(meetingId) {
      const params = { id: this.groupId, meetingId: meetingId };

      this.$router.push({ name: Routes.groupMeeting.name, params });
    }
  }
