
  import AsBlock from '@/components/shared/as-block-component.vue';
  import AsButton from '@/components/shared/as-button.vue';
  import { CaseHistoryModule, CaseHistoryStore } from '@/store/caseHistory';
  import { Options, Vue } from 'vue-class-component';
  import NewHistoryItem from './NewHistoryItem.vue';
  import { ButtonTypes } from '@/types/ButtonTypes';
  import { CaseHistoryDto } from '@/apiclient/apiclient_generated';
  import { ref } from 'vue';
  import ListHistoryItem from './ListHistoryItem.vue';
  import { CaseModule, CaseStore } from '@/store/case';
  import { CaseReadWriteScope } from '@/types/CaseReadWriteScope';
  import AccessRightsService from '@/services/accessRightsService';

  @Options({
    components: {
      NewHistoryItem,
      AsBlock,
      AsButton,
      ListHistoryItem,
    },
  })
  export default class CaseHistory extends Vue {
    private historyStore: CaseHistoryModule = CaseHistoryStore;
    private caseStore: CaseModule = CaseStore;

    caseScope = CaseReadWriteScope;

    buttonTypes = ButtonTypes;
    search: any = ref('');
    isAscSort = false;

    async mounted() {
      if (this.canRead(CaseReadWriteScope.CaseHistory)) {
        this.historyStore.getCaseHistory(this.caseId);
      }
    }

    unmounted(): void {
      this.historyStore.setCaseHistory({ historyItems: [] } as CaseHistoryDto);
    }

    get caseId() {
      return this.$route.params.id;
    }

    get isCaseDeleted() {
      return this.caseStore.isCaseDeleted;
    }

    get historyItems() {
      const items = this.historyStore.caseHistory.historyItems.sort((a: any, b: any) => {
        return this.isAscSort ? a.createdDate - b.createdDate : b.createdDate - a.createdDate;
      });

      return this.search ? items.filter((t) => this.includes(t.text, this.search)) : items;
    }

    private includes(source: string, pattern) {
      return source?.toLowerCase().includes(pattern?.toLowerCase());
    }

    canRead(caseScope: CaseReadWriteScope) {
      return AccessRightsService.canUserRead(caseScope);
    }

    get canCreate() {
      return AccessRightsService.canUserWrite(CaseReadWriteScope.CaseHistory);
    }

    canWrite(caseScope: CaseReadWriteScope, entityCreator, createdDate, modifiedDate) {
      return AccessRightsService.canUserWrite(caseScope, entityCreator, modifiedDate || createdDate);
    }
  }
