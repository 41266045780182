
  import AsBlock from '@/components/shared/as-block-component.vue';
  import AsButton from '@/components/shared/as-button.vue';
  import AsDropdown from '@/components/shared/as-dropdown.vue';
  import AsInput from '@/components/shared/as-input.vue';
  import { CaseModule, CaseStore } from '@/store/case';
  import { DocumentsModule, DocumentsStore } from '@/store/documents';
  import { ButtonTypes } from '@/types/ButtonTypes';
  import { Options, Vue } from 'vue-class-component';
  import NewDocument from './NewDocument.vue';
  import ListDocument from './ListDocument.vue';
  import { ref } from 'vue';
  import AccessRightsService from '@/services/accessRightsService';
  import { CaseReadWriteScope } from '@/types/CaseReadWriteScope';
  import { LookUpValuesModule, LookUpValuesStore } from '@/store/lookUpValues';
  import { LookUpValueDto, LookUpValueType } from '@/apiclient/apiclient_generated';

  @Options({
    components: {
      AsBlock,
      AsButton,
      AsDropdown,
      AsInput,
      NewDocument,
      ListDocument,
    },
  })
  export default class CaseDocuments extends Vue {
    private docsStore: DocumentsModule = DocumentsStore;
    private caseStore: CaseModule = CaseStore;
    private lookUpStore: LookUpValuesModule = LookUpValuesStore;

    caseScope = CaseReadWriteScope;

    buttonTypes = ButtonTypes;
    filters: any = ref({
      docType: null,
      docName: '',
    });

    isAscSort = false;

    bTypes = ButtonTypes;

    async mounted() {
      this.resetFiltersModel();

      await this.docsStore.getCaseDocuments(this.caseId);
    }

    get docTypesOptions(): LookUpValueDto[] {
      return this.lookUpStore.lookUpOptions(LookUpValueType.FileCategory);
    }

    private get caseId() {
      return this.$route.params.id;
    }

    get documents() {
      return this.docsStore.caseDocuments;
    }

    get filteredDocs() {
      let sortedDocs = this.documents.sort((a: any, b: any) => {
        return this.isAscSort ? a.createdDate - b.createdDate : b.createdDate - a.createdDate;
      });

      if (this.filters.docName) {
        sortedDocs = sortedDocs.filter((t) => this.includes(t.name, this.filters.docName));
      }

      if (this.filters.docType) {
        sortedDocs = sortedDocs.filter((t) => t.categoryId === this.filters.docType);
      }

      return sortedDocs;
    }

    private includes(source: string, pattern) {
      return source?.toLowerCase().includes(pattern?.toLowerCase());
    }

    resetFiltersModel() {
      this.filters = {
        docType: null,
        docName: '',
      };
    }

    get fileCategories() {
      return this.lookUpStore.lookUpOptions(LookUpValueType.FileCategory);
    }

    get isCaseDeleted() {
      return this.caseStore.isCaseDeleted;
    }

    get canCreate() {
      return AccessRightsService.canUserWrite(CaseReadWriteScope.Documents);
    }

    canRead(caseScope: CaseReadWriteScope) {
      return AccessRightsService.canUserRead(caseScope);
    }
  }
