import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSwitch = _resolveComponent("InputSwitch")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-item", _ctx.customClass])
  }, [
    _createVNode(_component_InputSwitch, {
      modelValue: _ctx.value_,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value_) = $event)),
        _ctx.update
      ],
      disabled: _ctx.disabled
    }, null, 8, ["modelValue", "disabled", "onUpdate:modelValue"]),
    _renderSlot(_ctx.$slots, "label", {}, undefined, true)
  ], 2))
}