import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e28f2182"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "case-clinical-information" }
const _hoisted_2 = { class: "grid-container" }
const _hoisted_3 = { class: "grid-container" }
const _hoisted_4 = { class: "grid-container" }
const _hoisted_5 = { class: "grid-container" }
const _hoisted_6 = { class: "grid-container" }
const _hoisted_7 = { class: "grid-container" }
const _hoisted_8 = { class: "grid-container" }
const _hoisted_9 = { class: "grid-container" }
const _hoisted_10 = { class: "grid-container" }
const _hoisted_11 = { class: "grid-container" }
const _hoisted_12 = { class: "grid-container" }
const _hoisted_13 = { class: "grid-container" }
const _hoisted_14 = { class: "grid-container" }
const _hoisted_15 = { class: "grid-container" }
const _hoisted_16 = { class: "grid-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsInput = _resolveComponent("AsInput")!
  const _component_AsReadWriteBlock = _resolveComponent("AsReadWriteBlock")!
  const _component_AsTags = _resolveComponent("AsTags")!
  const _component_AsLabeledBlock = _resolveComponent("AsLabeledBlock")!
  const _component_AsDatepicker = _resolveComponent("AsDatepicker")!
  const _component_AsDropdown = _resolveComponent("AsDropdown")!
  const _component_ClinicHistoryInputs = _resolveComponent("ClinicHistoryInputs")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_HospitalizationHistory = _resolveComponent("HospitalizationHistory")!
  const _component_Textarea = _resolveComponent("Textarea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AsLabeledBlock, {
      header: _ctx.$t('case.clinicalInformation.Diagnoses')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_AsReadWriteBlock, {
            class: "form-item",
            label: _ctx.$t('case.clinicalInformation.MainDiagnosis'),
            value: _ctx.getValueOrDefault(_ctx.model.mainDiagnose),
            isReadOnly: _ctx.isReadOnly
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AsInput, {
                type: "text",
                value: _ctx.model.mainDiagnose,
                onUpdate: _cache[0] || (_cache[0] = (value) => (_ctx.model.mainDiagnose = value))
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 8, ["label", "value", "isReadOnly"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_AsReadWriteBlock, {
            class: "form-item",
            label: _ctx.$t('case.clinicalInformation.SecondaryDiagnosis'),
            value: _ctx.getListValuesOrDefault(_ctx.model.secondaryDiagnosis),
            isReadOnly: _ctx.isReadOnly
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AsTags, {
                value: _ctx.model.secondaryDiagnosis,
                placeholder: _ctx.$t('case.clinicalInformation.secondaryDiagnosisPlaceholder'),
                onUpdate: _cache[1] || (_cache[1] = (updates) => (_ctx.model.secondaryDiagnosis = updates))
              }, null, 8, ["value", "placeholder"])
            ]),
            _: 1
          }, 8, ["label", "value", "isReadOnly"])
        ])
      ]),
      _: 1
    }, 8, ["header"]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_AsReadWriteBlock, {
        class: "form-item",
        label: _ctx.$t('case.clinicalInformation.DateOfProgramBegin'),
        value: _ctx.getFormattedDate(_ctx.model['programStartDateTime']),
        isReadOnly: _ctx.isReadOnly
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AsDatepicker, {
            placeholder: _ctx.$t('datePlaceholder'),
            modelValue: _ctx.model.programStartDate,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.programStartDate) = $event)),
            enableTimePicker: false,
            value: _ctx.model.programStartDate,
            onUpdate: _cache[3] || (_cache[3] = (date) => (_ctx.model.programStartDate = date))
          }, null, 8, ["placeholder", "modelValue", "value"])
        ]),
        _: 1
      }, 8, ["label", "value", "isReadOnly"]),
      _createVNode(_component_AsReadWriteBlock, {
        class: "form-item",
        label: _ctx.$t('case.clinicalInformation.DateOfPlannedEnd'),
        value: _ctx.getFormattedDate(_ctx.model['plannedProgramEndDateTime']),
        isReadOnly: _ctx.isReadOnly
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AsDatepicker, {
            placeholder: _ctx.$t('datePlaceholder'),
            modelValue: _ctx.model.plannedProgramEndDate,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.plannedProgramEndDate) = $event)),
            enableTimePicker: false,
            value: _ctx.model.plannedProgramEndDate,
            onUpdate: _cache[5] || (_cache[5] = (date) => (_ctx.model.plannedProgramEndDate = date))
          }, null, 8, ["placeholder", "modelValue", "value"])
        ]),
        _: 1
      }, 8, ["label", "value", "isReadOnly"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_AsReadWriteBlock, {
        class: "form-item",
        label: _ctx.$t('PeerWithAccess'),
        value: _ctx.getUserFullNameOrDefault(_ctx.model.advisorId),
        isReadOnly: _ctx.isReadOnly
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AsDropdown, {
            clearIcon: true,
            value: _ctx.model.advisorId,
            options: _ctx.peers,
            placeholder: _ctx.$t('selectContactsPlaceholder'),
            optionValue: 'id',
            optionLabel: 'fullName',
            onUpdate: _cache[6] || (_cache[6] = (value) => (_ctx.model.advisorId = value))
          }, null, 8, ["value", "options", "placeholder"])
        ]),
        _: 1
      }, 8, ["label", "value", "isReadOnly"]),
      _createVNode(_component_AsReadWriteBlock, {
        class: "form-item",
        label: _ctx.$t('TherapistWithAccess'),
        value: _ctx.getUserFullNameOrDefault(_ctx.model.therapistId),
        isReadOnly: _ctx.isReadOnly
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AsDropdown, {
            clearIcon: true,
            value: _ctx.model.therapistId,
            options: _ctx.therapists,
            placeholder: _ctx.$t('case.clinicalInformation.TherapistPlaceholder'),
            optionValue: 'id',
            optionLabel: 'fullName',
            onUpdate: _cache[7] || (_cache[7] = (value) => (_ctx.model.therapistId = value))
          }, null, 8, ["value", "options", "placeholder"])
        ]),
        _: 1
      }, 8, ["label", "value", "isReadOnly"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_AsReadWriteBlock, {
        class: "form-item",
        label: _ctx.$t('case.clinicalInformation.AeromedicalExaminer'),
        value: _ctx.getValueOrDefault(_ctx.model.aeromedicalExaminer),
        isReadOnly: _ctx.isReadOnly
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AsInput, {
            type: "text",
            value: _ctx.model.aeromedicalExaminer,
            onUpdate: _cache[8] || (_cache[8] = (value) => (_ctx.model.aeromedicalExaminer = value))
          }, null, 8, ["value"])
        ]),
        _: 1
      }, 8, ["label", "value", "isReadOnly"]),
      _createVNode(_component_AsReadWriteBlock, {
        class: "form-item",
        label: _ctx.$t('case.clinicalInformation.AeromedicalFormer'),
        value: _ctx.getValueOrDefault(_ctx.model.aeromedicalFormer),
        isReadOnly: _ctx.isReadOnly
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AsInput, {
            type: "text",
            value: _ctx.model.aeromedicalFormer,
            onUpdate: _cache[9] || (_cache[9] = (value) => (_ctx.model.aeromedicalFormer = value))
          }, null, 8, ["value"])
        ]),
        _: 1
      }, 8, ["label", "value", "isReadOnly"])
    ]),
    _createVNode(_component_AsLabeledBlock, {
      header: _ctx.$t('case.clinicalInformation.Airtworthiness')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ClinicHistoryInputs, {
          isReadOnly: _ctx.isReadOnly,
          model: _ctx.model.historyInputs
        }, null, 8, ["isReadOnly", "model"])
      ]),
      _: 1
    }, 8, ["header"]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_AsReadWriteBlock, {
        class: "form-item",
        label: _ctx.$t('case.clinicalInformation.ReferenceNumbers'),
        value: _ctx.getListValuesOrDefault(_ctx.model.referenceNumbers),
        isReadOnly: _ctx.isReadOnly
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AsTags, {
            value: _ctx.model.referenceNumbers,
            placeholder: _ctx.$t('case.clinicalInformation.referenceNumbersPlaceholder'),
            onUpdate: _cache[10] || (_cache[10] = (updates) => (_ctx.model.referenceNumbers = updates))
          }, null, 8, ["value", "placeholder"])
        ]),
        _: 1
      }, 8, ["label", "value", "isReadOnly"])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_AsReadWriteBlock, {
        class: "form-item",
        label: _ctx.$t('case.clinicalInformation.MedicalExpirationDate'),
        value: _ctx.getFormattedDate(_ctx.model['medicalExpirationDateTime']),
        isReadOnly: _ctx.isReadOnly
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AsDatepicker, {
            placeholder: _ctx.$t('datePlaceholder'),
            value: _ctx.model.medicalExpirationDate,
            enableTimePicker: false,
            onUpdate: _cache[11] || (_cache[11] = (date) => (_ctx.model.medicalExpirationDate = date))
          }, null, 8, ["placeholder", "value"])
        ]),
        _: 1
      }, 8, ["label", "value", "isReadOnly"]),
      _createVNode(_component_AsReadWriteBlock, {
        class: "form-item",
        label: _ctx.$t('case.clinicalInformation.RemarksAndRestrictions'),
        value: _ctx.getValueOrDefault(_ctx.model.remarksAndRestrictions),
        isReadOnly: _ctx.isReadOnly
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AsInput, {
            type: "text",
            value: _ctx.model.remarksAndRestrictions,
            onUpdate: _cache[12] || (_cache[12] = (value) => (_ctx.model.remarksAndRestrictions = value))
          }, null, 8, ["value"])
        ]),
        _: 1
      }, 8, ["label", "value", "isReadOnly"])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_AsReadWriteBlock, {
        class: "form-item",
        label: _ctx.$t('case.clinicalInformation.Laboratory'),
        value: _ctx.getBooleanValueOrDefault(_ctx.model.useLaboratory),
        isReadOnly: _ctx.isReadOnly
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InputSwitch, {
            modelValue: _ctx.model.useLaboratory,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model.useLaboratory) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["label", "value", "isReadOnly"]),
      _createVNode(_component_AsReadWriteBlock, {
        class: "form-item",
        label: _ctx.$t('case.clinicalInformation.Doctor'),
        value: _ctx.getValueOrDefault(_ctx.model.doctor),
        isReadOnly: _ctx.isReadOnly
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AsInput, {
            type: "text",
            value: _ctx.model.doctor,
            onUpdate: _cache[14] || (_cache[14] = (value) => (_ctx.model.doctor = value))
          }, null, 8, ["value"])
        ]),
        _: 1
      }, 8, ["label", "value", "isReadOnly"])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_AsReadWriteBlock, {
        class: "form-item",
        label: _ctx.$t('case.clinicalInformation.GroupMeetings'),
        value: _ctx.getBooleanValueOrDefault(_ctx.model.useGroupMeetings),
        isReadOnly: _ctx.isReadOnly
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InputSwitch, {
            modelValue: _ctx.model.useGroupMeetings,
            "onUpdate:modelValue": [
              _cache[15] || (_cache[15] = ($event: any) => ((_ctx.model.useGroupMeetings) = $event)),
              _ctx.processGroupChange
            ]
          }, null, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }, 8, ["label", "value", "isReadOnly"]),
      (_ctx.model.useGroupMeetings)
        ? (_openBlock(), _createBlock(_component_AsReadWriteBlock, {
            key: 0,
            class: "form-item",
            label: _ctx.$t('case.clinicalInformation.Group'),
            value: _ctx.getGroupNameOrDefault(_ctx.model.groupId),
            isReadOnly: _ctx.isReadOnly
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AsDropdown, {
                clearIcon: true,
                value: _ctx.model.groupId,
                options: _ctx.groups,
                placeholder: _ctx.$t('case.clinicalInformation.GroupPlaceholder'),
                optionLabel: 'name',
                optionValue: 'id',
                onUpdate: _cache[16] || (_cache[16] = (value) => (_ctx.model.groupId = value))
              }, null, 8, ["value", "options", "placeholder"])
            ]),
            _: 1
          }, 8, ["label", "value", "isReadOnly"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_AsLabeledBlock, {
      header: _ctx.$t('case.clinicalInformation.Hospitalization')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_HospitalizationHistory, {
          isReadOnly: _ctx.isReadOnly,
          model: _ctx.model.hospitalizationHistory
        }, null, 8, ["isReadOnly", "model"])
      ]),
      _: 1
    }, 8, ["header"]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_AsReadWriteBlock, {
        class: "form-item",
        label: _ctx.$t('case.clinicalInformation.EndOfProgram'),
        value: _ctx.getFormattedDate(_ctx.model['programEndDateTime']),
        isReadOnly: _ctx.isReadOnly
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AsDatepicker, {
            placeholder: _ctx.$t('datePlaceholder'),
            value: _ctx.model.programEndDate,
            enableTimePicker: false,
            onUpdate: _cache[17] || (_cache[17] = (date) => (_ctx.model.programEndDate = date))
          }, null, 8, ["placeholder", "value"])
        ]),
        _: 1
      }, 8, ["label", "value", "isReadOnly"])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createVNode(_component_AsReadWriteBlock, {
        class: "form-item",
        label: _ctx.$t('case.clinicalInformation.IsProgramSucceed'),
        value: _ctx.getBooleanValueOrDefault(_ctx.model.isProgramSucceed),
        isReadOnly: _ctx.isReadOnly
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InputSwitch, {
            modelValue: _ctx.model.isProgramSucceed,
            "onUpdate:modelValue": [
              _cache[18] || (_cache[18] = ($event: any) => ((_ctx.model.isProgramSucceed) = $event)),
              _ctx.processProgramSuccessChange
            ]
          }, null, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }, 8, ["label", "value", "isReadOnly"]),
      _createVNode(_component_AsReadWriteBlock, {
        class: "form-item",
        label: _ctx.$t('case.clinicalInformation.ProgramFailReasonType'),
        value: _ctx.getLookUpValue(_ctx.lvTypes.ProgramFailReason, _ctx.model.programFailReasonId),
        isReadOnly: _ctx.isReadOnly
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AsDropdown, {
            disabled: _ctx.model.isProgramSucceed,
            clearIcon: true,
            value: _ctx.model.programFailReasonId,
            options: _ctx.getLookUpOptions(_ctx.lvTypes.ProgramFailReason),
            optionValue: 'id',
            optionLabel: 'value',
            placeholder: _ctx.$t('case.clinicalInformation.ProgramFailReasonPlaceholder'),
            onUpdate: _cache[19] || (_cache[19] = (value) => (_ctx.model.programFailReasonId = value))
          }, null, 8, ["disabled", "value", "options", "placeholder"])
        ]),
        _: 1
      }, 8, ["label", "value", "isReadOnly"])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createVNode(_component_AsReadWriteBlock, {
        class: "form-item",
        label: _ctx.$t('case.clinicalInformation.IsLetterForLbaAvailable'),
        value: _ctx.getBooleanValueOrDefault(_ctx.model.isLetterForLbaAvailable),
        isReadOnly: _ctx.isReadOnly
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InputSwitch, {
            modelValue: _ctx.model.isLetterForLbaAvailable,
            "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.model.isLetterForLbaAvailable) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["label", "value", "isReadOnly"])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createVNode(_component_AsReadWriteBlock, {
        class: "form-item",
        label: _ctx.$t('case.clinicalInformation.FinalLetterSent'),
        value: _ctx.getFormattedDate(_ctx.model['finalLetterSentDateTime']),
        isReadOnly: _ctx.isReadOnly
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AsDatepicker, {
            placeholder: _ctx.$t('datePlaceholder'),
            value: _ctx.model.finalLetterSentDate,
            enableTimePicker: false,
            onUpdate: _cache[21] || (_cache[21] = (date) => (_ctx.model.finalLetterSentDate = date))
          }, null, 8, ["placeholder", "value"])
        ]),
        _: 1
      }, 8, ["label", "value", "isReadOnly"]),
      _createVNode(_component_AsReadWriteBlock, {
        class: "form-item",
        label: _ctx.$t('case.clinicalInformation.FollowUpConsent'),
        value: _ctx.getLookUpValue(_ctx.lvTypes.FollowUpConsent, _ctx.model.followUpConsentId),
        isReadOnly: _ctx.isReadOnly
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AsDropdown, {
            clearIcon: true,
            value: _ctx.model.followUpConsentId,
            options: _ctx.getLookUpOptions(_ctx.lvTypes.FollowUpConsent),
            optionValue: 'id',
            optionLabel: 'value',
            placeholder: _ctx.$t('case.clinicalInformation.FollowUpConsentPlaceholder'),
            onUpdate: _cache[22] || (_cache[22] = (value) => (_ctx.model.followUpConsentId = value))
          }, null, 8, ["value", "options", "placeholder"])
        ]),
        _: 1
      }, 8, ["label", "value", "isReadOnly"])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createVNode(_component_AsReadWriteBlock, {
        class: "form-item",
        label: _ctx.$t('case.clinicalInformation.FollowUp'),
        value: _ctx.getBooleanValueOrDefault(_ctx.model.isFollowUpConsent),
        isReadOnly: _ctx.isReadOnly
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InputSwitch, {
            modelValue: _ctx.model.isFollowUpConsent,
            "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.model.isFollowUpConsent) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["label", "value", "isReadOnly"])
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createVNode(_component_AsReadWriteBlock, {
        class: "form-item",
        label: _ctx.$t('case.clinicalInformation.FinalRemarks'),
        value: _ctx.getValueOrDefault(_ctx.model.remarks),
        isReadOnly: _ctx.isReadOnly
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Textarea, {
            class: "full-width",
            rows: "3",
            modelValue: _ctx.model.remarks,
            "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.model.remarks) = $event)),
            autoResize: true
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["label", "value", "isReadOnly"])
    ])
  ]))
}