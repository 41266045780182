import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "form-item" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_InputText, {
      class: "form-control",
      type: _ctx.type || 'text',
      disabled: _ctx.disabled,
      placeholder: _ctx.placeholder,
      value: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.update($event)))
    }, null, 8, ["type", "disabled", "placeholder", "value"])
  ]))
}