import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/index';

@Module({ dynamic: true, namespaced: true, store, name: 'layout' })
export class LayoutModule extends VuexModule {
  showSpinner = false;

  @Mutation
  setSpinnerState(showSpinner) {
    this.showSpinner = showSpinner;
  }
}

export const LayoutStore = getModule(LayoutModule);
