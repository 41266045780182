
  import { Options, Vue } from 'vue-class-component';
  import { CasePersonalDataDto, GenderType, LookUpValueType } from '@/apiclient/apiclient_generated';
  import { Prop } from 'vue-property-decorator';
  import AsBlock from '@/components/shared/as-block-component.vue';
  import AsDropdown from '@/components/shared/as-dropdown.vue';
  import AsTextWithLabel from '@/components/shared/as-textWithLabel.vue';
  import AsReadWriteBlock from '@/components/shared/as-read-write.vue';
  import AsInput from '@/components/shared/as-input.vue';
  import { formatDateWithoutTime } from '@/services/dateFunctions';
  import AsDatepicker from '@/components/shared/as-datepicker.vue';
  import { isEmailValid } from '@/services/formFunctions';
  import { LookUpValuesModule, LookUpValuesStore } from '@/store/lookUpValues';
  import { PublicConstants } from '@/types/PublicConstants';

  @Options({
    components: {
      AsBlock,
      AsDropdown,
      AsTextWithLabel,
      AsReadWriteBlock,
      AsInput,
      AsDatepicker,
    },
  })
  export default class CasePersonalData extends Vue {
    @Prop()
    program;

    @Prop()
    isEditMode: boolean;
    @Prop()
    isEditing: boolean;

    @Prop()
    personalData: CasePersonalDataDto;

    @Prop()
    caseNumber: string;

    lvTypes = LookUpValueType;
    private genderOptions_ = [GenderType.Male, GenderType.Female];
    genderOptions = [];
    publicConstants = PublicConstants;

    mounted(): void {
      this.genderOptions = this.genderOptions_.map((t) => {
        return {
          key: t,
          value: this.$t(`lookUpValues.${t}`),
        };
      });
    }

    private lookUpStore: LookUpValuesModule = LookUpValuesStore;

    getLookUpOptions(groupType: LookUpValueType) {
      return this.lookUpStore.lookUpOptions(groupType);
    }

    getLookUpValue(group: LookUpValueType, id) {
      return this.lookUpStore.getLookUpValueName({ group, id });
    }

    get caseId(): any {
      return this.$route.params.id;
    }

    get isReadOnly() {
      if (!this.isEditMode) {
        return false;
      }

      if (this.isEditMode && !this.isEditing) {
        return true;
      }

      return false;
    }

    formatDateToString(date) {
      return formatDateWithoutTime(date);
    }

    isNumber(evt: KeyboardEvent): void {
      const keysAllowed: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      const keyPressed: string = evt.key;

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    }

    get isPersonalDataEmailValid() {
      return isEmailValid(this.personalData.email);
    }
  }
