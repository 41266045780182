import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-227e4a08"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "case-personal-data" }
const _hoisted_2 = {
  key: 0,
  class: "grid-group-of-two"
}
const _hoisted_3 = { class: "form-item grid-group-of-two-first" }
const _hoisted_4 = { class: "form-item grid-group-of-two-second" }
const _hoisted_5 = { class: "grid-group-of-two" }
const _hoisted_6 = { class: "form-item grid-group-of-two-first" }
const _hoisted_7 = { class: "form-item grid-group-of-two-second" }
const _hoisted_8 = { class: "grid-group-of-two" }
const _hoisted_9 = { class: "form-item grid-group-of-two-first" }
const _hoisted_10 = { class: "form-item grid-group-of-two-second" }
const _hoisted_11 = { class: "grid-group-of-three" }
const _hoisted_12 = { class: "form-item grid-group-of-three-first" }
const _hoisted_13 = { class: "form-item zip grid-group-of-three-second" }
const _hoisted_14 = { class: "form-item grid-group-of-three-third" }
const _hoisted_15 = { class: "grid-group-of-three" }
const _hoisted_16 = { class: "form-item grid-group-of-three-first" }
const _hoisted_17 = { class: "form-item grid-group-of-three-second" }
const _hoisted_18 = { class: "form-item grid-group-of-three-third" }
const _hoisted_19 = { class: "email-with-error-wrapper" }
const _hoisted_20 = {
  key: 0,
  class: "pi pi-exclamation-triangle"
}
const _hoisted_21 = { class: "grid-group-of-two" }
const _hoisted_22 = { class: "form-item grid-group-of-two-first" }
const _hoisted_23 = { class: "form-item" }
const _hoisted_24 = { class: "form-item grid-group-of-two-second" }
const _hoisted_25 = { class: "grid-group-of-three" }
const _hoisted_26 = { class: "form-item grid-group-of-three-first" }
const _hoisted_27 = { class: "form-item grid-group-of-three-second" }
const _hoisted_28 = { class: "form-item grid-group-of-three-third" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_AsTextWithLabel = _resolveComponent("AsTextWithLabel")!
  const _component_AsInput = _resolveComponent("AsInput")!
  const _component_AsReadWriteBlock = _resolveComponent("AsReadWriteBlock")!
  const _component_AsDropdown = _resolveComponent("AsDropdown")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_AsDatepicker = _resolveComponent("AsDatepicker")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.caseId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.caseNumber)
              ? (_openBlock(), _createBlock(_component_AsTextWithLabel, {
                  key: 0,
                  label: _ctx.$t('case.CaseNumber'),
                  useSlot: true
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Badge, {
                      severity: "success",
                      class: "no-hover"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.caseNumber), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["label"]))
              : (_openBlock(), _createBlock(_component_AsReadWriteBlock, {
                  key: 1,
                  labelClass: 'pipelineCaseNumberColor',
                  label: _ctx.$t('case.PipelineNumber'),
                  value: _ctx.personalData.pipelineCaseNumber,
                  isReadOnly: _ctx.isReadOnly
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AsInput, {
                      type: "text",
                      value: _ctx.personalData.pipelineCaseNumber,
                      onUpdate: _cache[0] || (_cache[0] = (value) => (_ctx.personalData.pipelineCaseNumber = value))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label", "value", "isReadOnly"]))
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_AsReadWriteBlock, {
              label: _ctx.$t('case.personalData.Program'),
              value: _ctx.getLookUpValue(_ctx.lvTypes.Program, _ctx.personalData.programId),
              isReadOnly: _ctx.isReadOnly
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AsDropdown, {
                  clearIcon: true,
                  optionValue: 'id',
                  optionLabel: 'value',
                  value: _ctx.personalData.programId,
                  options: _ctx.getLookUpOptions(_ctx.lvTypes.Program),
                  placeholder: _ctx.$t('case.personalData.ProgramPlaceholder'),
                  onUpdate: _cache[1] || (_cache[1] = (value) => (_ctx.personalData.programId = value))
                }, null, 8, ["value", "options", "placeholder"])
              ]),
              _: 1
            }, 8, ["label", "value", "isReadOnly"])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_AsReadWriteBlock, {
          label: _ctx.$t('case.personalData.LastName'),
          value: _ctx.personalData.lastName,
          isReadOnly: _ctx.isReadOnly
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              class: "form-control",
              type: "text",
              modelValue: _ctx.personalData.lastName,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.personalData.lastName) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label", "value", "isReadOnly"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_AsReadWriteBlock, {
          label: _ctx.$t('case.personalData.FirstName'),
          value: _ctx.personalData.firstName,
          isReadOnly: _ctx.isReadOnly
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              class: "form-control",
              type: "text",
              modelValue: _ctx.personalData.firstName,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.personalData.firstName) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label", "value", "isReadOnly"])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_AsReadWriteBlock, {
          label: _ctx.$t('case.personalData.DateOfBirth'),
          value: _ctx.formatDateToString(_ctx.personalData['dateOfBirthDateTime']),
          isReadOnly: _ctx.isReadOnly
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AsDatepicker, {
              placeholder: _ctx.$t('datePlaceholder'),
              value: _ctx.personalData.dateOfBirth,
              enableTimePicker: false,
              max: new Date(),
              onUpdate: _cache[4] || (_cache[4] = (date) => (_ctx.personalData.dateOfBirth = date))
            }, null, 8, ["placeholder", "value", "max"])
          ]),
          _: 1
        }, 8, ["label", "value", "isReadOnly"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_AsReadWriteBlock, {
          label: _ctx.$t('case.personalData.Gender'),
          value: _ctx.personalData.gender ? _ctx.$t(`lookUpValues.${_ctx.personalData.gender}`) : _ctx.publicConstants.emptyValue,
          isReadOnly: _ctx.isReadOnly
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AsDropdown, {
              clearIcon: true,
              value: _ctx.personalData.gender,
              options: _ctx.genderOptions,
              optionValue: 'key',
              optionLabel: 'value',
              placeholder: _ctx.$t('case.personalData.GenderPlaceholder'),
              onUpdate: _cache[5] || (_cache[5] = (value) => (_ctx.personalData.gender = value))
            }, null, 8, ["value", "options", "placeholder"])
          ]),
          _: 1
        }, 8, ["label", "value", "isReadOnly"])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_AsReadWriteBlock, {
          label: _ctx.$t('case.personalData.Street'),
          value: _ctx.personalData.street,
          isReadOnly: _ctx.isReadOnly
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              class: "form-control",
              type: "text",
              modelValue: _ctx.personalData.street,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.personalData.street) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label", "value", "isReadOnly"])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_AsReadWriteBlock, {
          label: _ctx.$t('case.personalData.Zip'),
          value: _ctx.personalData.zip,
          isReadOnly: _ctx.isReadOnly
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("input", {
              class: "p-inputtext form-control",
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.personalData.zip) = $event)),
              onKeypress: _cache[8] || (_cache[8] = ($event: any) => (_ctx.isNumber($event))),
              type: "text"
            }, null, 544), [
              [_vModelText, _ctx.personalData.zip]
            ])
          ]),
          _: 1
        }, 8, ["label", "value", "isReadOnly"])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_AsReadWriteBlock, {
          label: _ctx.$t('case.personalData.City'),
          value: _ctx.personalData.city,
          isReadOnly: _ctx.isReadOnly
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              class: "form-control",
              type: "text",
              modelValue: _ctx.personalData.city,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.personalData.city) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label", "value", "isReadOnly"])
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_AsReadWriteBlock, {
          label: _ctx.$t('case.personalData.Phone'),
          value: _ctx.personalData.phone,
          isReadOnly: _ctx.isReadOnly
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              class: "form-control",
              type: "text",
              modelValue: _ctx.personalData.phone,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.personalData.phone) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label", "value", "isReadOnly"])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_component_AsReadWriteBlock, {
          label: _ctx.$t('case.personalData.MobilePhone'),
          value: _ctx.personalData.mobilePhone,
          isReadOnly: _ctx.isReadOnly
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              class: "form-control",
              type: "text",
              modelValue: _ctx.personalData.mobilePhone,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.personalData.mobilePhone) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label", "value", "isReadOnly"])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_component_AsReadWriteBlock, {
          label: _ctx.$t('case.personalData.Email'),
          value: _ctx.personalData.email,
          isReadOnly: _ctx.isReadOnly
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_19, [
              _withDirectives(_createElementVNode("input", {
                class: _normalizeClass(["form-control", { error: !_ctx.isPersonalDataEmailValid }]),
                type: "email",
                id: "email",
                name: "email",
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.personalData.email) = $event))
              }, null, 2), [
                [_vModelText, _ctx.personalData.email]
              ]),
              (!_ctx.isPersonalDataEmailValid)
                ? _withDirectives((_openBlock(), _createElementBlock("i", _hoisted_20, null, 512)), [
                    [_directive_tooltip, { value: _ctx.$t('invalidEmail'), showDelay: 1000, hideDelay: 300 }]
                  ])
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["label", "value", "isReadOnly"])
      ])
    ]),
    _createElementVNode("div", _hoisted_21, [
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("div", _hoisted_23, [
          _createVNode(_component_AsReadWriteBlock, {
            label: _ctx.$t('case.personalData.TypeofInsurance'),
            value: _ctx.getLookUpValue(_ctx.lvTypes.Insurance, _ctx.personalData.insuranceId),
            isReadOnly: _ctx.isReadOnly
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AsDropdown, {
                clearIcon: true,
                value: _ctx.personalData.insuranceId,
                options: _ctx.getLookUpOptions(_ctx.lvTypes.Insurance),
                optionLabel: 'value',
                optionValue: 'id',
                placeholder: _ctx.$t('case.personalData.InsurancePlaceholder'),
                onUpdate: _cache[13] || (_cache[13] = (value) => (_ctx.personalData.insuranceId = value))
              }, null, 8, ["value", "options", "placeholder"])
            ]),
            _: 1
          }, 8, ["label", "value", "isReadOnly"])
        ])
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createVNode(_component_AsReadWriteBlock, {
          label: _ctx.$t('case.personalData.HealthInsurance'),
          value: _ctx.personalData.healthInsurance,
          isReadOnly: _ctx.isReadOnly
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              class: "form-control",
              modelValue: _ctx.personalData.healthInsurance,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.personalData.healthInsurance) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label", "value", "isReadOnly"])
      ])
    ]),
    _createElementVNode("div", _hoisted_25, [
      _createElementVNode("div", _hoisted_26, [
        _createVNode(_component_AsReadWriteBlock, {
          label: _ctx.$t('case.personalData.Airline'),
          value: _ctx.getLookUpValue(_ctx.lvTypes.Airline, _ctx.personalData.airlineId),
          isReadOnly: _ctx.isReadOnly
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AsDropdown, {
              clearIcon: true,
              value: _ctx.personalData.airlineId,
              options: _ctx.getLookUpOptions(_ctx.lvTypes.Airline),
              placeholder: _ctx.$t('case.personalData.AirlinePlaceholder'),
              optionLabel: 'value',
              optionValue: 'id',
              onUpdate: _cache[15] || (_cache[15] = (value) => (_ctx.personalData.airlineId = value))
            }, null, 8, ["value", "options", "placeholder"])
          ]),
          _: 1
        }, 8, ["label", "value", "isReadOnly"])
      ]),
      _createElementVNode("div", _hoisted_27, [
        _createVNode(_component_AsReadWriteBlock, {
          label: _ctx.$t('case.personalData.Fleet'),
          value: _ctx.personalData.fleet,
          isReadOnly: _ctx.isReadOnly
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              class: "form-control",
              type: "text",
              modelValue: _ctx.personalData.fleet,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.personalData.fleet) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label", "value", "isReadOnly"])
      ]),
      _createElementVNode("div", _hoisted_28, [
        _createVNode(_component_AsReadWriteBlock, {
          label: _ctx.$t('case.personalData.Function'),
          value: _ctx.getLookUpValue(_ctx.lvTypes.Function, _ctx.personalData.functionId),
          isReadOnly: _ctx.isReadOnly
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AsDropdown, {
              clearIcon: true,
              optionValue: 'id',
              optionLabel: 'value',
              value: _ctx.personalData.functionId,
              options: _ctx.getLookUpOptions(_ctx.lvTypes.Function),
              placeholder: _ctx.$t('case.personalData.FunctionPlaceholder'),
              onUpdate: _cache[17] || (_cache[17] = (value) => (_ctx.personalData.functionId = value))
            }, null, 8, ["value", "options", "placeholder"])
          ]),
          _: 1
        }, 8, ["label", "value", "isReadOnly"])
      ])
    ])
  ]))
}